<template>
	<div v-if="visible" class="overlay" @click="handleOverlayClick"></div>
</template>

<script>
	export default {
		name: 'Overlay',
		data() {
			return {
				visible: false,
			};
		},
		methods: {
			show() {
				this.visible = true;
			},
			hide() {
				this.visible = false;
			},
			handleOverlayClick() {
				this.$emit('click');
			},
		},
	};
</script>

<style>
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0);
		/* Adjust transparency as needed */
		z-index: 100;
		/* Ensure it sits on top of other content */
	}
</style>
<template>
	<div style="width: 100%;float: center;display: flex;justify-content: flex-end;">
		<!-- 编辑群信息 -->
		<div class="right-frame">
			<div class="flexcen" style="position: absolute;width: 100%;margin-top: 602px;" v-if="finish==0">
				<div class="frame-button-send flexcen"
					style="background: #FFFFFF;color: #0052D9;border: 0.5px solid #0052D9;margin-right: 12px;"
					@click="handleAudit('撤回')">驳回</div>
				<div class="frame-button-send flexcen" @click="handleAudit('通过')">通过</div>
			</div>
			<!-- 编辑群信息 -->
			<div class="right-frame-title flexal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="submit" />
				<div class="right-tips-text" style="font-size: 17px;margin-left: 20px;color: #333333;">审批</div>
				<div style="flex: 1;font-weight: 400;font-size: 13px;color: #858585;margin-left: 58px;">
					{{detail.CreatedDate}}
				</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;" @click="submit" />
			</div>

			<!-- 编辑群信息 -->
			<div v-infinite-scroll="scrollLoad" :style="{'overflow':'auto','height':finish == 0? '535px':'605px'}">
				<div class="right-frame-title flexal">
					<div style="flex: 1;">
						<div style="font-weight: 400;font-size: 18px;color: #000000;">{{detail.FormName}}</div>
						<!-- <div style="font-weight: 400;font-size: 11px;color: #999999;margin-top: 5px;">所在的部门名称</div> -->
						<div style="font-weight: 400;font-size: 13px;color: #FF862A;margin-top: 10px;" v-if="finish==0">
							待审批</div>
						<div style="font-weight: 400;font-size: 13px;color: #27C216;margin-top: 10px;" v-if="finish==1">
							审批已通过</div>
						<div style="font-weight: 400;font-size: 13px;color: #D54941;margin-top: 10px;" v-if="finish==2">
							审批已拒绝</div>
					</div>
					<img :src="require('@/assets/img/in-review.png')" style="width: 73px;height: 73px;"
						v-if="finish == 0" />
					<img :src="require('@/assets/img/pass.png')" style="width: 73px;height: 73px;" v-if="finish == 1" />
				</div>

				<div style="margin-top: 14px;margin-left: 15px;font-weight: 400;font-size: 14px;color: #272B34;">
					{{detail.FormTypeCName}}：
				</div>
				<div class="frame-audit-content" v-html="detail.FormDesc">
				</div>

				<div style="margin-top: 14px;margin-left: 15px;font-weight: 400;font-size: 14px;color: #272B34;">审批部门：
				</div>

				<div style="height: 24px;width: 100%;"></div>

				<div style="display: flex;margin-left: 28px;">
					<!-- <img :src="avatar"  style="width: 45px;height: 45px;border-radius: 50%;" /> -->
					<div style="margin-left: 9px;flex: 1;">
						<!-- <div style="font-size: 15px;color: #000000;">审批人名称</div> -->
						<div style="font-size: 12px;color: #666666;">{{detail.ReviewDepartment}}</div>
					</div>
					<!-- <div style="font-size: 11px;color: #666666;margin-right: 26px;">2024-09-15 12:00</div> -->
				</div>

				<div class="flexal">
					<!-- <div class="frame-stand"></div> -->
					<div class="frame-across"> </div>
				</div>
				<!-- <div style="display: flex;margin-left: 28px;">
                    <div style="margin-left: 9px;flex: 1;">
                        <div style="font-size: 15px;color: #000000;">直属审批</div>
                        <div style="font-size: 12px;color: #666666;">领导名称（审核中）</div>
                    </div>
                    <div style="font-size: 11px;color: #666666;margin-right: 26px;">2024-09-15 12:00</div>
                </div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'audit',
		props: {
			detail: {
				type: Object,
				default: {}
			}
		},
		wacth: {
			detail(newaval) {
				if (newaval && newaval.ID > 0) {
					this.finish = (newaval.ReviewStatus == '待审批' ? 0 : newaval.ReviewStatus == '通过' ? 1 : 2)
				}

			}
		},
		data() {
			return {
				finish: 0,
			}
		},
		methods: {
			// 发送/关闭，按钮回调  0:关闭  1:发送
			submit() {
				this.$emit('auditSubmit', 0)
			},

			async handleAudit(status = '通过') {
				this.$confirm(`确定${status}?`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					var data = this.detail
					data.ReviewStatus = status
					const loading = this.$loading({
						lock: true,
						text: 'Loading'
					});
					var res = await this.$request.post('/notice/saveAudit', data)
					loading.close();
					if (res.code == 200) {
						this.$message.success('操作成功')
						this.submit()
					} else {
						this.$message.error(res.msg)
					}

				}).catch(err => {
					// console.error(err)
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.right-frame {
		margin-top: 0px;
		position: absolute;
		width: 457px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		z-index: 101;
		/* Ensure it sits on top of other content */

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.right-frame-title {
			padding: 15px 16px;
		}

		.right-line-bottom {
			margin-left: 16px;
			width: 424px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}

		.right-tips-text {
			font-weight: 400;
			color: #6B6B6B;
			font-size: 14px;
		}

		.el-button {
			// width: 96px;
			// height: 45px;
			padding: 0px 0px;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.3);
			font-size: 24px;
			color: rgba(255, 255, 255, 1);
			border: transparent;
			overflow: hidden;
		}

		.title-qrCode-frame {
			position: absolute;
			margin-left: -300px;
			z-index: 101;
			/* Ensure it sits on top of other content */
			width: 302px;
			height: 412px;
			// background: #FFFFFF;
			background-image: url('../../assets/img/navigation/qrCode-frame.png');
			/* 替换为实际的图片路径 */
			background-size: cover;
			/* 确保背景图像覆盖整个容器 */
			background-position: center;
			/* 将背景图像居中对齐 */
			background-repeat: no-repeat;
			/* 避免背景图像重复 */
			box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.17);
			border-radius: 16px 16px 16px 16px;
		}

		.saveQrCode {
			width: 132px;
			height: 39px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 12px;
			color: #0052D9;
		}

		.shareQrCode {
			margin-left: 10px;
			width: 132px;
			height: 39px;
			background: #0052D9;
			border-radius: 8px 8px 8px 8px;
			color: #FFFFFF;
			font-size: 12px;
		}

		.frame-time-list {
			margin-left: 13px;
			margin-top: 14px;
			width: 130px;
			height: 19px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 2px solid #366EF4;
			font-weight: 400;
			font-size: 11px;
			color: #366EF4;
		}

		.frame-audit-content {
			padding: 20px 44px;
		}

		.frame-stand {
			margin-left: 50px;
			margin-top: 4px;
			margin-bottom: 4px;
			margin-right: 30px;
			width: 1px;
			height: 24px;
			background: #D6D6D6;
			border-radius: 0px 0px 0px 0px;
		}

		.frame-across {
			// margin-left: 24px;
			// margin-top: 18px;
			margin: 18px 24px;
			width: 415px;
			height: 1px;
			background: #C9C9C9;
			border-radius: 0px 0px 0px 0px;
		}
	}

	.frame-button-send {

		width: 122px;
		height: 47px;
		background: #0052D9;
		border-radius: 16px 16px 16px 16px;
		font-size: 16px;
		color: #FFFFFF;
	}

	/deep/.el-textarea__inner {
		background: #F1F1F1;
		min-height: 114px;
		border-radius: 16px;
	}
</style>
<template>
	<div class="hello">
		<div class="frame" v-if="tab == 0">
			<div class="frame-title">{{ type == 0? '日报（周报/月报）':'自由汇报' }}</div>
			<div class="flexcumal" style="height: 650px;overflow: auto;">
				<div style="width: 794px;margin-top: 15px;">
					<div class="frame-text">任务名称</div>
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
						v-model="from.inputName"></el-input>
				</div>
				<div style="width: 794px;margin-top: 15px;">
					<div class="frame-text">问题描述</div>
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
						v-model="from.inputDesc"></el-input>
				</div>
				<div style="width: 794px;margin-top: 15px;" v-if="type == 0">
					<div class="frame-text">解决方案</div>
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
						v-model="from.inputOpts"></el-input>
				</div>
				<div style="width: 794px;margin-top: 15px;" v-if="type == 0">
					<div class="frame-text">心得体会</div>
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
						v-model="from.inputExpe"></el-input>
				</div>
				<div style="width: 794px;margin-top: 15px;" v-if="type == 0">
					<div class="frame-text">其他</div>
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
						v-model="from.inputOthe"></el-input>
				</div>
				<div style="width: 794px;margin-top: 20px;" class="flexal">
					<div class="frame-text" style="flex: 1;">发送到人</div>
					<img :src="require('@/assets/img/log/add.png')" style="width: 25px;height: 25px;"
						@click="handleusers()" />
				</div>
				<div class="flexal" style="width: 794px;margin-top: 10px;">
					<template v-for="(item,index) in list">
						<div class="flexcumal" style="margin-left: 14px;" :key="index" v-if="item.checked">
							<div style="display: flex;">
								<img :src="item.Avatar" style="width: 38px;height: 38px;border-radius: 50%;" />
								<img :src="require('@/assets/img/log/close.png')"
									style="width: 14px;height: 14px;position: relative;margin-left: -10px;"
									@click="item.checked = !item.checked" />
							</div>
							<div class="frame-text-name">{{item.UserName}}</div>
						</div>
					</template>
				</div>
			</div>
			<div style="width: 100%;display: flex;justify-content: flex-end;">
				<div @click="handleaddlog()" class="frame-button flexcen" style="margin-right: 53px;margin-top: 10px;">
					提交日志</div>
			</div>
		</div>
		<div class="frame" v-if="tab == 1">
			<div class="frame-title">查看日志</div>
			<div style="height: 700px;overflow: auto;margin-top: 28px;padding: 0px 17px;">
				<div class="flexal">
					<img :src="logview.workuser.Avatar" style="width: 42px;height: 42px;border-radius: 50%;" />
					<div style="margin-left: 15px;">
						<div style="font-weight: 500;font-size: 16px;">{{logview.workuser.UserName}}的工作日志</div>
						<div style="font-weight: 400;font-size: 11px;color: #878787;">{{logview.sdata.CreateTime}}</div>
					</div>
				</div>
				<div class="frame-content-text" style="margin-top: 13px;">
					任务名称：<span>{{logview.sdata.Content}}</span>
				</div>
				<div class="frame-content-text">
					问题描述：<span>{{logview.sdata.Content1}}</span>
				</div>
				<div class="frame-content-text" v-if="logview.sdata.Content2&&logview.sdata.Content2!=''">
					解决方案：<span>{{logview.sdata.Content2}}</span>
				</div>
				<div class="frame-content-text" v-if="logview.sdata.Content3&&logview.sdata.Content3!=''">
					心得体会：<span>{{logview.sdata.Content3}}</span>
				</div>
				<div class="frame-content-text" v-if="logview.sdata.Content4&&logview.sdata.Content4!=''">
					其它：<span>{{logview.sdata.Content4}}</span>
				</div>
			</div>
			<!-- <div style="width: 100%;display: flex;justify-content: flex-end;">
				<div class="frame-button flexcen" style="margin-right: 53px;margin-top: 10px;">写日志</div>
			</div> -->
		</div>
		<!-- 添加群成员弹窗 -->
		<memberPop :dialogVisibleShow="dialogVisibleMember" @updateDialog="updateDialogClick" :list="list"></memberPop>
	</div>
</template>

<script>
	import memberPop from '../pop-up/memberPop.vue'
	export default {
		name: 'workNotcie',
		props: {
			msg: {
				type: String,
				default: ''
			},
			// 日志类型 0-日报 1-周报 2-月报 3-自定义
			logtype: {
				type: Number,
				default: 0
			},
			//   类型 0:日报（周报/月报） 1:自由汇报
			type: {
				type: Number,
				default: 0
			},
			//   导航栏下标 0:工作日志  1:查看日志
			tab: {
				type: Number,
				default: 0
			},
			logId: {
				type: Number,
				default: 0
			}
		},
		watch: {
			tab(newval) {
				if (newval == 1) {
					console.log('查看日志')
				}
			},
			logId(newval) {
				if (newval > 0) {
					this.getlogdetail()
				}
			},
		},
		data() {
			return {
				// input:'',
				from: {
					input: '',
					inputName: '',
					inputDesc: '',
					inputOpts: '',
					inputExpe: '',
					inputOthe: ''
				},
				avatar: 'https://cdn.uviewui.com/uview/album/1.jpg',
				// 组织成员
				list: [],
				// chooseLsit:[],
				tabIndex: 0,
				logview: {
					workuser: {
						UserName: '王小二',
						Avatar: 'https://cdn.uviewui.com/uview/album/1.jpg',
					},
					sdata: {
						WorkLogId: 0,

						Content: '',
						Content1: '',
						Content2: '',
						Content3: ''
					}

				}
			}
		},
		components: {
			memberPop
		},
		methods: {
			submit(index) {
				this.tabIndex = index
				this.$emit('workNoticeSubmit', index)
			},
			async handleusers() {
				//获取全部
				var data = {
					page: 1,
					limit: 999,
					queryId: 0
				}
				var res = await this.$request.post('/Users/usersList', data)
				if (res.code == 200) {
					this.list = res.data.items
					this.dialogVisibleMember = true
				}
			},

			updateDialogClick(list) {
				console.log(list)
				this.list = list
				this.dialogVisibleMember = false
			},
			async getlogdetail() {
				var data = {
					WorkLogId: this.logId
				}
				var res = await this.$request.post('/work/detail', data)
				if (res.code == 200) {
					this.logview = res.data
					console.log('0', this.logview)
				}
			},
			async handleaddlog() {

				if (!this.from.inputName || this.from.inputName == '') {
					this.$message.error('任务名称不能为空')
					return false
				}
				if (!this.from.inputDesc || this.from.inputDesc == '') {
					this.$message.error('问题描述不能为空')
					return false
				}

				var uchecks = []
				this.list.map(it => {
					if (it.checked) uchecks.push(it.ID)
				})
				if (uchecks.length < 1) {
					this.$message.error('请选择发送人')
					return false
				}
				const loading = this.$loading({
					lock: true,
					text: 'Loading'
				});

				//获取全部
				var data = {
					Content: this.from.inputName,
					Content1: this.from.inputDesc,
					Content2: this.from.inputOpts,
					Content3: this.from.inputExpe,
					Content4: this.from.inputOthe,
					WorkType: this.logtype,
					uids: uchecks
				}
				var res = await this.$request.post('/work/crate', data)
				loading.close();
				if (res.code == 200) {
					this.list = res.data.items
					this.$message.success('提交成功')
				}
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		width: 901px;
		// height: 851px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		margin-left: 12px;

		.people-title {
			margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #777777;
		}

		.frame-title {
			padding-top: 15px;
			margin-left: 18px;
			font-weight: 500;
			font-size: 18px;
			color: #333333;
		}

		.left-item {
			margin-left: 12px;
			margin-top: 5px;
			display: flex;
			width: 185px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

			.item-bubble {
				width: 15px;
				height: 15px;
				background: #F65160;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				color: #FFFFFF;
				font-weight: 400;
			}
		}

		.frame-item {
			margin-top: 12px;
			margin-left: 12px;
			width: 290px;
			height: 63px;
			background: #FFFFFF;
			color: #272B34;
			border-radius: 8px 8px 8px 8px;
			box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.16);
		}

		.frame-item-active {
			// background: #EFF2FF;
			// color: #0052D9;
		}

		.frame-item-text {
			font-size: 15px;

			font-weight: 400;
		}

		.frame-line {
			width: 26px;
			height: 3px;
			background: #0052D9;
			border-radius: 3px 3px 3px 3px;
		}

		.frame-tab-line {
			width: 5px;
			height: 63px;
			background: #4CAF50;
			border-radius: 8px 0px 0px 8px;
		}

		.frame-text {
			font-weight: 400;
			font-size: 17px;
			color: #000000;
		}

		.frame-text-name {
			margin-top: 3px;
			font-weight: 400;
			font-size: 11px;
			color: #333333;
		}

		.frame-button {
			width: 122px;
			height: 48px;
			background: #0052D9;
			border-radius: 16px 16px 16px 16px;
			font-size: 15px;
			color: #FFFFFF;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}

	.frame-content-text {
		// margin-top: 5px;
		font-weight: 400;
		font-size: 13px;
		color: #333333;
		line-height: 20px;
		//   display: -webkit-box;
		//   -webkit-box-orient: vertical;
		//   -webkit-line-clamp: 2; /* 显示的行数 */
		//   overflow: hidden;
		//   text-overflow: ellipsis;
	}

	/deep/.el-textarea__inner {
		margin-top: 10px;
		width: 794px;
		// background: #CBCBCB;
		// min-height: 114px;
		border-radius: 8px;
	}
</style>
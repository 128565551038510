import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userId: 0,
		token: null
	},

	// 组件通过this.$store.getters.getUserId
	getters: {
		getUserId(state) {
			return localStorage.getItem('userId')
		}
	},
	//组件调用this.$store.commit('SET_TOKEN','new token')挂载方法
	mutations: {
		SET_USERID(state, params) {
			console.log('state', state, params)
			localStorage.setItem('userId', params)
			state.userId = params
		},

		SET_TOKEN: (state, token) => {
			state.token = token
			console.log(state.token);
			localStorage.setItem('userToken', token)
		}
	},
	// 组件中使用this.$store.dispatch('aEdit','new TOKEN')
	actions: {
		aEdit(context, payload) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					context.commit('SET_TOKEN', payload)
					resolve()
				}, 2000)
			})
		}
	},

	modules: {

	}
})
<template>
	<div style="width: 100%;float: center;display: flex;">
		<div class="frame">
			<!-- 搜索框 -->
			<div class="left-item" style="margin-top: 24px;">
				<el-input v-model="input" placeholder="搜索" @change="initseach()">
					<div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
						<div class="prefix-icon3"></div>
					</div>
				</el-input>
			</div>

			<div style="display: flex;margin-top: 24px;">
				<div style="width: 40%;font-weight: 400;font-size: 16px;color: #858A99;">标题</div>
				<div style="width: 30%;font-weight: 400;font-size: 16px;color: #858A99;">发送人</div>
				<div style="width: 30%;font-weight: 400;font-size: 16px;color: #858A99;">发送时间</div>
			</div>

			<div style="overflow:auto;height: 555px;" v-infinite-scroll="scrollLoad">
				<div class="flexal" style="margin-top: 22px;" v-for="(message,index) in doumentList">
					<div class="flexal" style="width: 40%;">
						<img :src="optListIndex == 1? require('@/assets/img/messageRight/searchCloud.png') : require('@/assets/img/messageRight/searchFile.png')"
							class="imgIcon" />

						<div style="margin-left: 13px;margin-right: 16px;" class="ellipsis item-text">
							<div class="ellipsis item-text">{{message.MsgName}}</div>
							<div style="font-size: 12px;color: #858A99;font-weight: 400;" v-if="optListIndex == 3">
								{{message.MsgContent.size}}
							</div>
						</div>
					</div>
					<div class="flexal item-text ellipsis" style="width: 30%;">
						{{getuserinfo(message.UserId,0)}}
					</div>
					<div style="width: 30%;" class="flexal item-text">
						<div style="flex: 1;margin-left: 5px;">{{dateFtt(message.CreateTime,'yyyy/MM/dd hh:mm')}}</div>
						<img :src="require('@/assets/img/skip.png')" style="width: 14px; height: 14px;" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'document',
		props: {
			// 显示内容 1：云文档，3：文件
			optListIndex: {
				type: Number,
				default: 1
			},
			roomid: {
				type: Number,
				default: 0
			},
			// 用户列表
			ulist: {
				type: Array,
				default: []
			},
		},
		watch: {
			optListIndex(newval) {
				this.initseach()
			}
		},

		data() {
			return {

				doumentList: [], //文件
				input: '',
				query: {
					id: 0, //群组ID
					type: 0, //消息类型
					page: 1, //页码
					limit: 20, //条数
					isnext: true, //可否加载下一页
					isload: false, //加载中

				}
			}
		},
		mounted() {
			this.initseach()
		},
		methods: {
			scrollLoad() {
				this.getlists()
			},
			initseach() {
				this.query.page = 1
				this.doumentList = []
				this.getlists()
			},
			async getlists() {
				var rlist = []
				if (!this.query.isload) {
					this.query.isload = true
					if (this.query.page == 1) {
						this.query.isnext = true
					}
					if (this.query.isnext) {

						this.query.id = this.roomid
						this.query.type = (this.optListIndex == 3 ? 4 : 6)
						var row = {
							type: this.query.type,
							name: this.input
						}
						rlist = await this.msggetlist(this.query.id, this.query.page, this.query.limit, row)
						this.query.page += 1
						if (rlist.length < this.query.limit) this.query.isnext = false
						rlist.map(it => {
							if (typeof it.MsgContent === 'string') {
								it.MsgContent = JSON.parse(it.MsgContent)
							}
						})
						console.log('rlist', rlist)
					}
					this.query.isload = false
				}


				this.doumentList = this.doumentList.concat(rlist)
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		margin-top: 0px;
		position: absolute;
		width: 682px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		padding: 0px 22px;
		z-index: 100;
		/* Ensure it sits on top of other content */
		// -webkit-overflow-scrolling: touch;
		// overflow-scrolling: touch;
		// overflow-y: auto;
		// overflow-x: hidden;

		.left-item {
			display: flex;
			width: 425px;
			align-items: center;
			border-radius: 8px;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			/deep/.el-input__inner {
				height: 36px;
				font-size: 15px;
				border-radius: 8px;
			}

			.item-text {
				font-weight: 500;
				font-size: 14px;
				color: #333333;
			}
		}

		.imgIcon {
			width: 30px;
			height: 37px;
		}


	}
</style>
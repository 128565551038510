<template>
	<div class="hello">
		<div class="frame">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-left: 12px;margin-bottom: 14px;">
				<!-- <img :src="require('@/assets/img/message/message.png')"  class="imgIcon"  /> -->
				<div class="people-title">云文档</div>
			</div>
			<!-- 下拉框 -->
			<div class="flexal">
				<el-dropdown @command="handleCommand" class="dropdown-frame" style="display: flex;align-items: center;"
					@visible-change="change">
					<span class="el-dropdown-link flexal">
						<img :src="require('@/assets/img/cloud/file.png')"
							style="width: 34px;height: 34px;margin-left: 12px;" />
						<div style="margin-left: 8px;flex: 1;">
							<div style="font-weight: 400;font-size: 15px;color: #272B34;">新建</div>
							<div style="font-weight: 400;font-size: 11px;color: #535D6D;">新建文档开始协作</div>
						</div>
						<img :src="require('@/assets/img/right.png')"
							:style="{'transform':fileShow? 'rotate(-90deg)':'rotate(90deg)'}"
							style="width: 10px;height: 16px;margin-right: 15px;" @click="submit" />
						<!-- 下拉菜单
                <i class="el-icon-arrow-down el-icon--right"></i> -->
					</span>
					<el-dropdown-menu slot="dropdown" style="width: 226px;">
						<el-dropdown-item :command="item.type" v-for="(item,index) in fileList" :key="index">
							<div class="flexal">

								<img :src="item.icon" style="width: 20px;height: 20px;margin-right: 6px;" />
								<div class="dropdown-text">{{item.text}}</div>

							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-dropdown @command="handleCommand" class="dropdown-frame" style="display: flex;align-items: center;"
					@visible-change="changeCloud">
					<span class="el-dropdown-link flexal">
						<img :src="require('@/assets/img/cloud/cloud.png')"
							style="width: 34px;height: 34px;margin-left: 12px;" />
						<div style="margin-left: 8px;flex: 1;">
							<div style="font-weight: 400;font-size: 15px;color: #272B34;">上传</div>
							<div style="font-weight: 400;font-size: 11px;color: #535D6D;">上传本地文件</div>
						</div>
						<img :src="require('@/assets/img/right.png')"
							:style="{'transform':cloudShow? 'rotate(-90deg)':'rotate(90deg)'}"
							style="width: 10px;height: 16px;margin-right: 15px;" @click="submit" />
						<!-- 下拉菜单
                <i class="el-icon-arrow-down el-icon--right"></i> -->
					</span>
					<el-dropdown-menu slot="dropdown" style="width: 226px;">
						<el-dropdown-item :command="item.type" v-for="(item,index) in uploadList" :key="index"
							v-if="index==0">
							<div class="flexal">
								<img :src="item.icon" style="width: 20px;height: 20px;margin-right: 6px;" />
								<div class="dropdown-text">{{item.text}}</div>
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<!-- 搜索框 -->
			<div class="left-item" style="margin-top: 24px;margin-bottom: 27px;">
				<el-input v-model="input" placeholder="搜索" @change="handinitload">
					<div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
						<div class="prefix-icon3"></div>
					</div>
				</el-input>
			</div>

			<!-- <div class="flexal">
				<template v-for="(item,index) in tab">
					<div class="flexcumal" :style="{'margin-left': index == 0?  '12px':'52px'}" :key="index"
						@click="tabIndex = index">
						<div class="frame-text" :class="index == tabIndex? 'frame-text-active':''">{{item}}</div>
						<div class="bottom-line" v-if="index == tabIndex"></div>
						<div class="bottom-line" style="background: #FFFFFF;" v-else></div>
					</div>
				</template>
			</div> -->

			<div class="flexal" style="margin-top: 24px;">
				<div class="flexal" style="margin-left: 12px;">
					<el-checkbox v-model="checked" @change="checkAll"></el-checkbox>
					<div style="font-weight: 400;font-size: 14px;color: #4E4E4E;margin-left: 6px;">所有类型</div>
					<img :src="require('@/assets/img/right.png')"
						style="width: 9px;height: 15px;transform:rotate(90deg);margin-left: 15px;" />
				</div>
				<div class="flexal" style="margin-left: 443px;">
					<div style="font-weight: 400;font-size: 14px;color: #4E4E4E;margin-left: 6px;">不限归属</div>
					<img :src="require('@/assets/img/right.png')"
						style="width: 9px;height: 15px;transform:rotate(90deg);margin-left: 15px;" />
				</div>
				<div class="flexal" style="margin-left: 389px;">
					<div style="font-weight: 400;font-size: 14px;color: #4E4E4E;margin-left: 6px;">最近打开</div>
					<img :src="require('@/assets/img/right.png')"
						style="width: 9px;height: 15px;transform:rotate(90deg);margin-left: 15px;" />
				</div>
			</div>

			<div style="width: 100%;height: 373px;overflow:auto;padding-top: 23px;" v-infinite-scroll="scrollLoad">
				<template v-for="(item,index) in list">
					<div class="frame-item-content flexal" :class="listIndex == index ? 'frame-item-content-active':''"
						:key="index" @click="listIndex = index">
						<div style="height: 100%;width: 560px;" class="flexal">
							<el-checkbox v-model="item.ischecked" @click="checkPeople(index)" class="flexal"
								style="margin-left: 7px;"></el-checkbox>
							<img :src="fileIcon[item.FileSuffix]" style="width: 33px;height: 34px;margin-left: 6px;" />
							<div class="frame-content-text" style="margin-left: 12px;">{{item.FileName}}</div>
						</div>
						<div style="height: 100%;width: 470px;" class="flexal frame-content-text">{{item.username}}
						</div>
						<div class="frame-content-text">{{dateFtt(item.CreateTime,'yyyy/MM/dd hh:mm')}}</div>
					</div>
				</template>
			</div>
			<div class="frame-line"></div>

			<div class="flexal" style="margin-top: 16px;margin-left: 24px;" v-if="checkids.length>0">
				<div class="delete-frame-button flexcen" @click="handelDel()">
					从列表中移除
				</div>
				<!-- 	<div class="create-frame-button flexcen">
					创建副本
				</div> -->
			</div>
		</div>
		<!-- 删除聊天记录弹窗 -->
		<detelePop :dialogVisibleShow="dialogVisibleDelete" :type="1" @updateDialog="updateDialog"></detelePop>
		<el-upload class="upload-resource" ref="uploadInstance" action="#" accept="application/*"
			:show-file-list="false" :http-request="sendUpload">
		</el-upload>
	</div>
</template>

<script>
	import detelePop from '../pop-up/detelePop.vue'
	export default {
		name: 'cloudIndex',
		props: {
			msg: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				input: '', //搜索文本
				fileList: [{
					icon: require('@/assets/img/messageRight/word.png'),
					text: '创建Word文档',
					type: 0,
				}, {
					icon: require('@/assets/img/messageRight/ppt.png'),
					text: '创建PDF',
					type: 1,
				}, {
					icon: require('@/assets/img/messageRight/xlx.png'),
					text: '创建表格',
					type: 2,
				}, ],
				uploadList: [{
					icon: require('@/assets/img/cloud/fileIcon.png'),
					text: '上传文件',
					type: 3,
				}, {
					icon: require('@/assets/img/cloud/folder.png'),
					text: '上传文件夹',
					type: 4,
				}, {
					icon: require('@/assets/img/cloud/onLine.png'),
					text: '导入在线文档',
					type: 5,
				}, ],
				tab: [
					'最近', '目录'
				],
				list: [],
				listIndex: 0, //文件列表下标
				tabIndex: 0, //最近/目录
				fileShow: false, //是否显示新建弹窗
				cloudShow: false, //是否显示上传弹窗
				checked: false, //全选
				commandType: 0, //命令类型 0:上传word 1:上传pdf  2:xls 3:word/pdf/xls
				//查询信息
				query: {
					page: 0,
					limit: 20,
					queryId: '', //搜索指定用户
					search: '', //搜索文件名称
					dicttype: '', //文件后缀名称
				},
				total: 0, //总的文件数
				checkids: [],
			}
		},
		components: {
			detelePop
		},
		watch: {
			list: {
				handler(value) { //这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
					// console.log('数组变化',value)
					let checked = true
					let ids = []
					value.forEach(element => {
						if (element.ischecked == false) {
							// num++
							checked = false
						} else {
							ids.push(element.DocumentId)
						}
					});
					this.checkids = ids
					this.checked = checked
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
		},
		computed: {
			// 文件消息图标
			fileIcon() {
				let fileIcon = []
				fileIcon['.ppt'] = require('@/assets/img/messageRight/ppt.png')
				fileIcon['.pdf'] = require('@/assets/img/messageRight/pdf.png')
				fileIcon['.doc'] = require('@/assets/img/messageRight/word.png')
				fileIcon['.docx'] = require('@/assets/img/messageRight/word.png')
				fileIcon['.xls'] = require('@/assets/img/messageRight/xlx.png')
				fileIcon['.xlsx'] = require('@/assets/img/messageRight/xlx.png')
				fileIcon['.zip'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.rar'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.txt'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.package'] = require('@/assets/img/messageRight/package.png')
				// console.log('fileIcon',fileIcon['ppt'])
				return fileIcon
			}
		},
		mounted() {

		},

		methods: {
			submit(index) {
				this.tabIndex = index
				this.$emit('cloudIndexSubmit', index)
			},
			handleCommand(type) {
				console.log('触发了', type)
				this.commandType = type
				if (type < 4) {
					document.querySelector(".upload-resource .el-upload").click()
				}
			},
			change(e) {
				console.log('下拉框出现', e)
				this.fileShow = e
			},
			changeCloud(e) {
				this.cloudShow = e
			},
			// 弹窗关闭回调
			updateDialog() {
				// console.log('回调了')
				this.dialogVisibleDelete = false
			},
			// 全选
			checkAll(e) {
				console.log('修改后的值', e)
				var value = this.list
				value.forEach(element => {
					console.log('全选', element)
					element.checked = e
				});
				this.list = value
			},
			scrollLoad() {
				console.log('滑底触发')
				if (this.query.page * this.query.limit <= this.total) {
					this.getlists(true)
				}
			},
			handinitload() {
				this.list = []
				this.query.page = 0
				this.query.search = this.input
				this.getlists(true)
			},
			async getlists(isnext) {
				if (isnext) this.query.page += 1
				var res = await this.$request.post('/document/docList', this.query)
				if (res.code == 200) {
					this.total = res.data.total
					this.list = this.list.concat(res.data.items)
				}
			},
			async sendUpload(item) {

				var ftype = this.getFileTypeByMime(item.file.type)
				console.log('文件类型', ftype)
				if (this.commandType < 2 && ftype != 'document') {
					this.$message(`请上传${(this.commandType==0?'word':'pdf')}类型文件`)
					return false

				} else if (this.commandType == 2 && ftype != 'spreadsheet') {
					this.$message(`请上传xls类型文件`)
					return false
				} else if (this.commandType == 3 && ftype != 'spreadsheet' && ftype != 'document') {
					this.$message(`请上传word/xls/pdf类型文件`)
					return false
				}

				const formData = new FormData()
				var fileno = new Date().getTime();
				if (fileno && fileno != '') formData.append('uploadfileNo', fileno)
				var groupno = 'api_yundoument_groupno:000000'
				if (groupno && groupno != '') formData.append('groupNo', groupno)
				formData.append('uploadfile', item.file)
				var res = await this.$request.post('/upload/UploadFile', formData)
				console.log(res)
				if (res.code == 200) {
					this.handinitload()
				}
				//成功后清除文件列表
				this.$refs.uploadInstance.clearFiles()
			},
			async handelDel() {
				this.$confirm(`确定移除文件?`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					if (this.checkids.length < 1) {
						this.$message('请选择文件')
						return false;
					}
					var data = {
						ids: this.checkids
					}
					var res = await this.$request.post('/document/delDocument', data)
					if (res.code == 200) {
						this.$message.success('成功')
						this.handinitload()
					}

				})
			},
			//发送云文档信息
			async sendyunmsg(info,roomid){
				//roomid 选择的群组
				//info 云文档信息
				
				var msgName = info.FileName //文件名称
				var suffix = this.getFileExtension(msgName) //文件后缀
				var sizestr=`${info.FileSize}MB`
				var content = {
					suffix: suffix, //后缀
					url: info.FileUrl, //线上地址
					size: sizestr,
					isxiazai: false, //是否已下载
					localurl: '', //本地地址
					mimetype: suffix, //文件mime类型
					filetype: suffix //文件类型
				}
				var msgdata = JSON.stringify(content)
				//判断文件类型  1-语音 2-视频 3-链接 4-文件 5-图片 6-云文档
				var msgType = 6
				var data = {
					MsgType: msgType,
					RoomId:roomid,
					MsgName: msgName,
					MsgContent: msgdata
				}
				var rs = await this.$request.post('/chat/sendMsg', data)
				if (rs.code == 200) {
					this.$message.success('成功')
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		width: 1226px;
		// height: 822px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;

		.people-title {
			// margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
		}

		.left-item {
			margin-left: 12px;
			margin-top: 5px;
			display: flex;
			width: 465px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

			.item-bubble {
				width: 15px;
				height: 15px;
				background: #F65160;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				color: #FFFFFF;
				font-weight: 400;
			}
		}

		.frame-item {
			margin-top: 5px;
			margin-left: 12px;
			width: 185px;
			height: 36px;
			background: #FFFFFF;
			color: #858A99;
			border-radius: 8px 8px 8px 8px;
		}

		.frame-item-active {
			background: #EFF2FF;
			color: #0052D9;
		}

		.frame-item-text {
			font-size: 15px;

			font-weight: 400;
		}

		.bottom-line {
			width: 18px;
			height: 3px;
			background: #0052D9;
			border-radius: 2px 2px 2px 2px;
		}

		.frame-text {
			font-weight: 400;
			font-size: 16px;
			color: #858A99;
		}

		.frame-text-active {
			color: #0052D9;
		}

		.frame-item-content {
			margin-left: 5px;
			width: 1204px;
			height: 75px;
			background: #FFFFFF;
			border-radius: 16px 16px 16px 16px;
		}

		.frame-item-content-active {
			background: #F2F3FF;
		}

		.frame-content-text {
			font-weight: 400;
			font-size: 15px;
			color: #000000;
		}

		.frame-line {
			margin-left: 18px;
			width: 1185px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}

	.dropdown-frame {
		// padding-top: 12px;
		margin-left: 12px;
		width: 227px;
		height: 62px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #DEE0E3;

		.el-dropdown-link {
			width: 100%;
			height: 100%;
			cursor: pointer;
			// color: #409EFF;
		}

		.el-icon-arrow-down {
			font-size: 12px;
		}

		.dropdown-text {
			margin-left: 6px;
			font-weight: 400;
			font-size: 13px;
			color: #535D6D;
		}
	}

	.delete-frame-button {
		width: 136px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #D54941;
		font-size: 18px;
		color: #D54941;
	}

	.create-frame-button {
		margin-left: 18px;
		width: 136px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #0052D9;
		font-size: 18px;
		color: #0052D9;
	}

	/deep/.el-checkbox__inner {
		width: 18px;
		height: 18px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 5px;
		top: 2px;
	}
</style>
<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" width="884px" class="myDialogClass"
			@close="closeDialog">

			<div>
				<el-input type="textarea" :autosize="{ minRows: 10, maxRows: 20}" placeholder="请输入内容"
					v-model="input"></el-input>
			</div>
			<!--弹窗底部-->
			<template #footer>
				<span class="myDialogFooter">
					<el-button type="primary" @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="sumbitFun()"
						style="background: #0052D9;color: #FFFFFF;">确定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'noticePop',
		props: {
			// 是否显示弹窗
			dialogVisibleShow: {
				type: Boolean,
				default: false
			},
			roomid: {
				type: Number,
				default: 0
			},
			// 弹窗标题
			title: {
				type: String,
				default: '群公告'
			},
			intro: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				dialogVisible: false,
				input: '',
			};
		},

		watch: {
			// New: 新值 | Old: 老值
			dialogVisibleShow(New, Old) {
				this.dialogVisible = New
				//  console.log(`新值:${New} ——— 老值:${Old}`)
			},
			intro(New, Old) {
				this.input = New
				//  console.log(`新值:${New} ——— 老值:${Old}`)
			},

		},
		mounted() {
			//  console.log('组件初始化',this.dialogVisible)
		},
		methods: {
			closeDialog() {
				//  console.log('我被取消了',this.dialogVisibleMember)
				this.$emit('updateDialog')
			},
			async sumbitFun(){
				
				var data = {
					RoomId: this.roomid,
					Intro: this.input
				}
				var rs = await this.$request.post('/chat/editIntro', data)
				if (rs.code == 200) {
					this.$emit('updateDialog',this.input)
				}
			}
			
		},
	};
</script>

<style scoped lang="less">
	.el-dialog .el-dialog__footer {
		padding: 10px;
	}

	/deep/.el-dialog__body {
		padding: 0 1vw;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.el-textarea__inner {
		background: #F1F1F1;
		min-height: 214px;
	}

	.myDialogFooter .el-button {
		width: 113px;
		height: 45px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #0052D9;
		font-size: 16px;
		color: #0052D9;
	}
</style>
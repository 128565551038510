<template>
	<!-- 聊天界面 -->
	<div class="frame-message " v-if="!isEmpty(messageDetail)" ref="content1">

		<div class="title-frame flexal">

			<div class="avatar" v-if="avatarList.length !=0">
				<img v-for="(item,index) in avatarList" :src="item" :class="computedAvatar(avatarList)" :key="index" />
			</div>
			<div v-else>
				<img :src="messageDetail.avatar" class="imgIcon-avatar" @click="infoClick" />
				<div class="info-frame" v-if="openInfoFrame&&messageDetail.type==0">
					<img :src="messageDetail.avatar"
						style="width: 77px;height: 78px;border-radius: 50%;margin-top: 127px;margin-left: 21px;" />
					<div class="flexal">
						<div style="font-weight: 500;font-size:20px;color: #333333;margin-left: 40px;">姓名</div>
						<img :src="require('@/assets/img/message/start.png')" v-if="messageDetail.start"
							style="width: 16px;height: 16px;margin-left: 7px;" />
					</div>
					<div class="flexal" style="padding-left: 9px;margin-top: 23px;">
						<div class="info-frame-item flexcumal">
							<img :src="require('@/assets/img/messageRight/inof-message.png')"
								style="width: 28px;height: 28px;margin-top: 10px;" />
							<div style="font-weight: 400;font-size: 13px;color: #000000;">消息</div>
						</div>
						<div class="info-frame-item flexcumal">
							<img :src="require('@/assets/img/messageRight/info-audio.png')"
								style="width: 28px;height: 28px;margin-top: 10px;" />
							<div style="font-weight: 400;font-size: 13px;color: #000000;">语音</div>
						</div>
						<div class="info-frame-item flexcumal">
							<img :src="require('@/assets/img/messageRight/info-video.png')"
								style="width: 28px;height: 28px;margin-top: 10px;" />
							<div style="font-weight: 400;font-size: 13px;color: #000000;">视频</div>
						</div>
					</div>
					<div class="info-frame-buttom">
						<div class="flexal" style="height: 50%;" @click="openInfoFrameEdit = !openInfoFrameEdit">
							<div style="flex:1;font-weight: 400;font-size: 16px;color: #000000;margin-left: 19px;">备注与描述
							</div>
							<div style="font-weight: 400;font-size: 14px;color: #6E6E6E;">编辑内容</div>
							<img :src="require('@/assets/img/right.png')"
								style="width: 8px;height: 14px;margin-right: 18px;margin-left: 12px;" />
						</div>
						<div
							style="width: 352px;height: 0px;border: 1px solid #707070;opacity: 0.17;margin-left: 18px;">
						</div>
						<div class="flexal" style="height: 50%;">
							<div style="flex:1;font-weight: 400;font-size: 16px;color: #000000;margin-left: 19px;">
								设为星标联系人 </div>
							<el-switch v-model="setStart" active-color="#0052D9" inactive-color="#DCDFE6"
								style="margin-right: 18px;"></el-switch>
						</div>
					</div>
				</div>
				<div class="info-frame-edit" v-if="openInfoFrameEdit">
					<div class="info-frame-edit-text">备注与描述</div>
					<el-input v-model="mark" class="memberInput" style="margin-left: 16px;margin-top: 16px;"
						placeholder="请输入备注"></el-input>
					<div class="info-frame-edit-text" style="margin-top: 18px;">描述</div>
					<el-input type="textarea" class="textareaMark" :autosize="{ minRows: 12, maxRows: 12}"
						placeholder="请输入" style="margin:16px" v-model="input"></el-input>
					<div class="flexal" style="margin-top: 10px">
						<div class="info-frame-edit-button flexcen" style="margin-left: 18px;"
							@click="openInfoFrameEdit = !openInfoFrameEdit">取消</div>
						<div class="info-frame-edit-button flexcen"
							style="background: #0052D9;color: #FFFFFF;margin-left: 12px;"
							@click="openInfoFrameEdit = !openInfoFrameEdit">保存</div>
					</div>
				</div>
			</div>
			<div class="title-content">
				<div class="flexal" style="width: 400px;">
					<div class="ellipsis title-text">{{messageDetail.RoomName}}</div>
					<div class="flexal" v-if="messageDetail.type == 1">
						<img :src="require('@/assets/img/messageRight/people.png')"
							style="width: 16px;height: 17px; margin-left: 13px;" />
						<div class="title-num">{{ulist.length}}</div>
					</div>
					<img :src="require('@/assets/img/message/start.png')" v-if="messageDetail.IsStar>0"
						style="width: 16px;height: 16px;margin-left: 7px;" />
				</div>
				<!-- 标题菜单栏 -->
				<div class="flexal" style="margin-top: 5px;">
					<template v-for="(item,index) in optList">
						<div class="title-item flexal" :class="optListIndex == index? 'title-item-active':''"
							:key="index" @click="chooseTabIndex(index)"
							v-if="item.type <= messageDetail.type && messageDetail.type < 2">
							<img :src="optListIndex == index? item.activeIcon : item.icon"
								style="width: 13px;height: 13px;" />
							<div class="title-text-item" :class="optListIndex == index? 'title-text-item-active':''">
								{{item.text}}
							</div>
						</div>
					</template>
				</div>
			</div>

			<div class="flexal" v-if="messageDetail.type <=1">
				<!--  -->
				<img :src="require('@/assets/img/messageRight/peopleButton.png')"
					style="width: 28px;height: 28px;margin-right: 18px;" @click="inDevelopment" />
				<!-- 视频图标 -->
				<div>
					<img :src="require('@/assets/img/messageRight/video.png')" @mouseenter="onMouseOverO"
						@mouseleave="onMouseOutO"
						style="width: 25px;height: 19px;margin-right: 17px;margin-top: 5px;" />
					<div class="frame-video-block" v-if="phoneShow" @mouseenter="onMouseOverT"
						@mouseleave="onMouseOutT">
						<div class="flexal" style="height: 50%;" @click="openTelePhone(0)">
							<img :src="require('@/assets/img/messageRight/voice.png')"
								style="width: 21px;height: 21px;margin-left: 13px;" />
							<div style="font-weight: 400;font-size: 16px;color: #333333;margin-left: 8px;">语音通话</div>
						</div>
						<div class="flexal" style="margin-top: 10px;" @click="openTelePhone(1)">
							<img :src="require('@/assets/img/messageRight/video-phone.png')"
								style="width: 21px;height: 21px;margin-left: 13px;" />
							<div style="font-weight: 400;font-size: 16px;color: #333333;margin-left: 8px;">视频通话</div>
						</div>
					</div>
				</div>
				<div v-if="messageDetail.type ==0">
					<!-- 远程桌面图片 -->
					<img :src="require('@/assets/img/messageRight/computer.png')" @mouseenter="onMouseOver2"
						@mouseleave="onMouseOut2"
						style="width: 20px;height: 19px;margin-right: 17px;margin-top: 5px;" />
					<div class="frame-video-block" style="width: 190px;" v-if="openComputer" @mouseenter="onMouseOver3"
						@mouseleave="onMouseOut3">
						<div class="flexal" style="height: 50%;" @click="openLongFrame(0)">
							<img :src="require('@/assets/img/messageRight/ad41d0703ccdbfd7600a85710eeba68.png')"
								style="width: 21px;height: 21px;margin-left: 13px;" />
							<div style="font-weight: 400;font-size: 16px;color: #333333;margin-left: 8px;">请求控制对方电脑
							</div>
						</div>
						<div class="flexal" style="margin-top: 10px;" @click="openLongFrame(1)">
							<img :src="require('@/assets/img/messageRight/a40b7579c498c9ab0db1adeec58c444.png')"
								style="width: 21px;height: 21px;margin-left: 13px;" />
							<div style="font-weight: 400;font-size: 16px;color: #333333;margin-left: 8px;">邀请对方远程协助
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 更多图标 -->
			<div style="width: 25px;height: 19px;" class="flexcen" @click="openOptShow">
				<img :src="require('@/assets/img/messageRight/more.png')" style="width: 23px;height: 5px;" />
			</div>
		</div>

		<!-- 操作弹窗 -->
		<optMessage v-if="optShow" @optMessageFun="optMessageFun" :messageDetail="messageDetail" :ulist="ulist">
		</optMessage>
		<!-- 云文档/文件界面 -->
		<documentFrame v-if="optListIndex ==1 || optListIndex == 3" :optListIndex="optListIndex"
			:roomid="messageDetail.RoomId" :ulist="ulist"></documentFrame>
		<!-- 公告界面 -->
		<afficheFrame v-if="optListIndex == 2" @afficheSubimt="afficheSubimt" :messageDetail="messageDetail">
		</afficheFrame>
		<!-- 发送签收消息界面 -->
		<signInFrame v-if="optListIndex == 4" :roomid="messageDetail.RoomId" @signInSubmit="signInSubmit"
			:list="signInFrameUsers">
		</signInFrame>
		<!-- 审批详情 -->
		<auditFrame v-if="auditShow" :detail="auditList[auditIndex]" @auditSubmit="auditSubmit"></auditFrame>
		<!-- 远程桌面 -->
		<longFrame v-if="longFrameShow" @longFrameSubmit="longFrameSubmit" :type="longFrameType"
			:touser="longFrameUser"></longFrame>
		<!-- 公告详情 -->
		<detailFrame v-if="detailFrameShow" @detailSubmit="detailSubmit" :nId="noticeId"></detailFrame>


		<!-- 公告浮窗 -->
		<div class="frame-notice flexal"
			v-if="noticeShow&&messageDetail.type==1&&messageDetail.intro&&messageDetail.intro!=''">
			<img :src="require('@/assets/img/messageRight/notice-audio.png')"
				style="width: 19px;height: 19px;margin-left: 17px;" />
			<div class="notice-content ellipsis">{{messageDetail.intro}}</div>
		</div>

		<!-- 消息内容 -->
		<div class="content-frame" :style="{'height':(noticeShow&&messageDetail.type==1)? '527px':'585px'}"
			v-infinite-scroll="scrollLoad" v-if="messageDetail.type <= 1 || messageDetail.type == 4">
			<!-- 占位 -->
			<!-- <div class="frame-notice" style="position: relative;opacity: 0;"></div> -->
			<template v-for="(message, index) in messages">
				<div v-if="message.MsgType==31">
					<!-- 创建群时的时间 -->
					<div class="chat-time flexcen">{{dateFtt(message.CreateTime,'yyyy/MM/dd hh:mm')}}</div>

					<!-- 聊天界面群操作 -->
					<div class="frame-notice-title flexcumal">
						<div class="notice-title">欢迎加入群组</div>
						<div class="notice-title-tips">你可以尝试以下操作，快速开始协作</div>
						<div class="flexal" style="margin-top: 21px;">
							<div class="notcie-title-button flexcen" style="margin-right: 21px;">完善群消息</div>
							<div class="notcie-title-button flexcen">完善群公告</div>
						</div>
						<!-- 创建通知 -->
						<div class="tips-name flexal">
							{{message.Name}}
							<p style="font-weight: 400;font-size: 14px;color: #858585;margin-left: 9px;">创建了群组</p>
						</div>
					</div>
				</div>
				<div v-else>
					<!-- 消息时间 -->
					<div class="chat-time flexcen" v-if="message.MsgType=='time'">
						{{dateFtt(message.CreateTime,'yyyy/MM/dd hh:mm')}}
					</div>
					<!-- 邀请通知 -->
					<div class="tips-name" style="display: flex;justify-content: center;margin-top: 20px;"
						v-if="message.MsgType == 32">
						<!-- {{message.Name}} -->
						<span
							style="font-weight: 400;font-size: 14px;color: #858585;margin-left: 15px;width: 350px;text-align: center;">
							{{message.MsgContent}}
						</span>
					</div>

					<!-- 消息整体(这个数据不包括时间)-->
					<div :class="message.UserId==myuser.ID?'chat-message-me':'chat-message-other'"
						:style="{'padding-bottom':messages.length-1===index?'2rem':'none'}" v-if="message.MsgType<=10">

						<!--            消息头像-->
						<div
							:class="message.UserId==myuser.ID?'message-me-asWhole-headPortrait':'message-other-asWhole-headPortrait'">
							<img :src="getuserinfo(message.UserId,1)" class="examineeFace_logo_style">
						</div>
						<!--          消息-->
						<div
							:class="message.UserId==myuser.ID?'message-me-asWhole-right':'message-other-asWhole-right'">
							<!--            消息上面-->
							<div
								:class="message.UserId==myuser.ID?'message-me-asWhole-top':'message-other-asWhole-top'">
								{{getuserinfo(message.UserId,0)}}
							</div>
							<!-- 文本消息 -->
							<div :class="message.UserId==myuser.ID?'':'flexal'">
								<div :class="message.UserId==myuser.ID?'message-me':'message-other'"
									v-if="message.MsgType == 0||message.MsgType == 3 ">
									{{ message.MsgContent }}
								</div>
								<!-- 图片消息 -->
								<img :src="message.MsgContent.url" class="meesage-img" model="aspectFit"
									v-if="message.MsgType == 5 " />
								<!-- 视频消息 -->
								<video :src="message.MsgContent.url" class="meesage-img" :controls="true"
									v-if="message.MsgType == 2 "></video>
								<!-- 文件消息 -->
								<div :class="message.UserId==myuser.ID?'message-me':'message-other'"
									v-if="message.MsgType == 4">
									<div
										:style="{'display': 'flex','justify-content': 'space-between','flex-direction': message.UserId==myuser.ID? 'row':'row-reverse'}">
										<div style="margin-left: 10px;">
											<div class="meesage-file-text">{{message.MsgName}}</div>
											<div class="meesage-file-text1">{{message.MsgContent.size}}</div>
										</div>
										<div>
											<img :src="fileIcon[message.MsgContent.suffix]"
												style="width: 48px;height: 46px;" />
										</div>
									</div>
								</div>
								<!-- 签收消息 -->
								<div :class="message.UserId==myuser.ID?'message-me':'message-other'"
									v-if="message.MsgType == 10 " style="width: 200px;">
									<div>{{ message.MsgContent }}</div>
									<div
										style="width: 100%;height: 0px;border: 1px solid #002565;opacity: 0.26;margin-top: 10px;">
									</div>
									<div style="width: 100%;" class="flexcen" @click="sendsignmsg(message)">
										<div class="message-signButton flexcen"
											:class="message.IsCall>0? 'message-signButton-receive':''">
											{{message.IsCall>0? '  已签收':'未签收'}}
										</div>
									</div>
								</div>
								<!-- 视频通话 -->
								<div :class="message.UserId==myuser.ID?'message-me':'message-other'"
									v-if="message.MsgType == 8 ">
									<div :style="{'flex-direction': message.UserId==myuser.ID? 'row':'row-reverse'}"
										class="flexal">
										<div class="meesage-file-text"
											:style="{'width': '100%','margin-left':message.UserId==myuser.ID? '0px':'10px','margin-right':message.UserId==myuser.ID? '10px':'0px'}">
											{{message.MsgContent}}
										</div>
										<img :src="require('@/assets/img/messageRight/videoCall.png')"
											style="width: 25px;height: 15px;" />
									</div>
								</div>
								<!-- 语音通话 -->
								<div :class="message.UserId==myuser.ID?'message-me':'message-other'"
									v-if="message.MsgType == 7 ">
									<div :style="{'flex-direction': message.UserId==myuser.ID? 'row':'row-reverse'}"
										class="flexal">
										<div class="meesage-file-text"
											:style="{'width': '100%','margin-left':message.UserId==myuser.ID? '0px':'10px', 'margin-right':message.UserId==myuser.ID? '10px':'0px'}">
											{{message.MsgContent}}
										</div>
										<img :src="require('@/assets/img/messageRight/audioCall.png')"
											style="width: 25px;height: 15px;" />
									</div>
								</div>
								<!-- 视频会议消息 -->
								<div :class="message.UserId==myuser.ID?'message-me':'message-other'"
									v-if="message.MsgType == 9 " style="width: 250px;">
									<div class="flexal" style="margin-bottom: 5px;">
										<img :src="require('@/assets/img/messageRight/videoMeeting.png')"
											style="width: 21px;height: 15px;margin-right: 10px;" />
										<div class="meesage-file-text" style="width: 100%;font-size: 16px;">
											{{ message.MsgName }}({{ dateFtt(message.MsgContent.mdate,'yyyy/MM/dd hh:mm') }})
										</div>
									</div>
									<div class="flexal" style="margin-bottom: 5px;">
										<img :src="require('@/assets/img/messageRight/meetingID.png')"
											style="width: 21px;height: 15px;margin-right: 10px;" />
										<div class="meesage-file-text" style="width: 100%;">
											{{ message.MsgContent.mcode }}
										</div>
									</div>
									<div class="flexal" style="margin-bottom: 5px;">
										<img :src="require('@/assets/img/messageRight/meetingPeople.png')"
											style="width: 21px;height: 16px;margin-right: 10px;" />
										<div style="overflow: hidden;width: 80%;white-space: nowrap;">
											<!-- <img :src=""
												style="width: 25px;height: 25px;margin-right: 10px;border-radius: 50%;"
												 /> -->
											{{getuserinfo(message.MsgContent.iuserid,0)}}邀请
										</div>
									</div>
									<div class="meetingButton flexcen"
										:class="message.MsgContent.mstate==2? 'meetingButton-end':''">
										{{message.MsgContent.mstate==2? '已结束':'正在进行中'}}
									</div>
								</div>
								<!-- 是否已读 -->
								<div style="flex-direction: column-reverse;height: 100%;display: flex;margin-left: 10px;"
									v-if="message.UserId!=myuser.ID" class="message-read"
									:class="message.State == 1? '':'message-read-active'">
									{{message.State ==1? '已读':'未读'}}
								</div>
								<div v-else class="message-read" style="margin-top: 6px;"
									:class="message.State == 1? '':'message-read-active'">
									{{message.State ==1? '已读':'未读'}}
								</div>

							</div>
						</div>
					</div>
				</div>
			</template>
		</div>

		<!-- 审批内容 -->
		<div class="content-frame" style="height: 665px;" v-infinite-scroll="scrollLoad" v-if="messageDetail.type == 2">
			<div v-for="(message, index) in auditList" :key="index" @click="openAudit(index)">
				<!-- 消息时间 -->
				<div class="chat-time flexcen">{{message.CreatedDate}}</div>

				<!-- 消息整体-->
				<div :class="'chat-message-other'" :style="{'padding-bottom':auditList.length-1===index?'2rem':'none'}">
					<!-- 消息头像-->
					<div :class="'message-other-asWhole-headPortrait'">
						<img :src="messageDetail.avatar" class="examineeFace_logo_style">
					</div>
					<!--          消息-->
					<div :class="'message-other-asWhole-right'">
						<!-- 文本消息 -->
						<div :class="'flexal'">
							<!-- 审批消息 -->
							<div :class="'message-other'" style="max-width: 441px;margin-top:0px">
								<div style="font-weight: 400;font-size: 16px;color: #15181D;">审批</div>
								<div style="font-weight: 500;font-size: 16px;color: #000000;">{{message.FormTypeCName}}
								</div>
								<div class="audit-content">
									<div style="font-weight: 400;font-size: 14px;color: #15181D;margin-left: 14px;">
										{{message.FormDesc}}
									</div>
									<div
										style="font-weight: 400;font-size: 12px;color: #6C707B;margin-left: 14px;margin-top: 5px;">
										{{message.FormName}}
									</div>
								</div>
								<div class="aduitButton flexcen">{{message.ID>0? '查看详情':'查看并处理'}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 公告内容 -->
		<div class="content-frame" style="height: 665px;" v-infinite-scroll="scrollLoad" v-if="messageDetail.type == 3">
			<div v-for="(message, index) in noticeList" :key="index" @click="openDetail(message.MsgContent.NoticeId)">
				<!-- 消息时间 -->
				<div class="chat-time flexcen">{{dateFtt(message.CreateTime,'yyyy/MM/dd hh:mm')}}</div>

				<!-- 消息整体-->
				<div :class="'chat-message-other'"
					:style="{'padding-bottom':noticeList.length-1===index?'2rem':'none'}">
					<!-- 消息头像-->
					<div :class="'message-other-asWhole-headPortrait'">
						<img :src="messageDetail.avatar" class="examineeFace_logo_style">
					</div>
					<!--          消息-->
					<div :class="'message-other-asWhole-right'">
						<!-- 文本消息 -->
						<div :class="'flexal'">
							<!-- 消息 -->
							<div :class="'message-other'"
								style="max-width: 461px;margin-top:0px;padding: 0px 0px;padding-bottom: 15px; border-radius: 16px;">
								<img :src="message.MsgContent.ImgUrl"
									style="width: 461px;height: 194px; border-radius: 16px 16px 0px 0px;" />
								<div style="font-weight: 400;font-size: 12px;color: #000000;margin-top: 5px;margin-left: 11px;"
									class="ellipsis">
									{{message.MsgContent.Title}}
								</div>
								<div class="message-line" style="margin-left: 12px;margin-top: 10px;"></div>
								<div
									style="font-weight: 400;font-size: 10px;color: #333333;margin-top: 10px;margin-left: 12px;">
									点击查看详情</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 机器人内容 -->
		<div class="content-frame" style="height: 585px;" v-infinite-scroll="scrollLoad" v-if="messageDetail.type == 5">
			<div v-for="(message, index) in robotList" :key="index">
				<!-- 消息时间 -->
				<!-- <div class="chat-time flexcen" v-if="message.type=='time'">{{message.content}}</div> -->
				<!-- 消息整体(这个数据不包括时间)-->
				<div :class="message.UserId>0?'chat-message-me':'chat-message-other'"
					:style="{'padding-bottom':messages.length-1===index?'2rem':'none'}" v-if="message.type!='time'">

					<!--            消息头像-->
					<div
						:class="message.UserId>0?'message-me-asWhole-headPortrait':'message-other-asWhole-headPortrait'">
						<img :src="message.UserId>0?myuser.Avatar:messageDetail.avatar" class="examineeFace_logo_style">
					</div>
					<!--          消息-->
					<div :class="message.UserId>0?'message-me-asWhole-right':'message-other-asWhole-right'">
						<!--            消息上面-->
						<div :class="message.UserId>0?'message-me-asWhole-top':'message-other-asWhole-top'"
							v-if="message.UserId>0">
							{{myuser.UserName}}
						</div>
						<!-- 文本消息 -->
						<div :class="message.UserId>0?'':'flexal'">
							<div :class="message.UserId>0?'message-me':'message-other'" style="max-width: 100%;">
								<!-- {{ message.content }} -->
								<div v-html="message.MsgContent"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 通知集成内容 -->
		<div class="content-frame" style="height: 665px;" v-infinite-scroll="scrollLoad" v-if="messageDetail.type == 6">
			<div v-for="(message, index) in pushmsgList" :key="index">
				<!-- 消息时间 -->
				<div class="chat-time flexcen">{{dateFtt(message.CreateTime,'yyyy/MM/dd hh:mm')}}</div>

				<!-- 消息整体-->
				<div :class="'chat-message-other'"
					:style="{'padding-bottom':pushmsgList.length-1===index?'2rem':'none'}" style="padding: 1rem 33px;">
					<div :class="'message-other-asWhole-right'">
						<!-- 文本消息 -->
						<div :class="'flexal'">
							<!-- 审批消息 -->
							<div :class="'message-other'"
								style="max-width: 650px;width: 650px; margin-top:0px;padding: 0px 0px;padding-bottom: 15px; border-radius: 16px;">
								<div style="font-weight: 500;font-size: 18px;color: #000000;margin-top: 23px;margin-left: 21px;"
									class="ellipsis">{{message.Name}}</div>
								<div
									style="font-weight: 400;font-size: 16px;color: #000000;margin-top: 10px;margin-left: 21px;margin-right: 21px;">
									{{message.MsgContent}}
								</div>
							</div>
							<!-- <div class="bell-content"></div> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 添加群成员弹窗 -->
		<memberPop v-if="messageDetail.type == 1" :dialogVisibleShow="dialogVisibleMember" :type="3"
			@updateDialog_message="updateDialog1Click" :list="memberpoplist" :roomid="messageDetail.RoomId"></memberPop>

		<!-- 输入框 -->
		<div class="frame-input flexal"
			v-if="messageDetail.type <= 1 || messageDetail.type == 5|| messageDetail.type == 4">
			<div class="frame-border flexal">
				<el-input style="width: 555px;margin-left: 12px;background: #F6F6F6;border: 0px solid #DCDFE6;"
					v-model="content" placeholder="输入消息"></el-input>
				<el-upload action="#" accept="image/png,image/jpg,video/*,audio/*,application/*"
					v-if="messageDetail.type != 5" :show-file-list="false" :http-request="sendmsgbyUpload">
					<img :src="require('@/assets/img/messageRight/add.png')"
						style="width: 25px;height: 25px;margin-left: 22px;" />
				</el-upload>
				<img :src="require('@/assets/img/messageRight/send.png')" @click="sendmsg()"
					style="width: 23px;height: 23px;margin-left: 24px;" />
			</div>
		</div>

		<!-- <telephonePop :dialogVisibleShow="dialogVisibleTelePhone"></telephonePop> -->

	</div>

	<div class="frame-message " v-else ref="content1">
	</div>

</template>

<script>
	import memberPop from '../pop-up/memberPop.vue'
	import optMessage from './optMessage.vue';
	import documentFrame from './documentFrame.vue';
	import afficheFrame from './afficheFrame.vue';
	import signInFrame from './signInFrame.vue';
	import auditFrame from './auditFrame.vue';
	import telephonePop from '../pop-up/telephonePop.vue'
	import longFrame from './longFrame.vue';
	import detailFrame from './detailFrame.vue';

	export default {
		name: 'message',
		props: {
			messageDetail: {
				type: Object,
				default: {}
			}
		},

		data() {
			return {
				src: 'https://cdn.uviewui.com/uview/album/1.jpg', //用户头像路径(模拟用)
				poster: "https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/2024/0712/202407121752186069.png", //公告图片                                             
				avatarList: [],
				avatar: require('@/assets/img/messageRight/group.png'), //最顶部聊天界面头像
				group: 1, //是否群聊天(暂未用到)
				optList: [{
						text: '消息',
						icon: require('@/assets/img/messageRight/message.png'),
						activeIcon: require('@/assets/img/messageRight/messageIcon.png'),
						type: 0
					}, {
						text: '云文档',
						icon: require('@/assets/img/messageRight/cloud.png'),
						activeIcon: require('@/assets/img/messageRight/cloudIcon.png'),
						type: 0
					}, {
						text: '群公告',
						icon: require('@/assets/img/messageRight/notice.png'),
						activeIcon: require('@/assets/img/messageRight/noticeIcon.png'),
						type: 1
					}, {
						text: '文件',
						icon: require('@/assets/img/messageRight/file.png'),
						activeIcon: require('@/assets/img/messageRight/fileIcon.png'),
						type: 0
					},
					{
						text: '签收消息',
						icon: require('@/assets/img/messageRight/sign.png'),
						activeIcon: require('@/assets/img/messageRight/signIcon.png'),
						type: 0
					},
				],
				optListIndex: 0, //群名称下方的操作栏
				// 消息数据集(type有文本，图片，视频，文件，时间)
				// sender:发送人，content：文本内容，type:类型，read:是否已读，receive:是否签收，suffix：文件后缀，size：文件大小
				messages: [],
				//审批消息
				auditList: [],

				//机器人消息
				robotList: [],

				content: '', //文本输入
				noticeShow: true, //是否显示公告
				// 组织成员
				ulist: [],
				auditIndex: 0, //点击审批详情下标
				phoneShow: false, //视频通话和语音通话弹窗
				falg: true, //衔接参数
				openComputer: false, //远程控制弹窗
				falgs: false, //远程弹窗衔接参数
				longFrameType: 0, //远程桌面控制/邀请
				longFrameUser: null, //远程桌面控制/邀请用户
				setStart: false, //设为星标联系人 
				// openInfoFrame:false,                      //个人信息弹窗
				falg2: false, //个人弹窗衔接参数
				mark: '', //备注
				noticeList: [], //公告信息
				pushmsgList: [], //通知集成信息
				query: {
					id: 0,
					page: 1, //页码
					limit: 20, //条数
					isnext: true, //可否加载下一页
					isload: false, //加载中
				},
				noticeId: 0, //公告详情ID
				myuser: {}, //当前用户
				dialogVisibleMember: false, //选择用户
				memberpoplist: [], //群组用户
				teltype: 0, //0:语言通话 1:视频通话
				signInFrameUsers: [], //签到用户
			}
		},
		watch: {
			messageDetail: {
				handler(newValue, oldValue) { //这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
					// console.log('message-数组变化', newValue, oldValue)
					this.optListIndex = 0

					if (newValue && newValue.RoomId > 0) {
						var udata = localStorage.getItem('udata')
						if (udata) {
							var user = JSON.parse(udata);
							this.myuser = user
						}

						//未读信息变更已读
						this.msgUpdState(newValue.RoomId, 1)

						if (oldValue == null || oldValue == undefined || oldValue.RoomId != newValue.RoomId) {
							this.initShowD(newValue.RoomId)
						}
					}
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
		},
		created() {
			document.addEventListener("click", this.myFunction);

			// console.log('user', this.udata)
		},
		components: {
			optMessage, //右侧菜单栏
			documentFrame, //云文档/文件
			afficheFrame, //公告
			signInFrame, //签收消息
			auditFrame, //审核详情
			telephonePop, //语音通话/视频通话弹窗
			longFrame, //远程桌面
			detailFrame, //公告详情
			memberPop, //选择用户
		},
		computed: {
			// 文件消息图标
			fileIcon() {
				let fileIcon = []
				fileIcon['ppt'] = require('@/assets/img/messageRight/ppt.png')
				fileIcon['pdf'] = require('@/assets/img/messageRight/pdf.png')
				fileIcon['doc'] = require('@/assets/img/messageRight/word.png')
				fileIcon['docx'] = require('@/assets/img/messageRight/word.png')
				fileIcon['xls'] = require('@/assets/img/messageRight/xlx.png')
				fileIcon['xlsx'] = require('@/assets/img/messageRight/xlx.png')
				fileIcon['zip'] = require('@/assets/img/messageRight/package.png')
				fileIcon['rar'] = require('@/assets/img/messageRight/package.png')
				fileIcon['txt'] = require('@/assets/img/messageRight/package.png')
				fileIcon['package'] = require('@/assets/img/messageRight/package.png')
				// console.log('fileIcon',fileIcon['ppt'])
				return fileIcon
			}
		},
		created() {
			if (this.$eventBus) {
				this.$eventBus.$on("message", (WebSocketdata) => {
					console.log('信息详情页-message', WebSocketdata)
					this.handelDetailMsg(WebSocketdata)
				});
			}
		},
		mounted() {

			console.log('this.$refs.content1', this.$refs.content1)
			this.$watermark.set("水印", this.$refs.content1)
			//this.getMediaDevices()

		},
		beforeDestroy() {
			this.$watermark.set("", this.$refs.content1);
		},
		methods: {
			// 查看当前可用的媒体设备
			getMediaDevices() {
				try {
					navigator.mediaDevices.enumerateDevices().then(function(devices) {
						devices.forEach(function(device) {
							switch (device?.kind) {
								case 'audioinput':
									console.log('音频输入设备(麦克风|话筒)：', device);
									break;
								case 'audiooutput':
									console.log('音频输出设备(扬声器|音响)：', device);
									break;
								case 'videoinput':
									console.log('视频输入设备(摄像头|相机)：', device);
									break;
								default:
									console.log('当前可用的媒体设备: ', device);
									break;
							}
						});
					}).catch(function(err) {
						console.error(err);
					});
				} catch (err) {
					console.error(err);
				} finally {
					if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
						console.log("不支持mediaDevices.enumerateDevices(), 未识别到多媒体设备！");
					}
				}
			},
			//初始化页面
			initShowD(roomId) {
				console.log('进入initShowD')
				//弹窗
				this.optShow = false
				this.auditShow = false
				this.longFrameShow = false
				this.detailFrameShow = false
				this.optListIndex = 0
				if (this.messageDetail.type <= 1 || this.messageDetail.type == 4) {
					this.getusers(roomId)
				}
				this.messages = []
				this.query.page = 1
				this.query.isload = false
				if (this.messageDetail.type == 2) {
					this.auditList = []
					this.query.isnext = true
					this.query.page = 0
				}
				this.query.id = roomId
				this.handlegetlist()
			},

			// 切换导航栏
			chooseTabIndex(index) {
				// if(index>1){
				//   this.$message({
				//     message: '开发中，敬请等待',
				//     type: 'warning'
				//   });
				//   return
				// }
				this.optListIndex = index
				if (this.optListIndex == 4) {
					this.signInFrameUsers = this.ulist.filter(a => a.ID != this.myuser.ID)
				}

			},
			// 打开右侧操作栏
			openOptShow() {
				var that = this
				that.optShow = !this.optShow
				this.$overlay.show();
			},
			optMessageFun() {
				var that = this
				that.optShow = !this.optShow
				this.$overlay.hide();
			},
			// 滑底
			scrollLoad() {
				console.log('滑底触发')
				this.handlegetlist()
			},
			// 群公告界面按钮回调
			afficheSubimt(args) {
				console.log('回调参数', args)
				this.chooseTabIndex(0)
			},
			// 签收消息发送页面回调
			signInSubmit(args) {
				console.log('签收消息回调', args)
				this.chooseTabIndex(0)
			},
			// 鼠标移入1
			onMouseOverO(item) {
				this.phoneShow = true
			},
			// 鼠标移出1
			onMouseOutO() {
				setTimeout(() => {
					if (this.falg) {
						this.phoneShow = false
					}
				}, 100)
			},
			// 鼠标移入2
			onMouseOverT() {
				this.falg = false
			},
			// 鼠标移出2
			onMouseOutT() {
				this.falg = true
				this.phoneShow = false
			},

			// 鼠标移入1
			onMouseOver2(item) {
				this.openComputer = true
			},
			// 鼠标移出1
			onMouseOut2() {
				setTimeout(() => {
					if (this.falgs) {
						this.openComputer = false
					}
				}, 100)
			},
			// 鼠标移入2
			onMouseOver3() {
				this.falgs = false
			},
			// 鼠标移出2
			onMouseOut3() {
				this.falgs = true
				this.openComputer = false
			},
			// 开启语音/视频通话 type//0:语音通话 1:视频通话
			openTelePhone(type = 0) {
				console.log('udatatype', type)
				//判断群组还是单聊，群组需要选择用户
				this.teltype = type
				if (this.messageDetail.type == 0) {
					//单聊
					var udata = this.ulist
					this.sendTel(udata)

				} else if (this.messageDetail.type == 1) {
					//群组，显示选择用户
					var udata = this.ulist.filter(a => a.ID != this.myuser.ID)
					this.dialogVisibleMember = true
					this.memberpoplist = udata
				}
			},

			//选择通话用户回调
			updateDialog1Click(udata) {
				this.dialogVisibleMember = false
				console.log('udata', udata)
				if (udata && udata.length > 0) {
					udata.push(this.myuser)

					this.sendTel(udata)

				}
			},
			//发送通话请求
			async sendTel(userlist) {

				var myuserid = this.myuser.ID
				var msgdata = []
				userlist.map(it => {
					if (it.uid != myuserid) {
						msgdata.push({
							ID: it.ID,
							UserName: it.UserName,
							Avatar: it.Avatar,
							state: 0,
						})
					}
				})
				var msgtype = (this.teltype > 0 ? 8 : 7)
				var rtcdata = JSON.stringify(msgdata);
				var data = {
					MsgType: msgtype,
					RoomId: this.messageDetail.RoomId,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendMsg', data)
				if (rs.code == 200) {
					this.$overlayPhone.show(this.teltype, userlist, 1)
				}




			},
			// 开启远程桌面
			openLongFrame(index) {
				this.longFrameUser = this.ulist.find(a => a.ID != this.myuser.ID)
				console.log('this.longFrameUser', this.longFrameUser)
				this.longFrameShow = true
				this.longFrameType = index
			},
			// 关闭远程桌面
			longFrameSubmit() {
				this.longFrameShow = false
			},
			// 头像个人信息弹窗
			infoClick() {
				this.openInfoFrame = !this.openInfoFrame
				this.$overlay.show();
			},
			// 查看公告详情
			openDetail(nid) {
				this.noticeId = nid
				this.detailFrameShow = true
				this.$overlay.show();
			},
			//关闭公告详情界面
			detailSubmit() {
				this.detailFrameShow = false
				this.$overlay.hide();
			},
			//获取消息列表
			async handlegetlist() {


				var rlist = []
				if (this.messageDetail.type != 2) {
					if (!this.query.isload) {
						this.query.isload = true
						if (this.query.page == 1) {
							this.query.isnext = true
							this.noticeList = []
						}
						if (this.query.isnext) {
							rlist = await this.msggetlist(this.query.id, this.query.page, this.query.limit)
							this.query.page += 1

							if (rlist == undefined || rlist == null) rlist = []
							if (rlist.length < this.query.limit) this.query.isnext = false
							rlist.map(it => {
								if (it.MsgType == 10) {
									it = this.getmsg10(it)
								} else if (it.MsgType > 0 && it.MsgType < 7 && it.MsgType != 3) {
									if (typeof it.MsgContent === 'string') {
										it.MsgContent = JSON.parse(it.MsgContent)
									}
								} else if (it.MsgType == 9) {
									if (typeof it.MsgContent === 'string') {
										it.MsgContent = JSON.parse(it.MsgContent)
									}
								}
							})
							// console.log('rlist', rlist)
						}
						this.query.isload = false
					}
				} else {
					//审批消息
					this.getdatas(this.query.isnext)
				}
				if (this.messageDetail.type == 0 || this.messageDetail.type == 1 || this.messageDetail.type == 4) {
					rlist.sort((a, b) => a.MsgId - b.MsgId);
					this.messages = this.messages.concat(rlist)
				} else if (this.messageDetail.type == 3) {
					rlist.map(it => {
						it.MsgContent = JSON.parse(it.MsgContent)
					})
					rlist.sort((a, b) => a.MsgId - b.MsgId);
					this.noticeList = this.noticeList.concat(rlist)

				} else if (this.messageDetail.type == 5) {
					rlist.sort((a, b) => a.MsgId - b.MsgId);
					this.robotList = this.robotList.concat(rlist)
				}
			},
			//处理签收信息
			getmsg10(msg) {
				if (msg.MsgType == 10) {

					if (!Array.isArray(msg.MsgName)) {
						msg.MsgName = JSON.parse(msg.MsgName)
					}
					if (msg.UserId == this.myuser.ID) {
						//发送签收信息用户判断全部用户是否签收完成
						msg.IsCall = (msg.MsgName.findIndex(a => a.state == 0) < 0 ? 1 : 0)
					} else {
						//判断我是否签收
						msg.IsCall = (msg.MsgName.findIndex(a => a.ID == this.myuser.ID && a.state == 1) >= 0 ? 1 : 0)
					}
				}

				return msg
			},

			//处理消息
			handelDetailMsg(msg) {
				if (msg.MsgType <= 10) {
					if (msg.MsgType == 10) {
						var newmsg = this.getmsg10(msg)
						console.log('处理信息10', newmsg)
						if (newmsg.CallUserId == newmsg.MsgId) {
							//更新
							//CallUserId 更新信息ID-签收
							var index = this.messages.findIndex(a => a.MsgId == newmsg.CallUserId)
							if (index >= 0) {
								this.messages[index].IsCall = newmsg.IsCall
								this.messages[index].MsgName = newmsg.MsgName
							}
						} else {
							this.messages.push(newmsg)
						}

					} else if (msg.MsgType == 9) {
						if (typeof msg.MsgContent === 'string') {
							msg.MsgContent = JSON.parse(msg.MsgContent)
						}
						this.messages.push(msg)
					} else {
						this.messages.push(msg)
					}


				} else if (msg.MsgType == 11) {
					msg.MsgContent = JSON.parse(msg.MsgContent)
					this.noticeList.push(msg)
				} else if (msg.MsgType == 12) {
					this.robotList.push(msg)
				} else if (msg.MsgType > 30) {
					this.messages.push(msg)
				}
			},
			//发送文本消息
			async sendmsg() {
				if (!this.content || this.content == '') {
					this.$message('发送消息不能为空')
					return false;
				}

				if (this.messageDetail.type == 5) {
					var data = {
						MsgContent: this.content
					}
					var rs = await this.$request.post('/notice/robotMsg', data)
					if (rs.code == 200) {
						this.content = ''
					}
				} else {
					var msgType = (this.content.indexOf('http') >= 0 ? 3 : 0)
					var data = {
						MsgType: msgType,
						RoomId: this.messageDetail.RoomId,
						MsgContent: this.content
					}
					var rs = await this.$request.post('/chat/sendMsg', data)
					if (rs.code == 200) {
						this.content = ''
					}
				}
			},
			//发送图片/视频/文件/语音信息
			async sendmsgbyUpload(item) {

				var ftype = this.getFileTypeByMime(item.file.type)
				console.log('文件类型', ftype)
				if (ftype != 'image' && ftype != 'video' && ftype != 'audio' &&
					ftype != 'document' && ftype != 'spreadsheet' && ftype != 'archive') {
					this.$message(`不能上传${item.file.type}类型文件`)
					return false
				}


				const formData = new FormData()
				var roomId = this.messageDetail.RoomId
				if (roomId && roomId > 0) formData.append('roomId', roomId)
				var fileno = new Date().getTime();
				if (fileno && fileno != '') formData.append('uploadfileNo', fileno)
				var groupno = this.messageDetail.roomcode
				if (groupno && groupno != '') formData.append('groupNo', groupno)
				formData.append('uploadfile', item.file)
				var res = await this.$request.post('/upload/UploadFile', formData)
				console.log(res)
				if (res.code == 200) {

					var sizestr = ''
					var size = item.file.size / 1024
					if (size > 1024) {
						size = size / 1024
						sizestr = `${size.toFixed(2)}MB`
					} else {
						sizestr = `${size.toFixed(0)}KB`
					}

					var msgName = item.file.name //文件名称
					var suffix = this.getFileExtension(msgName) //文件后缀
					var content = {
						suffix: suffix, //后缀
						url: res.data, //线上地址
						size: sizestr,
						isxiazai: false, //是否已下载
						localurl: '', //本地地址
						mimetype: item.file.type, //文件mime类型
						filetype: ftype //文件类型
					}
					var msgdata = JSON.stringify(content)
					//判断文件类型  1-语音 2-视频 3-链接 4-文件 5-图片 6-云文档
					var msgType = (ftype == 'image' ? 5 : ftype == 'video' ? 2 : ftype == 'audio' ? 1 : 4)
					var data = {
						MsgType: msgType,
						RoomId: this.messageDetail.RoomId,
						MsgName: msgName,
						MsgContent: msgdata
					}
					var rs = await this.$request.post('/chat/sendMsg', data)
					if (rs.code == 200) {
						this.content = ''
					}



				}
			},

			//发送签收成功消息
			async sendsignmsg(msg) {
				console.log('sendsignmsg', msg)
				if (msg.IsCall < 1 && msg.UserId != this.myuser.ID) {
					var data = {
						MsgId: msg.MsgId
					}
					var rs = await this.$request.post('/chat/sendsignMsg', data)
					if (rs.code == 200) {
						this.$message('签收成功')
					}
				}
			},
			//审核消息
			async getdatas(isnext) {
				if (!this.query.isload) {
					this.query.isload = true
					if (isnext) this.query.page += 1
					var data = {
						page: this.query.page,
						limit: 20,
						state: 0,
					}
					var res = await this.$request.post('/notice/auditList', data)
					this.query.isload = false
					if (res.code == 200) {
						this.auditList = this.auditList.concat(res.data.items)
						this.query.isnext = this.auditList.length <= res.data.total
					}
				}
			},
			// 打开审批详情
			openAudit(index) {
				this.auditShow = !this.auditShow
				this.auditIndex = index
				this.$overlay.show();
			},
			//关闭审批详情
			auditSubmit() {
				this.auditShow = false
				this.$overlay.hide();
			},
			//获取群组成员
			async getusers(roomid) {
				this.ulist = []
				var data = {
					RoomId: roomid
				}
				var rs = await this.$request.post('/chat/roomUsers', data)
				if (rs.code == 200) {
					this.ulist = rs.data
				}
			},
		}
	}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame-message {
		// margin-top: 31px;
		// margin-left: 12px;
		width: 726px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.title-frame {
			padding: 23px 22px;
			border-bottom: 1px solid #C9C9C9;

			.avatar {
				width: 52px;
				height: 52px;
				overflow: hidden;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				flex-wrap: wrap-reverse;
				border: 2px solid #36A8F4;
				border-radius: 50%;
				margin-left: 12px;
				// border: #000 1px solid;
				// margin: 30px;


				.avatarImg {
					width: 52px;
					height: 52px;
				}

				.avatarItem--1 {
					width: 98%;
					height: 98%;
				}

				.avatarItem--2 {
					width: 47%;
					height: 47%;
					margin: 1%;
				}

				.avatarItem--3 {
					width: 32%;
					height: 30%;
					margin: 1%;
				}
			}

			.title-content {
				margin-left: 12px;
				flex: 1;
			}


			.title-num {
				margin-top: 2px;
				margin-left: 2px;
				font-weight: 400;
				font-size: 13px;
				color: #858A99;
			}

			.title-item {
				margin-right: 11px;
				background: #E9E9E9;
				border-radius: 9px 9px 9px 9px;
				padding: 3px 9px;
			}

			.title-item-active {
				background: #366EF4;
			}

			.title-text-item {
				margin-left: 5px;
				font-weight: 400;
				font-size: 12px;
				color: #535D6D;
			}

			.title-text-item-active {
				color: #FFFFFF;
			}
		}


		.imgIcon-avatar {
			width: 52px;
			height: 52px;
			border-radius: 50%;
			margin-left: 12px;
		}

		.content-frame {

			// height: 612px;
			-webkit-overflow-scrolling: touch;
			overflow-scrolling: touch;
			overflow-y: auto;
			overflow-x: hidden;

			// background: #000;
			.chat-message-other {
				/*background-color: red;*/
				display: flex;
				padding-left: 22px;
				padding-top: 1rem;
			}

			.chat-message-me {
				/*background-color: red;*/
				display: flex;
				padding-right: 22px;
				padding-top: 1rem;
				flex-direction: row-reverse;
				/* 将子div的顺序反转 */

			}

			.message-me-asWhole-headPortrait {
				padding: 3px;
			}

			.message-other-asWhole-headPortrait {
				padding: 3px;
			}

			.message-me-asWhole-right {
				display: flex;
				flex-direction: column;
				/* 设置子元素垂直排列 */
				margin-left: 0.1rem;
			}

			.message-other-asWhole-right {
				display: flex;
				flex-direction: column;
				/* 设置子元素垂直排列 */
				margin-left: 0.1rem;
			}

			.message-me-asWhole-top {
				padding: 3px;
				font-weight: 400;
				font-size: 15px;
				color: #6E6E6E;
				padding: 3px;
				color: rgba(134, 144, 156, 1);
				text-align: right;
			}

			.message-other-asWhole-top {
				padding: 3px;
				font-weight: 400;
				font-size: 15px;
				color: #6E6E6E;
				padding: 3px;
				color: rgba(134, 144, 156, 1);
			}

			.message-me {
				margin-top: 10px;
				// background-color: rgba(242, 243, 245, 1);
				background: #F2F2F2;
				max-width: 280px;
				word-wrap: break-word;
				/* 处理英文单词换行 */
				word-break: break-all;
				/* 处理中文换行 */
				display: inline-block;
				/*将div元素转换为行内块元素*/
				width: auto;
				/* 宽度根据文本宽度自动调正*/
				padding: 13px 15px;
				border-radius: 4px;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
				border-radius: 8px;
			}

			.message-other {
				margin-top: 10px;
				// background-color: rgba(242, 243, 245, 1);
				background: #F2F2F2;
				max-width: 280px;
				word-wrap: break-word;
				/* 处理英文单词换行 */
				word-break: break-all;
				/* 处理中文换行 */
				display: inline-block;
				/*将div元素转换为行内块元素*/
				width: auto;
				/* 宽度根据文本宽度自动调正*/
				padding: 13px 15px;
				border-radius: 4px;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
				border-radius: 8px;
			}


			.examineeFace_logo_style {
				width: 52px;
				height: 52px;
				border-radius: 50%;
			}

			.chat-time {
				width: 100%;
				margin-top: 24px;
				font-weight: 400;
				font-size: 13px;
				color: #858585;
			}
		}

		.frame-notice {
			// position: fixed;
			width: 690px;
			height: 45px;
			background: #F2F2F2;
			border-radius: 15px 15px 15px 15px;
			margin-top: 13px;
			margin-left: 18px;
		}

		.notice-content {
			margin-left: 16px;
			font-size: 14px;
			color: #070707;
		}

		.frame-notice-title {
			width: 100%;
			margin-top: 23px;
		}

		.notice-title {
			font-size: 20px;
			color: #000000;
		}

		.notice-title-tips {
			margin-top: 14px;
			font-weight: 400;
			font-size: 14px;
			color: #858585;
		}

		.notcie-title-button {
			width: 136px;
			height: 42px;
			background: #FFFFFF;
			border-radius: 16px 16px 16px 16px;
			border: 1px solid #929292;
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}

		.tips-name {
			font-weight: 400;
			font-size: 16px;
			color: #0052D9;
			// width: 100%;
		}

		.meesage-img {
			max-width: 240px;
			max-height: 240px;
			border-radius: 4px;
		}

		.message-read {
			font-weight: 400;
			font-size: 15px;
			color: #6E6E6E;
		}

		.message-read-active {
			color: #36A8F4;
		}

		.frame-input {
			padding: 17px 22px;

			// position: absolute;
			// margin-bottom: 0px;
			.frame-border {
				width: 686px;
				height: 45px;
				border-radius: 16px 16px 16px 16px;
				border: 1px solid #707070;
			}

			.el-input__inner {
				width: 555px;
				height: 33px;
				background: #F6F6F6;
				border: 0px solid #DCDFE6;
			}
		}

		.meesage-file-text {
			font-weight: 400;
			color: #333333;
			font-size: 14px;
			width: 154px;
			display: -webkit-box;
			/* 需要使用 -webkit- 前缀 */
			-webkit-box-orient: vertical;
			/* 垂直布局 */
			overflow: hidden;
			/* 隐藏超出的内容 */
			text-overflow: ellipsis;
			/* 省略号 */
			-webkit-line-clamp: 2;
			/* 显示的行数 */
		}

		.meesage-file-text1 {
			margin-top: 5px;
			font-weight: 400;
			font-size: 13px;
			color: #6E6E6E;
		}

		.message-signButton {
			margin-top: 10px;
			width: 90px;
			height: 25px;
			border-radius: 8px 8px 8px 8px;
			border: 2px solid #FF3400;
			color: #FF3400;
			font-weight: 400;
			font-size: 14px;
		}

		.message-signButton-receive {
			border: 2px solid #858A99;
			color: #858A99;
		}

		.meetingButton {
			margin-top: 15px;
			width: 100%;
			height: 30px;
			font-size: 16px;
			color: #0052D9;
			font-weight: 400;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
		}

		.meetingButton-end {
			border: 1px solid #858A99;
			color: #858A99;
		}

		.audit-content {
			margin-top: 10px;
			width: 414px;
			// height: 62px;
			background: #E2E2E2;
			border-radius: 0px 0px 0px 0px;
			padding: 11px 0px;
		}

		.aduitButton {
			margin-top: 23px;
			width: 100%;
			height: 44px;
			background: #0052D9;
			border-radius: 16px 16px 16px 16px;
			font-size: 16px;
			color: #FFFFFF;
		}

		.frame-video-block {
			margin-top: 8px;
			margin-left: -50px;
			position: absolute;
			width: 136px;
			height: 106px;
			background: #FFFFFF;
			box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.16);
			border-radius: 16px 16px 16px 16px;
			z-index: 101;
		}

		.info-frame {
			position: absolute;
			width: 424px;
			height: 508px;
			background: #FFFFFF;
			box-shadow: 0px 3px 19px 1px rgba(0, 0, 0, 0.2);
			border-radius: 24px 24px 24px 24px;
			z-index: 101;
			// background: #FFFFFF;
			background-image: url('../../assets/img/messageRight/23c0b764fde8a302d22f7b078586239.png');
			/* 替换为实际的图片路径 */
			background-size: cover;
			/* 确保背景图像覆盖整个容器 */
			background-position: center;
			/* 将背景图像居中对齐 */
			background-repeat: no-repeat;
			/* 避免背景图像重复 */
		}

		.info-frame-edit {
			position: absolute;
			z-index: 101;
			width: 257px;
			height: 508px;
			background: #FFFFFF;
			box-shadow: 0px 3px 19px 1px rgba(0, 0, 0, 0.2);
			border-radius: 24px 24px 24px 24px;
			margin-left: 436px;
		}

		.info-frame-item {
			margin-left: 9px;
			width: 124px;
			// width: 33%;
			height: 68px;
			background: #F5F6F8;
			border-radius: 16px 16px 16px 16px;
		}

		.info-frame-buttom {
			margin-top: 32px;
			margin-left: 18px;
			width: 388px;
			height: 113px;
			background: #F5F6F8;
			border-radius: 24px 24px 24px 24px;
		}

		.info-frame-edit-text {
			margin-top: 27px;
			margin-left: 16px;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
		}

		.info-frame-edit-button {
			width: 106px;
			height: 41px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 14px;
			color: #0052D9;
		}
	}

	.message-line {
		width: 437px;
		height: 0px;
		border: 1px solid #707070;
		opacity: 0.24;
	}

	::-webkit-scrollbar {
		width: 6px;
		/*对垂直流动条有效*/
		height: 6px;
		/*对水平流动条有效*/
	}

	/deep/.memberInput .el-input__inner {
		width: 225px;
		height: 37px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #A8A8A8;
	}

	/deep/.textareaMark .el-textarea__inner {
		background: #FFFFFF;
		// min-height: 114px;
		min-height: 255px;
		width: 225px;
		border-radius: 8px;
	}
</style>
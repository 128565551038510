<template>
	<div class="hello">
		<div class="frame">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-bottom: 10px;">
				<template v-for="(item,index) in titleList">
					<div class="flexcumal" :key="index" :style="{'margin-left':index ==1? '39px':'0px'}"
						@click="titleIndexChoose(index)">
						<div class="people-title">{{item}}</div>
						<div class="frame-line" v-if="index == titleIndex"></div>
						<div class="frame-line" v-else style="background: #fff;"></div>
					</div>
				</template>
				<div style="flex: 1;"></div>
				<div style="margin-right: 12px;" v-if="titleIndex">
					<img :src="require('@/assets/img/log/b5716c534c74208bdb4a8ce823a3d05.png')"
						style="width: 21px;height: 21px;z-index: 100;" @click="openPicker" />
					<el-date-picker style="z-index: -1;" @change="getloglist(value1)" v-model="value1"
						value-format="yyyy-MM-dd" type="date" ref="datePicker"></el-date-picker>
					<div class="frame-line" style="background: #fff;"></div>
				</div>
			</div>
			<template v-for="(item,index) in tab">
				<div class="frame-item flexal" :class="index == tabIndex? 'frame-item-active': ''" :key="index"
					@click="submit(index)" v-if="titleIndex == 0">
					<!-- <img :src="index == tabIndex ? item.chooseIcon:item.icon"   style="width: 16px;height: 16px;margin-left: 12px;margin-right: 6px;"  /> -->
					<div class="frame-tab-line" v-if="index == 0"></div>
					<div class="frame-tab-line" style="background: #FF9800;" v-if="index == 1"></div>
					<div class="frame-tab-line" style="background: #1591F0;" v-if="index == 2"></div>
					<div class="frame-tab-line" style="background: #7832E2;" v-if="index == 3"></div>
					<div class="frame-item-text" style="margin-left: 19px;">{{item.text}}</div>
				</div>
			</template>

			<div class="flexal" v-if="titleIndex == 1" style="padding-top: 6px;">
				<template v-for="(item,index) in twoList">
					<div class="frame-two-tab flexcen" :key="index"
						:class="index == twoIndex? 'frame-two-tab-active':''" @click="twoIndex = index;getloglist()">
						{{item}}
					</div>
				</template>
			</div>

			<div style="padding: 0px 12px;height: 760px;overflow: auto;" v-if="titleIndex == 1">

				<div style="margin-top: 22px;" v-for="(item,index) in list " :key="index"
					@click="handel_logcheck(item)">
					<div class="flexal">
						<img :src="item.user.Avatar" style="width: 30px;height: 30px;border-radius: 50%;" />
						<div style="margin-left: 11px;flex: 1;">
							<div style="font-weight: 500;font-size: 11px;">{{item.user.UserName}}的工作日志</div>
							<div style="font-weight: 400;font-size: 8px;color: #878787;margin-top: 1px;">
								{{item.CreateTime}}
							</div>
						</div>
						<img :src="require('@/assets/img/right.png')" style="width: 8px;height: 13px;" />
					</div>
					<div class="frame-content-text" style="margin-top: 11px;">
						任务名称：<span>{{item.Content}}</span>
					</div>
					<div class="frame-content-text">
						问题描述：<span>{{item.Content1}}</span>
					</div>
					<div class="frame-content-text" v-if="item.Content2&&item.Content2!=''">
						解决方案：<span>{{item.Content2}}</span>
					</div>
					<div class="frame-content-text" v-if="item.Content3&&item.Content3!=''">
						心得体会：<span>{{item.Content3}}</span>
					</div>
					<div class="frame-line-content"></div>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'workNotcie',
		props: {
			msg: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				input: '',
				value1: '',
				titleList: ['工作日志', '查看日志'],
				titleIndex: 0,
				avatar: 'https://cdn.uviewui.com/uview/album/1.jpg',
				tab: [{
						icon: require('@/assets/img/workbench/stay.png'),
						chooseIcon: require('@/assets/img/workbench/stayIcon.png'),
						text: '日报'
					},
					{
						icon: require('@/assets/img/workbench/end.png'),
						chooseIcon: require('@/assets/img/workbench/endIcon.png'),
						text: '周报'
					},
					{
						icon: require('@/assets/img/workbench/start.png'),
						chooseIcon: require('@/assets/img/workbench/startIcon.png'),
						text: '月报'
					},
					{
						icon: require('@/assets/img/workbench/start.png'),
						chooseIcon: require('@/assets/img/workbench/startIcon.png'),
						text: '自由汇报'
					},
				],
				tabIndex: 0,
				twoList: ['我收到的', '我发出的'],
				twoIndex: 0,
				list: [{
					user: {
						UserName: '王小二',
						Avatar: 'https://cdn.uviewui.com/uview/album/1.jpg',
					},
					Content: '内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字内容文字',
					Content1: '内容文字内容文字内容文字内容文字内容文字',
					Content2: '内容文字内容文字内容文字内容文字内容文字内容文',
					Content3: '内容文字内容文字内容文字内容文字'
				}],
			}
		},
		methods: {
			submit(index) {
				this.tabIndex = index
				this.$emit('logTabSubmit', index)
			},
			async titleIndexChoose(index) {
				this.titleIndex = index
				if (index == 1) {
					this.getloglist()
				} else {
					this.$emit('titleIndex', this.titleIndex, 0)
				}

			},
			openPicker() {
				this.$refs.datePicker.pickerVisible = true
			},
			async getloglist(date = '') {
				//获取日志列表
				var data = {
					page: 1,
					limit: 999,
					date: date
				}
				//issueList-我发出的
				//receiveList-我收到的
				var url = '/work/receiveList'
				if (this.twoIndex > 0) url = '/work/issueList'
				var res = await this.$request.post(url, data)

				if (res.code == 200) {
					this.list = res.data.items
					if (this.list.length > 0) {
						var vdata = this.list[0]
						this.$emit('titleIndex', this.titleIndex, vdata.WorkLogId)
					} else {
						this.$emit('titleIndex', this.titleIndex, 0)
					}
				}

			},
			handel_logcheck(vdata) {
				this.$emit('titleIndex', this.titleIndex, vdata.WorkLogId)
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		width: 313px;
		// height: 851px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;

		.people-title {
			margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #777777;
		}


		.left-item {
			margin-left: 12px;
			margin-top: 5px;
			display: flex;
			width: 185px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

			.item-bubble {
				width: 15px;
				height: 15px;
				background: #F65160;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				color: #FFFFFF;
				font-weight: 400;
			}

		}

		.frame-item {
			margin-top: 12px;
			margin-left: 12px;
			width: 290px;
			height: 63px;
			background: #FFFFFF;
			color: #272B34;
			border-radius: 8px 8px 8px 8px;
			box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.16);
		}

		.frame-item-active {
			// background: #EFF2FF;
			// color: #0052D9;
		}

		.frame-item-text {
			font-size: 15px;

			font-weight: 400;
		}

		.frame-line {
			width: 26px;
			height: 3px;
			background: #0052D9;
			border-radius: 3px 3px 3px 3px;
		}

		.frame-tab-line {
			width: 5px;
			height: 63px;
			background: #4CAF50;
			border-radius: 8px 0px 0px 8px;
		}

		.frame-two-tab {
			margin-left: 12px;
			width: 74px;
			height: 23px;
			background: #F5F5F5;
			border-radius: 7px 7px 7px 7px;
			font-weight: 400;
			font-size: 14px;
			color: #717171;
		}

		.frame-two-tab-active {
			background: #E6F0FF;
			color: #0052D9;
		}
	}

	.frame-content-text {
		// margin-top: 5px;
		font-weight: 400;
		font-size: 10px;
		color: #333333;
		line-height: 19px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		/* 显示的行数 */
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.frame-line-content {
		margin-top: 20px;
		width: 288px;
		height: 0px;
		border: 1px solid #707070;
		opacity: 0.29;
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}

	/deep/ .el-date-editor.el-input {
		margin-left: -50px;
		width: 21px;
		height: 21px;
		// display: none;
	}

	/deep/ .el-input__inner {
		// display: none;
		border: 0px solid #DCDFE6;
	}
</style>
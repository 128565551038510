<template>
	<div class="hello">
		<div class="frame">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 16px;margin-bottom: 10px;">
				<!-- 组织内联系人 -->
				<div class="people-title" v-if="type != 1 && type !=4">{{title}}</div>
				<!-- 我的群组 -->
				<div class="flexal" style="width: 100%;" v-if="type == 1">
					<div class="people-title">{{title}}</div>
					<div class="create-frame flexcen"
						@click="dialogVisibleMember = !dialogVisibleMember;getUsersByDepId(0)">创建群组</div>
				</div>
				<div class="flexal" v-if="type == 4">
					<div class="people-title" style="font-size: 16px;" @click="type = 3;title='组织架构'">{{companyname}}
					</div>
					<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;margin-left: 17px;" />
					<div class="people-title" style="font-size: 16px;">{{title}}</div>
				</div>
			</div>

			<div class="info-frame" v-if="openInfoFrame">
				<img :src="openInfo.Avatar"
					style="width: 77px;height: 78px;border-radius: 50%;margin-top: 127px;margin-left: 21px;" />
				<div class="flexal">
					<div style="font-weight: 500;font-size:20px;color: #333333;margin-left: 40px;">{{openInfo.uname}}
					</div>
					<img :src="require('@/assets/img/message/start.png')" v-if="openInfo.IsStar>0"
						style="width: 16px;height: 16px;margin-left: 7px;" />
				</div>
				<div class="flexal" style="padding-left: 9px;margin-top: 23px;">
					<div class="info-frame-item flexcumal">
						<img :src="require('@/assets/img/messageRight/inof-message.png')"
							style="width: 28px;height: 28px;margin-top: 10px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;">消息</div>
					</div>
					<div class="info-frame-item flexcumal">
						<img :src="require('@/assets/img/messageRight/info-audio.png')"
							style="width: 28px;height: 28px;margin-top: 10px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;">语音</div>
					</div>
					<div class="info-frame-item flexcumal">
						<img :src="require('@/assets/img/messageRight/info-video.png')"
							style="width: 28px;height: 28px;margin-top: 10px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;">视频</div>
					</div>
				</div>
				<div class="info-frame-buttom">
					<div class="flexal" style="height: 50%;" @click="openInfoFrameEdit = !openInfoFrameEdit">
						<div style="flex:1;font-weight: 400;font-size: 16px;color: #000000;margin-left: 19px;">备注与描述
						</div>
						<div style="font-weight: 400;font-size: 14px;color: #6E6E6E;">编辑内容</div>
						<img :src="require('@/assets/img/right.png')"
							style="width: 8px;height: 14px;margin-right: 18px;margin-left: 12px;" />
					</div>
					<div style="width: 352px;height: 0px;border: 1px solid #707070;opacity: 0.17;margin-left: 18px;">
					</div>
					<div class="flexal" style="height: 50%;">
						<div style="flex:1;font-weight: 400;font-size: 16px;color: #000000;margin-left: 19px;">设为星标联系人
						</div>
						<el-switch v-model="setStart" active-color="#0052D9" inactive-color="#DCDFE6"
							@change="startChange" style="margin-right: 18px;"></el-switch>
					</div>
				</div>
			</div>
			<div class="info-frame-edit" v-if="openInfoFrameEdit">
				<div class="info-frame-edit-text">备注与描述</div>
				<el-input v-model="openInfo.RoomName" class="memberInput" style="margin-left: 16px;margin-top: 16px;"
					placeholder="请输入备注"></el-input>
				<div class="info-frame-edit-text" style="margin-top: 18px;">描述</div>
				<el-input type="textarea" class="textareaMark" :autosize="{ minRows: 12, maxRows: 12}" placeholder="请输入"
					style="margin:16px" v-model="openInfo.RoomIntro"></el-input>
				<div class="flexal" style="margin-top: 10px">
					<div class="info-frame-edit-button flexcen" style="margin-left: 18px;"
						@click="openInfoFrameEdit = !openInfoFrameEdit">取消</div>
					<div class="info-frame-edit-button flexcen"
						style="background: #0052D9;color: #FFFFFF;margin-left: 12px;"
						@click="editRemarks()">保存</div>
				</div>
			</div>

			<!-- 组织内联系人 -->
			<div style="width: 100%;height: 700px;overflow:auto" v-infinite-scroll="scrollLoad" v-if="type == 0">
				<div class="flexal" style="margin-top: 14px;margin-left: 16px;" v-for="(item,index) in ulist"
					@click="infoClick(item.ID)"  :key="index">
					<img :src="item.Avatar" class="imgIcon-avatar" />
					<div class="frame-text" style="margin-left: 10px;">{{item.UserName}}</div>
					<img :src="require('@/assets/img/message/start.png')" v-if="item.checked"
						style="width: 17px;height: 16px;margin-left: 7px;margin-top: -5px;" />
					<div class="tips-frame flexcen" v-if="item.PositionID>0">{{item.posiName}}</div>
				</div>
			</div>

			<!-- 群组列表 -->
			<div class="flexal" style="margin-top: 20px;" v-if="type == 1">
				<div class="flexcumal" :style="{'margin-left':index == 0 ? '17px':'110px'}" v-for="(item,index) in tab"
					:key="index" @click="tabIndex = index;loadgroupdata()">
					<div class="tab-text" :class="index == tabIndex? 'tab-text-active':''">{{item}}</div>
					<div class="frame-tab-line" v-if="index == tabIndex"></div>
					<div class="frame-tab-line" style="background: #fff;" v-else></div>
				</div>
			</div>
			<div style="width: 100%;height: 660px;overflow:auto;" v-infinite-scroll="scrollLoad" v-if="type == 1">
				<div class="flexal" style="margin-top: 22px;margin-left: 16px;" v-for="(item,index) in grouplist"
					:key="index">
					<img :src="item.Avatar" class="imgIcon-avatar" style="width: 34px;height: 34px;" />
					<div class="frame-text" style="margin-left: 10px;">{{item.Name}}（{{item.usertotal}}）</div>
					<!-- <img :src="require('@/assets/img/message/start.png')" v-if="item.checked" style="width: 17px;height: 16px;margin-left: 7px;margin-top: -5px;"  /> -->
					<!-- <div class="tips-frame flexcen" v-if="item.super">超级管理员</div> -->
				</div>
			</div>

			<!-- 星标联系人 -->
			<div style="width: 100%;height: 700px;overflow:auto" v-infinite-scroll="scrollLoad" v-if="type == 2">
				<div class="flexal" style="margin-top: 14px;margin-left: 16px;" v-for="(item,index) in ulist"
				@click="infoClick(item.ID)" 	:key="index">
					<img :src="item.Avatar" class="imgIcon-avatar" />
					<div class="frame-text" style="margin-left: 10px;">{{item.UserName}}</div>
					<!-- <img :src="require('@/assets/img/message/start.png')" v-if="item.checked" style="width: 17px;height: 16px;margin-left: 7px;margin-top: -5px;"  /> -->
					<div class="tips-frame flexcen" v-if="item.PositionID>0">{{item.posiName}}</div>
					<div style="flex: 1;"></div>
					<!--<div class="frame-new-button flexcen" :class="item.checked? '':'frame-new-button-active'">
						 {{item.checked?'已验证':'通过验证'}} 
					</div>-->
				</div>
			</div>

			<!-- 组织架构 -->
			<div style="width: 100%;height: 700px;overflow:auto" v-infinite-scroll="scrollLoad" v-if="type == 3">
				<template v-for="(item,index) in organList">
					<div :key="index">
						<div class="flexal" style="margin-top: 14px;margin-left: 16px;"
							@click="openList(index,item.Child)">
							<img :src="require('@/assets/img/book/c665e08c212174c6a538225cf2c4637.png')"
								class="imgIcon-avatar" />
							<div class="frame-text" style="margin-left: 10px;flex: 1;">
								{{item.DepartmentName+"（"+item.LayerCount+"）"}}
							</div>
							<img :src="require('@/assets/img/right.png')"
								style="width: 7px; height: 12px;margin-right: 17px;" />
						</div>
						<div v-if="item.Child&&item.Child.length>0">
							<template v-for="(items,indexs) in item.Child">
								<div class="flexal" style="margin-left: 45px;" :key="indexs"
									@click="clickChild(indexs,items)">
									<img :src="require('@/assets/img/book/L.png')"
										style="width: 7px;height: 8px;margin-left: 26px;" />
									<div class="frame-text-block flexcen"
										:class="childIndex == indexs ? 'frame-text-block-active':''">
										{{items.DepartmentName +"（"+items.LayerCount+"）"}}
									</div>
								</div>
								<div v-if="items.Child&&items.Child.length>0">
									<div class="flexal" style="margin-left: 65px;" v-for="(it2,index2) in items.Child"
										:key="index2" @click="clickChild(index2,it2)">
										<img :src="require('@/assets/img/book/L.png')"
											style="width: 7px;height: 8px;margin-left: 26px;" />
										<div class="frame-text-block flexcen"
											:class="childIndex == index2 ? 'frame-text-block-active':''">
											{{it2.DepartmentName +"（"+it2.LayerCount+"）"}}
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</template>
			</div>

			<!-- 组织人员 -->
			<div style="width: 100%;height: 700px;overflow:auto" v-infinite-scroll="scrollLoad" v-if="type == 4">
				<div class="flexal" style="margin-top: 14px;margin-left: 16px;" v-for="(item,index) in ulist"
					@click="infoClick(item.ID)" :key="index">
					<img :src="item.Avatar" class="imgIcon-avatar" />
					<div class="frame-text" style="margin-left: 10px;">{{item.UserName}}</div>
				</div>
			</div>
		</div>

		<!-- 弹窗组件集 -->
		<memberPop :dialogVisibleShow="dialogVisibleMember" @updateDialog="updateDialogClick" :list="ulist" :type="1"
			:title="'创建群组'"></memberPop>



	</div>
</template>

<script>
	import memberPop from '@/components/pop-up/memberPop.vue'

	export default {
		name: 'bookList',
		props: {
			// 数据类型0:组织内联系人 1:我的群组  2:星标联系人 3:组织架构  4:部门页面
			type: {
				type: Number,
				default: 0
			},
			depid: {
				type: Number,
				default: 0
			},
			title: {
				type: String,
				default: '组织内联系人'
			}
		},

		watch: {
			// 监听type的变化
			type(newVal, oldVal) {
				console.log(`type从${oldVal}变化到${newVal}`);
				// 在这里可以根据newVal执行相应的逻辑
				if (newVal == 1) {
					this.loadgroupdata()
				} else if (newVal == 2) {
					this.loadstardatas()
				} else if (newVal == 3) {
					this.loadDepTree()
				} else if (newVal == 4) {
					this.getUsersByDepId(this.depid)
				}
			},
		},
		data() {
			return {
				input: '',
				companyname: '鼎智',
				avatar: 'https://cdn.uviewui.com/uview/album/7.jpg',
				tab: ['我创建的群组', '我加入的群组'],
				tabIndex: 0,
				childIndex: null,
				//群组列表
				grouplist: [],
				setStart: false, //设置星标联系人
				// 组织成员
				ulist: [],
				organList: [],
				organIndex: null,
				openInfo: {}, //好友消息
			}
		},
		components: {
			memberPop,
		},
		created() {
			document.addEventListener("click", this.myFunction);
		},
		methods: {
			submit(index) {
				this.tabIndex = index
				this.$emit('bookListSubmit', index)
			},
			scrollLoad() {
				console.log('滑底触发')
			},
			// 弹窗关闭回调
			updateDialogClick() {
				// console.log('我被调用了')
				this.dialogVisibleMember = false
				if (this.type == 1) {
					this.loadgroupdata()
				}

			},
			openList(index, list) {
				if (list.length > 0) {
					this.organList[index].show = !this.organList[index].show
				} else {
					this.type = 4
				}
				this.organIndex = index
			},
			clickChild(index, row) {
				this.childIndex = index
				this.type = 4
				this.title = row.DepartmentName
				this.depid = row.DepartmentID
				this.getUsersByDepId(this.depid)
			},
			// 头像个人信息弹窗
			async infoClick(uid) {

				var data = {
					UserId: uid
				}
				var res = await this.$request.post('/chat/friendUser', data)
				if (res.code == 200) {
					this.openInfo = res.data
					this.setStart = this.openInfo.IsStar > 0
					this.openInfoFrame = !this.openInfoFrame
					this.$overlay.show();
				}
			},


			// 编辑星标
			async startChange() {
				var isStar = this.setStart ? 1 : 0
				var data = {
					editType: 6,
					Id: this.openInfo.Id,
					IsStar: isStar
				}
				var res = await this.$request.post('/chat/editRoomRole', data)
				if (res.code == 200) {
					this.openInfo.IsStar = isStar
				}

			},
			//编辑备注和描述
			async editRemarks() {
				var data = {
					editType: 4,
					Id: this.openInfo.Id,
					RoomName: this.openInfo.RoomName,
					RoomIntro: this.openInfo.RoomIntro,
				}
				var res = await this.$request.post('/chat/editRoomRole', data)
				if (res.code == 200) {
					this.openInfoFrameEdit = !this.openInfoFrameEdit
				}

			},
			//获取星标好友
			async loadstardatas() {
				this.ulist = []
				var data = {}
				var res = await this.$request({
					url: '/Users/starList',
					method: "POST",
					data: data
				})
				if (res.code == 200) {
					console.log('starList', res.data)
					this.ulist = res.data.items
				}
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		width: 874px;
		// height: 759px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 0px 16px 16px 0px;
		// border-right: 1px solid #707070;

		.people-title {
			margin-left: 16px;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
		}

		.frame-text {
			font-weight: 500;
			font-size: 13px;
			color: #333333;
		}

		.tips-frame {
			margin-left: 10px;
			width: 74px;
			height: 19px;
			background: #EEF3FF;
			border-radius: 4px 4px 4px 4px;
			font-weight: 400;
			font-size: 11px;
			color: #2953E7;
		}

		.frame-new-button {
			width: 79px;
			height: 26px;
			border-radius: 24px 24px 24px 24px;
			border: 1px solid #A2A6B1;
			font-size: 15px;
			color: #858A99;
			margin-right: 25px;
		}

		.frame-new-button-active {
			border: 1px solid #0052D9;
			color: #0052D9;
		}
	}

	.imgIcon {
		width: 30px;
		height: 31px;
	}

	.imgIcon-avatar {
		width: 43px;
		height: 43px;
		border-radius: 50%;
	}

	.frame-line {
		margin-left: 24px;
		margin-top: 28px;
		width: 328px;
		height: 0px;
		border: 1px solid #707070;
		opacity: 0.43;
	}

	.create-frame {
		margin-right: 17px;
		width: 83px;
		height: 27px;
		border-radius: 10px 10px 10px 10px;
		border: 1px solid #0052D9;
		font-size: 15px;
		color: #0052D9;
	}

	.frame-tab-line {
		width: 45px;
		height: 3px;
		background: #0052D9;
		border-radius: 2px 2px 2px 2px;
	}

	.tab-text {
		font-weight: 400;
		font-size: 16px;
		color: #858A99;
	}

	.tab-text-active {
		color: #0052D9;
	}

	.frame-text-block {
		margin-left: 9px;
		width: 93px;
		height: 25px;
		border-radius: 16px 16px 16px 16px;
		font-weight: 400;
		font-size: 12px;
		color: #333333;
	}

	.frame-text-block-active {
		background: #F2F3FF;
		color: #0052D9;
	}

	.info-frame {
		position: absolute;
		width: 424px;
		height: 508px;
		background: #FFFFFF;
		box-shadow: 0px 3px 19px 1px rgba(0, 0, 0, 0.2);
		border-radius: 24px 24px 24px 24px;
		z-index: 101;
		// background: #FFFFFF;
		background-image: url('../../assets/img/messageRight/23c0b764fde8a302d22f7b078586239.png');
		/* 替换为实际的图片路径 */
		background-size: cover;
		/* 确保背景图像覆盖整个容器 */
		background-position: center;
		/* 将背景图像居中对齐 */
		background-repeat: no-repeat;
		/* 避免背景图像重复 */
		margin-left: 100px;
	}

	.info-frame-edit {
		position: absolute;
		z-index: 101;
		width: 257px;
		height: 508px;
		background: #FFFFFF;
		box-shadow: 0px 3px 19px 1px rgba(0, 0, 0, 0.2);
		border-radius: 24px 24px 24px 24px;
		margin-left: 536px;
	}

	.info-frame-item {
		margin-left: 9px;
		width: 124px;
		// width: 33%;
		height: 68px;
		background: #F5F6F8;
		border-radius: 16px 16px 16px 16px;
	}

	.info-frame-buttom {
		margin-top: 32px;
		margin-left: 18px;
		width: 388px;
		height: 113px;
		background: #F5F6F8;
		border-radius: 24px 24px 24px 24px;
	}

	.info-frame-edit-text {
		margin-top: 27px;
		margin-left: 16px;
		font-weight: 400;
		font-size: 14px;
		color: #000000;
	}

	.info-frame-edit-button {
		width: 106px;
		height: 41px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #0052D9;
		font-size: 14px;
		color: #0052D9;
	}

	/deep/.memberInput .el-input__inner {
		width: 225px;
		height: 37px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #A8A8A8;
	}

	/deep/.textareaMark .el-textarea__inner {
		background: #FFFFFF;
		// min-height: 114px;
		min-height: 255px;
		width: 225px;
		border-radius: 8px;
	}
</style>
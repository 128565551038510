export const addressbook = {
	data() {
		return {

		};
	},
	methods: {
		//加载用户信息
		async loadUserData() {
			var udata = localStorage.getItem('udata')
			if (udata) {
				var user = JSON.parse(udata);
				this.avatar = user.Avatar
				this.username = user.UserName
				this.userdepid = user.DepartmentID
				this.list[1] = user.depName
			}
		},
		//获取组织结构
		async loadDepTree() {
			var data = {}
			var res = await this.$request({
				url: '/Users/treeList',
				method: "POST",
				data: data
			})
			if (res.code == 200) {
				console.log('treeList', res.data)
				if (res.data.length > 0) {
					this.companyname = res.data[0].DepartmentName
					this.organList = res.data[0].Child
				}
			}
		},
		//通过部门id获取成员列表
		async getUsersByDepId(depid) {
			var data = {
				page: 1,
				limit: 999,
				queryId: depid
			}
			var res = await this.$request({
				url: '/Users/usersList',
				method: "POST",
				data: data
			})
			if (res.code == 200) {
				console.log('getUsersByDepId', res.data)
				this.ulist = res.data.items
			}

		},
		//获取我的群组信息
		async loadgroupdata() {
			var data = {
				page: 1,
				limit: 999,
				type: this.tabIndex
			}
			var res = await this.$request.post('/chat/groupList', data)
			if (res.code == 200) {
				console.log('loadgroupdata', res.data)
				this.grouplist = res.data.items
			}

		},


	}
};
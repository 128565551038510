import telephone from '../components/pop-up/telephonePop.vue';
 
const TelePhonePlugin = {
  install(Vue) {
    const OverlayConstructor = Vue.extend(telephone);
    const overlayInstance = new OverlayConstructor();
    overlayInstance.$mount(document.createElement('div'));
    document.body.appendChild(overlayInstance.$el);
 
    // 添加实例方法
    Vue.prototype.$overlayPhone = {
      show(type, userlist,isfq) {
        overlayInstance.show(type, userlist,isfq);
      },
      hide() {
        overlayInstance.hide();
      },
    };
  },
};
 
export default TelePhonePlugin;
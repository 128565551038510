<template>
	<div style="width: 100%;float: center;display: flex;justify-content: flex-end;">
		<!-- 编辑群信息 -->
		<div class="right-frame">
			<!-- 编辑群信息 -->
			<div class="right-frame-title flexal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="submit(0)" />
				<div class="right-tips-text" style="font-size: 16px;margin-left: 20px;flex: 1;">签收消息</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;" @click="submit(0)" />
			</div>
			<div style="padding: 10px 22px;">
				<el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6}" placeholder="请输入"
					v-model="input"></el-input>
			</div>
			<div class="choose-frame">
				<div class="flexal">
					<div style="font-weight: 400;font-size: 17px;color: #000000;flex: 1;">接收人</div>
					<div style="font-weight: 400;font-size: 16px;color: #000000;">全选</div>
					<el-checkbox v-model="checked" @change="checkAll"
						style="margin-right: 6px;margin-left: 6px;"></el-checkbox>
				</div>
				<div v-infinite-scroll="scrollLoad" style="overflow:auto;height: 95%;">
					<div class="flexal" style="margin-top: 13px;" v-for="(item,index) in list" :key="index">
						<img :src="item.Avatar" style="width: 40px;height: 40px;border-radius: 50%;" />
						<div style="flex: 1;">{{item.UserName}}</div>
						<el-checkbox v-model="item.checked"></el-checkbox>
					</div>
				</div>
			</div>
			<div class="flexcen">
				<div class="frame-button-send flexcen" @click="submit(1)">发送</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'signIn',
		props: {
			// 联系人列表
			list: {
				type: Array,
				default: []
			},
			roomid: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				input: '',
				checked: false
			}
		},
		watch: {
			list: {
				handler(value) { //这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
					// console.log('数组变化',value)
					let checked = true
					value.forEach(element => {
						if (element.checked == false) {
							// num++
							checked = false
						}
					});
					this.checked = checked
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
		},
		methods: {
			// 发送/关闭，按钮回调  0:关闭  1:发送
			async submit(type) {
				if (type > 0) {
					if (!this.input || this.input == '') {
						this.$message('签收信息不能为空')
						return false
					}

					var msgdata = []
					this.list.map(it => {
						msgdata.push({
							ID: it.ID,
							UserName: it.UserName,
							Avatar: it.Avatar,
							state: 0,
						})
					})
					var rtcdata = JSON.stringify(msgdata);
					var data = {
						MsgType: 10,
						RoomId: this.roomid,
						MsgContent: this.input,
						MsgName: rtcdata,
					}
					var rs = await this.$request.post('/chat/sendMsg', data)
					if (rs.code == 200) {
						var params = {
							type: type,
							content: this.input
						}
						this.$emit('signInSubmit', params)
					}
				} else {
					var params = {
						type: type,
						content: this.input
					}
					this.$emit('signInSubmit', params)
				}


			},
			// 滑底触发
			scrollLoad() {
				console.log('滑底触发')
			},
			// 全选
			checkAll(e) {
				console.log('修改后的值', e)
				var value = this.list
				value.forEach(element => {
					console.log('全选', element)
					element.checked = e
				});
				this.list = value
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.right-frame {
		margin-top: 0px;
		position: absolute;
		width: 457px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		z-index: 100;
		/* Ensure it sits on top of other content */

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.right-frame-title {
			padding: 15px 16px;
		}

		.right-line-bottom {
			margin-left: 16px;
			width: 424px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}

		.right-tips-text {
			font-weight: 400;
			color: #6B6B6B;
			font-size: 14px;
		}

		.el-button {
			// width: 96px;
			// height: 45px;
			padding: 0px 0px;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.3);
			font-size: 24px;
			color: rgba(255, 255, 255, 1);
			border: transparent;
			overflow: hidden;
			// &:hover{
			//   background: #ecf5ff;
			//   color: #fff;
			// }
			// &:focus{
			//   background: #ecf5ff;
			//   color: #fff;
			// }
		}

		.title-qrCode-frame {
			position: absolute;
			margin-left: -300px;
			z-index: 101;
			/* Ensure it sits on top of other content */
			width: 302px;
			height: 412px;
			// background: #FFFFFF;
			background-image: url('../../assets/img/navigation/qrCode-frame.png');
			/* 替换为实际的图片路径 */
			background-size: cover;
			/* 确保背景图像覆盖整个容器 */
			background-position: center;
			/* 将背景图像居中对齐 */
			background-repeat: no-repeat;
			/* 避免背景图像重复 */
			box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.17);
			border-radius: 16px 16px 16px 16px;
		}

		.saveQrCode {
			width: 132px;
			height: 39px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 12px;
			color: #0052D9;
		}

		.shareQrCode {
			margin-left: 10px;
			width: 132px;
			height: 39px;
			background: #0052D9;
			border-radius: 8px 8px 8px 8px;
			color: #FFFFFF;
			font-size: 12px;
		}

		.choose-frame {
			// width: 100%;
			padding: 15px 15px;
			height: 360px;
			// background: #000;
		}
	}

	.frame-button-send {
		width: 122px;
		height: 47px;
		background: #0052D9;
		border-radius: 16px 16px 16px 16px;
		font-size: 16px;
		color: #FFFFFF;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.el-textarea__inner {
		background: #F1F1F1;
		// min-height: 114px;
		border-radius: 16px;
	}
</style>
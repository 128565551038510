import {
	Notification
} from "element-ui";

let count = 0; // 记录计数
let lockReconnect = false; //避免ws重复连接
let ws = null; // 判断当前浏览器是否支持WebSocket
let server = ''; //此处放置请求服务器地址
// 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
window.onbeforeunload = function() {
	if (ws && ws.readyState === WebSocket.OPEN) {
		ws.onclose();
	}
};
// 重连
let reconnect = (server) => {
	if (count >= 5) return console.log("超出重连次数！");
	if (lockReconnect) return false;
	lockReconnect = true;
	setTimeout(function() { //没连接上会一直重连，设置延迟避免请求过多
		createWebSocket(server);
		lockReconnect = false;
		count++;
	}, 5000);
};
let createWebSocket = (server) => {
	return new Promise((resolve, reject) => {
		if (ws && ws.readyState === WebSocket.OPEN) {
			return resolve();
		}
		try {
			if ('WebSocket' in window) {
				ws = new WebSocket(server);
			} else {
				Notification({
					message: "当前浏览器不支持websocket协议",
					type: "success"
				});
				return reject("浏览器不支持 WebSocket 协议");
			}
			initEventHandle();
			resolve();
		} catch (e) {
			console.log("捕获异常", e);
		}
	});
};
// 初始化事件函数
let initEventHandle = () => {
	// 连接报错
	ws.onerror = function(evt, e) {
		console.log("[onerror]");

		reconnect(server)
	};
	// 连接关闭
	ws.onclose = function(evt) {
		console.log("[onclose]");

		console.log("连接关闭---" + new Date().toLocaleTimeString());
		reconnect(server)
	};
	// 链接成功
	ws.onopen = function(evt) {
		console.log("[open]");
		//heartCheck.reset().start();// 心跳检测重置
		count = 0; // 重置重连次数
	};
	// 接受数据
	ws.onmessage = function(evt) { // 如果获取到消息，心跳检测重置
		console.log("内容：", evt)
		//heartCheck.reset().start();// 拿到任何消息都说明当前连接是正常的
		let eventData = undefined;
		try {
			eventData = JSON.parse(evt.data);
			handMsg(eventData)
		} catch (e) {
			console.log("捕获异常: 当前返回的数据不能解析;");
			console.log("内容：" + evt.data)
		}
	};
	console.log("初始化成功");
};
// 心跳检测
const heartCheck = {
	timeout: 25000, // 设置心跳时间 2.5秒
	timeoutObj: null,
	serverTimeoutObj: null,
	reset: function() {
		clearTimeout(this.timeoutObj);
		clearTimeout(this.serverTimeoutObj);
		return this;
	},
	start: function() {
		const self = this;
		this.timeoutObj = setTimeout(function() {
			// 这里发送一个心跳，后端收到后则返回一个心跳消息，onmessage拿到返回的心跳就说明连接正常
			ws.send("心跳");
			self.serverTimeoutObj = setTimeout(function() {
				// 如果超过一定时间还没重置，说明后端主动断开了
				// 如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
				ws.close();
			}, self.timeout)
		}, self.timeout)
	}
};
// 处理消息
let handMsg = (eventData) => {
	// console.log(eventData, '消息')
	if (ws.readyState === WebSocket.OPEN) {
		let WebSocketdata = JSON.parse(eventData)
		//自定义判断传值
		if (WebSocketdata.MsgType != undefined && WebSocketdata.MsgType >= 0 && WebSocketdata.MsgType != 41) {
			_bus.$emit('message', WebSocketdata);
		}

		if (WebSocketdata.RoomType != undefined && WebSocketdata.RoomType == 2) {
			_bus.$emit('meeting_message', WebSocketdata);
		}

		if (WebSocketdata.type != undefined && WebSocketdata.type == 999) {
			_bus.$emit('rtcmessage', WebSocketdata);
		}

		if (WebSocketdata.type != undefined && WebSocketdata.type == 8999) {
			_bus.$emit('meeting_rtcmessage', WebSocketdata);
		}

		if (WebSocketdata.type != undefined && WebSocketdata.type == 6999) {
			if (WebSocketdata.code == 'CallRemote') {
				_bus.$emit('remotemessage', WebSocketdata);
			} else if (WebSocketdata.code == 'AnswerRemote') {
				_bus.$emit('answer_remotmessage', WebSocketdata);
			} else {
				_bus.$emit('remote_rtcmessage', WebSocketdata);
			}

		}

	}
};
let _bus = null;
let startWebSocket = (bus, url = '') => {
	_bus = bus;
	server = url
	return () => {
		createWebSocket(server)
	}
};
export default {
	startWebSocket
}

//示例
// import moudelWebSocket from "@/util/webSocket.js";

// data() {
//     return {
//       $eventBus: null,
//       busvalue: {},
//     }
//   },
//   created() {
//     this.$eventBus = new Vue();
//   },
//   methods: {
//     moudelweb() {
//       //启动连接
//       moudelWebSocket.startWebSocket(this.$eventBus)();

//       //监听数据
//       this.$eventBus.$on("echart", (WebSocketdata) => {
//         this.busvalue = WebSocketdata.entity[0]
//       });
//       this.$eventBus.$on("message", (WebSocketdata) => {
//         this.Suggestiontext = WebSocketdata
//       });
//     }
//   },
import axios from 'axios';
import store from '@/store'
import router from '@/router'
import {
	MessageBox,
	Message
} from 'element-ui'

// 基本配置
const instance = axios.create({
	baseURL: '/api/', // 根据实际情况修改API地址
	timeout: 5000 // 设置超时时间，单位为ms
});

// 请求拦截器
instance.interceptors.request.use(config => {
	// 设置请求头部分，这里举例使用了localStorage存储的token作为身份标识
	var token = localStorage.getItem('userToken');
	if (token) {
		config.headers['Authorization'] = `Bearer ${token}`
	}
	return config;
}, error => {
	console.log(error);
	return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(response => {
	var data = response.data;
	if (response.data.body) data = response.data.body
	// console.log('response-data', data)
	if (data) {
		if (data.code === 501) {
			// to re-login
			Message({
				message: '您已注销,请重新登录',
				type: 'error',
				duration: 5 * 1000
			})
			store.commit('SET_TOKEN', '')
			router.push({
				path: '/login'
			})
		} else if (data.code === 400) {
			Message({
				message: data.msg,
				type: 'error',
				duration: 5 * 1000
			})
		}
		return data;
	}
}, error => {
	console.log(error);
	var edata = {
		code: 400,
		msg: '系统错误'
	}
	return edata; // Promise.reject(error);
});

export default instance;
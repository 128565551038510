<template>
	<div style="width: 100%;float: center;display: flex;justify-content: flex-end;">
		<!-- 编辑群信息 -->
		<div class="right-frame">
			<!-- 编辑群信息 -->
			<div class="right-frame-title flexal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="submit" />
				<div class="right-tips-text" style="font-size: 16px;margin-left: 20px;flex: 1;" v-if="type == 0">
					请求控制对方电脑</div>
				<div class="right-tips-text" style="font-size: 16px;margin-left: 20px;flex: 1;" v-if="type == 1">
					邀请对方远程协助</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;" @click="submit" />
			</div>

			<div class="flexcumal ">
				<img :src="require('@/assets/img/messageRight/c376a2944e3195a91c5e25336b6f540.png')"
					style="width: 204px;height: 138px;margin-top: 145px;" />
				<div style="font-weight: 400;font-size: 14px;color: #333333;margin-top: 54px;" v-if="type == 0">
					你正在请求远程控制{{touser.UserName}}的电脑，请等待对方回应</div>
				<div style="font-weight: 400;font-size: 14px;color: #333333;margin-top: 54px;" v-if="type == 1">
					你正在邀请远程{{touser.UserName}}的电脑协助，请等待对方回应</div>
				<div class="flexcen">
					<div class="flexcumal" >
						<img :src="require('@/assets/img/popUp/tele-stop.png')"
							style="width: 61px;height: 61px;margin-top: 55px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;margin-top: 5px;">取消</div>
					</div>
					<!-- <div class="flexcumal" v-if="type == 1">
						<img :src="require('@/assets/img/messageRight/e240f605b4b3c0c72335c020bffae3c.png')"
							style="width: 61px;height: 61px;margin-top: 55px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;margin-top: 5px;">拒绝</div>
					</div>
					<div class="flexcumal" style="margin-left: 116px;" v-if="type == 1">
						<img :src="require('@/assets/img/messageRight/e531caa835bcc2972dd243354dd0708.png')"
							style="width: 61px;height: 61px;margin-top: 55px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;margin-top: 5px;">接受</div>
					</div> -->
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'longFrame',
		props: {
			// 类型 0:请求控制对方电脑  1:邀请对方控制电脑
			type: {
				type: Number,
				default: 0
			},
			touser: {
				type: Object,
				default: null
			},
		},
		data() {
			return {
				myuser: {}
			}
		},
		watch: {

		},
		created() {
			if (this.$eventBus) {
				this.$eventBus.$on("answer_remotmessage", (wdata) => {
					console.log('answer_remotmessage', wdata)
					if (wdata.code == 'AnswerRemote') {
						this.answerFun(wdata.data)
					}
				});
			}

			var udata = localStorage.getItem('udata')
			if (udata) {
				var user = JSON.parse(udata);
				this.myuser = user
			}
			this.sendRemoteMsg()
		},
		beforeDestroy() {
			//销毁事件
			if (this.$eventBus) {
				this.$eventBus.$off("answer_remotmessage")
			}
		},
		methods: {
			// 发送/关闭，按钮回调  0:关闭  1:接听
			submit() {

				this.$emit('longFrameSubmit', 0)
			},
			//回应的远程请求
			answerFun(isok) {
				if (isok) {
					//远程控制请求
					var type = this.type > 0 ? 0 : 1
					this.$overlayRemote.show(type, this.touser, true)
				}
				this.$emit('longFrameSubmit', 0)
			},
			//发送远程桌面请求
			async sendRemoteMsg() {

				var data = JSON.stringify({
					type: this.type,
					touser: this.myuser
				})

				var rtcdata = JSON.stringify({
					type: 6999,
					code: 'CallRemote',
					userid: this.touser.ID,
					sendid: this.myuser.ID, //发送用户(我)Id
					data: data
				});
				var data = {
					UserId: this.touser.ID,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
			},

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.right-frame {
		margin-top: 0px;
		position: absolute;
		width: 457px;
		height: 670px;
		background: #F8F9FA;
		z-index: 100;
		/* Ensure it sits on top of other content */

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.right-frame-title {
			padding: 15px 16px;
		}

		.right-line-bottom {
			margin-left: 16px;
			width: 424px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}

		.right-tips-text {
			font-weight: 400;
			color: #6B6B6B;
			font-size: 14px;
		}

		.el-button {
			// width: 96px;
			// height: 45px;
			padding: 0px 0px;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.3);
			font-size: 24px;
			color: rgba(255, 255, 255, 1);
			border: transparent;
			overflow: hidden;
			// &:hover{
			//   background: #ecf5ff;
			//   color: #fff;
			// }
			// &:focus{
			//   background: #ecf5ff;
			//   color: #fff;
			// }
		}

		.title-qrCode-frame {
			position: absolute;
			margin-left: -300px;
			z-index: 101;
			/* Ensure it sits on top of other content */
			width: 302px;
			height: 412px;
			// background: #FFFFFF;
			background-image: url('../../assets/img/navigation/qrCode-frame.png');
			/* 替换为实际的图片路径 */
			background-size: cover;
			/* 确保背景图像覆盖整个容器 */
			background-position: center;
			/* 将背景图像居中对齐 */
			background-repeat: no-repeat;
			/* 避免背景图像重复 */
			box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.17);
			border-radius: 16px 16px 16px 16px;
		}

		.saveQrCode {
			width: 132px;
			height: 39px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 12px;
			color: #0052D9;
		}

		.shareQrCode {
			margin-left: 10px;
			width: 132px;
			height: 39px;
			background: #0052D9;
			border-radius: 8px 8px 8px 8px;
			color: #FFFFFF;
			font-size: 12px;
		}

		.choose-frame {
			// width: 100%;
			padding: 15px 15px;
			height: 360px;
			// background: #000;
		}
	}

	.frame-button-send {
		width: 122px;
		height: 47px;
		background: #0052D9;
		border-radius: 16px 16px 16px 16px;
		font-size: 16px;
		color: #FFFFFF;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.el-textarea__inner {
		background: #F1F1F1;
		// min-height: 114px;
		border-radius: 16px;
	}
</style>
import Vue from 'vue'
import moudelWebSocket from "@/util/webSocket.js";

export const mixins = {
	data() {
		return {
			msgs: "我是小猪课堂",
			AddShow: false, //是否显示弹窗操作栏
			openQrcode: false, //打开二维码
			optShow: false, //右侧操作栏是否显示
			dialogVisibleMember: false, //添加弹窗
			dialogVisibleNotice: false, //群公告编辑弹窗
			searchMessageShow: false, //搜索会话内容
			imageExtensions: ['jpg', 'jpeg', 'gif', 'png'], //图片后缀
			videoExtensions: ['mp4', 'wmv', 'avi', 'mov'], //视频后缀
			dialogVisibleVideoShow: false, //视频预览是否显示
			dialogVisibleDelete: false, //删除弹窗是都显示
			auditShow: false, //审批详情
			longFrameShow: false, //远程桌面是否显示
			dialogVisibleBookEdit: false, //通讯录编辑管理弹窗
			visibleJoinConference: false, //加入会议弹窗
			visibleLaunchConference: false, //发起会议弹窗
			visibleStartConference: false, //开始会议弹窗
			visibleSignInConference: false, //签到弹窗
			visibleVoteInConference: false, //投票弹窗
			visiblefileConference: false, //文档弹窗
			openInfoFrame: false, //个人信息弹窗
			openInfoFrameEdit: false, //个人信息修改弹窗
			detailFrameShow: false, //公告详情界面
			verifyShow: false, //进群验证界面
			// $eventBus: null, //stocket事件
		};
	},
	computed: {},
	created() {

		//   console.log("我是mixin中的created生命周期函数");
	},
	mounted() {
		//   console.log("我是mixin中的mounted生命周期函数");
	},
	methods: {
		async moudelweb() {
			// this.$eventBus = new Vue();
			var data = {
				device: 1
			}
			var rs = await this.$request.post('/chat/preConnect', data)
			if (rs.code == 200) {
				var url = rs.data
				//启动连接
				moudelWebSocket.startWebSocket(this.$eventBus, url)();
				var userid = localStorage.getItem('userId')


				this.$eventBus.$on("remotemessage", async (wdata) => {

					var cdata = JSON.parse(wdata.data)
					var _isok = false
					//远程控制请求
					this.$overlayRemote.show(cdata.type, cdata.touser, _isok)

				})

				this.$eventBus.$on("message", async (WebSocketdata) => {
					console.log('主页面-message', WebSocketdata)

					//新增信息记录
					if (WebSocketdata.UserId == userid) {
						WebSocketdata.State = 1
					}
					if (WebSocketdata.MsgType != 10) {
						var isok = await this.msgAdd(WebSocketdata)
						if (isok) {
							if (WebSocketdata.MsgType > 0 && WebSocketdata.MsgType < 7 &&
								WebSocketdata.MsgType != 3) {
								if (typeof WebSocketdata.MsgContent === 'string') {
									WebSocketdata.MsgContent = JSON.parse(WebSocketdata.MsgContent)
								}
							}
							//更新聊天
							var data = localStorage.getItem('chatlist')
							if (data && data != '') {
								var chatlist = JSON.parse(data)
								var index = chatlist.findIndex(a => a.RoomId == WebSocketdata
									.RoomId)
								if (index >= 0) {
									chatlist[index].msg = WebSocketdata
									localStorage.setItem('chatlist', JSON.stringify(chatlist))
									this.detailchange += 1;
								} else {
									this.isinitNum += 1;
								}
							}
						}
					}

					//通话
					if (WebSocketdata.MsgType == 7 || WebSocketdata.MsgType == 8) {
						//判断当前用户是否被呼叫用户
						if (userid) {
							var ulist = Array.isArray(WebSocketdata.MsgContent) ? WebSocketdata
								.MsgContent : JSON.parse(WebSocketdata.MsgContent)
							if (userid != WebSocketdata.UserId && ulist.findIndex(a => a.ID ==
									userid) >= 0) {
								var teltype = (WebSocketdata.MsgType > 7 ? 1 : 0);
								this.$overlayPhone.show(teltype, ulist, 0)
							}
						}
					} else if (WebSocketdata.MsgType == 10) {
						//签收信息
						if (userid && WebSocketdata.CallUserId == 0) {
							var ulist = Array.isArray(WebSocketdata.MsgName) ? WebSocketdata
								.MsgName : JSON.parse(WebSocketdata.MsgName)
							if (userid == WebSocketdata.UserId || ulist.findIndex(a => a.ID ==
									userid) >= 0) {
								var isok = await this.msgAdd(WebSocketdata)
								if (isok) {
									if (WebSocketdata.RoomId == this.messageDetail.RoomId) {
										this.messageDetail.msg = WebSocketdata
									}
								}
							}
						} else {
							var content = Array.isArray(WebSocketdata.MsgName) ? JSON.stringify(
								WebSocketdata.MsgName) : WebSocketdata.MsgName
							var isok = await this.msgUpdName(WebSocketdata.MsgId, content)
							if (isok) {

							}
						}
					}




					//最后统计所有未读条数
					this.tab[0].count = await this.msgloadcount()

				});
				//最后统计所有未读条数
				this.tab[0].count = await this.msgloadcount()
			}
		},
		//获取指定用户消息
		getuserinfo(userid, tag = 0) {
			var txt = ''
			if (userid > 0) {
				var index = this.ulist.findIndex(a => a.ID == userid)
				if (index >= 0) {
					var user = this.ulist[index]

					if (tag == 0) txt = user.UserName
					else if (tag == 1) txt = user.Avatar
				}
			}
			return txt
		},

		//获取用户信息
		getutxt(userid, ulist, col, idcol = 'UserId') {
			let txt = '';
			if (userid > 0 && ulist && ulist.length > 0) {
				var index = ulist.findIndex(a => a[idcol] == userid)
				if (index >= 0) {
					var user = ulist[index]
					txt = user[col]
				}
			}
			return txt
		},
		//计算头像布局
		computedAvatar(avatarList) {
			if (avatarList.length > 4) {
				return "avatarItem--3"
			} else if (avatarList.length > 1) {
				return "avatarItem--2"
			} else {
				return "avatarItem--1"
			}
		},

		// 开发中
		inDevelopment() {
			this.$message({
				message: '开发中，敬请等待',
				type: 'warning'
			});
			return
		},
		// 获取文件名中最后一个点的位置
		getFileExtension(filename) {
			const lastDot = filename.lastIndexOf('.');
			// 如果没有点或者点位于第一个位置，返回空字符串
			if (lastDot === -1 || lastDot === 0) return '';
			// 返回点之后的字符串，即后缀名
			return filename.substring(lastDot + 1).toLowerCase();
		},
		//文件类型
		getFileTypeByMime(mimeType) {

			if (mimeType.startsWith('image/')) {
				return 'image';
			} else if (mimeType.startsWith('video/')) {
				return 'video';
			} else if (mimeType.startsWith('audio/')) {
				return 'audio';
			} else if (mimeType.startsWith('text/')) {
				return 'document';
			} else if (mimeType.startsWith('application/pdf')) {
				return 'document';
			} else if (mimeType.startsWith('application/msword') || mimeType.startsWith(
					'application/vnd.openxmlformats-officedocument.wordprocessingml.')) {
				return 'document';
			} else if (mimeType.startsWith('application/vnd.ms-excel') || mimeType.startsWith(
					'application/vnd.openxmlformats-officedocument.spreadsheetml.')) {
				return 'spreadsheet';
			} else if (mimeType.startsWith('application/vnd.ms-powerpoint') || mimeType.startsWith(
					'application/vnd.openxmlformats-officedocument.presentationml.')) {
				return 'presentation';
			} else if (mimeType.startsWith('application/zip') || mimeType.startsWith(
					'application/x-rar-compressed') ||
				mimeType.startsWith('application/x-zip-compressed')) {
				return 'archive';
			} else if (mimeType.startsWith('application/json')) {
				return 'data';
			} else if (mimeType.startsWith('application/javascript')) {
				return 'code';
			} else if (mimeType.startsWith('application/xml')) {
				return 'code';
			} else if (mimeType.startsWith('multipart/form-data')) {
				return 'formData';
			} else {
				return 'unknown';
			}
		},

		// 点击是否关闭操作栏
		myFunction(e) {
			var that = this
			// console.log('e',e)
			if (this.AddShow == true && e.target._prevClass == 'overlay') {
				this.AddShow = false
				this.$overlay.hide();
			}
			if (this.optShow == true && e.target._prevClass == 'overlay') {
				this.openQrcode = false
				this.searchMessageShow = false
				this.optShow = false
				this.$overlay.hide();
			}
			if (e.target._prevClass == 'overlay') {
				this.auditShow = false
				this.openInfoFrame = false
				this.openInfoFrameEdit = false
				this.detailFrameShow = false
				this.verifyShow = false
				this.$overlay.hide();
			}
		},
		// 判断是否图片还是视频
		isImgOfVideo(url) {
			var extension = url.split('.').pop().toLowerCase();
			// console.log('后缀',extension)
			if (this.imageExtensions.includes(extension)) {
				return 'image'
			}
			if (this.videoExtensions.includes(extension)) {
				return 'video'
			}
			return 'image'
		},
		// 检测数组是否为空
		isEmpty(obj) {
			return Object.keys(obj).length === 0 && obj.constructor === Object;
		},
		getTimes(t) {
			let h = parseInt(t / 60 / 60 % 24)
			let m = parseInt(t / 60 % 60)
			let s = parseInt(t % 60)
			//三元表达式 补零 如果小于10 则在前边进行补零 如果大于10 则不需要补零
			let data = {
				m: m,
				s: s
			}
			h = h < 10 ? '0' + h : h
			m = m < 10 ? '0' + m : m
			s = s < 10 ? '0' + s : s

			return data
		},
		/********时间格式化处理********/
		//使用事例 return top.dateFtt(crtTime,"yyyy/MM/dd hh:mm:ss");
		dateFtt(date, fmt) {
			date = new Date(date);
			var o = {
				"M+": date.getMonth() + 1, //月份   
				"d+": date.getDate(), //日   
				"h+": date.getHours(), //小时   
				"m+": date.getMinutes(), //分   
				"s+": date.getSeconds(), //秒   
				"q+": Math.floor((date.getMonth() + 3) / 3), //季度   
				"S": date.getMilliseconds() //毫秒   
			};
			if (/(y+)/.test(fmt))
				fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
			for (var k in o)
				if (new RegExp("(" + k + ")").test(fmt))
					fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[
							k])
						.length)));
			return fmt;
		},
	}
};
import remoteDesktop from '../components/pop-up/remoteDesktopPop.vue';
 
const RemoteDesktopPlugin = {
  install(Vue) {
    const OverlayConstructor = Vue.extend(remoteDesktop);
    const overlayInstance = new OverlayConstructor();
    overlayInstance.$mount(document.createElement('div'));
    document.body.appendChild(overlayInstance.$el);
 
    // 添加实例方法
    Vue.prototype.$overlayRemote = {
      show(_type, _touser, _isok) {
        overlayInstance.show(_type, _touser, _isok);
      },
      hide() {
        overlayInstance.hide();
      },
    };
  },
};
 
export default RemoteDesktopPlugin;
import Overlay from '../components/Overlay.vue';
 
const OverlayPlugin = {
  install(Vue) {
    const OverlayConstructor = Vue.extend(Overlay);
    const overlayInstance = new OverlayConstructor();
    overlayInstance.$mount(document.createElement('div'));
    document.body.appendChild(overlayInstance.$el);
 
    // 添加实例方法
    Vue.prototype.$overlay = {
      show() {
        overlayInstance.show();
      },
      hide() {
        overlayInstance.hide();
      },
    };
  },
};
 
export default OverlayPlugin;
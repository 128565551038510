<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" width="884px" class="myDialogClass"
			@close="closeDialog">

			<!-- <span>这是一段信息</span> -->
			<div>
				<div style="font-weight: 400;font-size: 16px;color: #333333;margin-bottom: 15px;">昵称</div>
				<el-input v-model="content" class="memberInput" placeholder="请输入昵称"></el-input>
				<div style="font-weight: 400;font-size: 16px;color: #333333;margin-bottom: 15px;margin-top: 19px;">头像
				</div>
				<div class="flexal" style="margin-bottom: 27px;">
					<el-upload action="#" accept="image/png,image/jpg" :show-file-list="false"
						:http-request="imgUpload">
						<img :src="useravatar" style="width: 57px;height: 57px;border-radius: 50%;" />
						<div style="font-weight: 400;font-size: 16px;color: #366EF4;margin-left: 20px;">点击上传头像</div>
					</el-upload>
				</div>
			</div>
			<!--弹窗底部-->
			<template #footer>
				<span class="myDialogFooter">
					<el-button type="primary" @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="handleeditusers()"
						style="background: #0052D9;color: #FFFFFF;">确定</el-button>
					<!-- <el-button type="primary" @click="dialogVisible = false" style="background: #0052D9;color: #FFFFFF;" v-if="type == 1">创建</el-button> -->
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'bookEditPop',
		props: {
			// 是否显示弹窗
			dialogVisibleShow: {
				type: Boolean,
				default: false
			},
			// 弹窗标题
			title: {
				type: String,
				default: '编辑管理'
			},
			// 现有头像
			avatar: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				dialogVisible: false,
				input: '',
				num: 0,
				content: '',
				useravatar: ''
			};
		},

		watch: {
			// New: 新值 | Old: 老值
			dialogVisibleShow(New, Old) {
				this.dialogVisible = New
				// console.log(`新值:${New} ——— 老值:${Old}`)
			},
			avatar(newValue) {
				this.useravatar = newValue;
			},
		},
		mounted() {
			// console.log('组件初始化',this.dialogVisible)
		},
		methods: {
			closeDialog() {
				// console.log('我被取消了',this.dialogVisibleMember)
				// this.dialogVisible = false
				// this.dialogVisibleMember = 'false'
				this.$emit('updateDialog')
				// this.$emit('update:dialogVisibleMember', false)
			},
			cancelClick(index) {
				// console.log('关闭前',e)
				// this.checkList.push(list[index])
				this.list[index].checked = false
			},
			scrollLoad() {
				console.log('下拉到底部了')
			},
			//提交图片
			async imgUpload(item) {
				const formData = new FormData()
				var fileno = ''
				if (fileno && fileno != '') formData.append('uploadfileNo', fileno)
				formData.append('uploadfile', item.file)
				var res = await this.$request.post('/upload/UploadFile', formData)
				console.log(res)
				if (res.code == 200) {
					//http://localhost:5263/Upload/2024/0902/202409021543423566.png

					this.useravatar = res.data
				}
			},
			//编辑用户
			async handleeditusers() {
				if (!this.content || this.content == '') {
					this.$message.error('用户昵称不能为空')
					return false
				}
				var data = {
					UserName: this.content,
					Avatar: this.useravatar
				}
				console.log('propsdata', data)
				var res = await this.$request.post('/users/editUser', data)
				console.log(res)
				if (res.code == 200) {
					this.dialogVisible = false
					var udata = localStorage.getItem('uadta')
					if (udata) {
						var user = JSON.parse(udata)
						user.Avatar = this.useravatar
						user.UserName = this.content
						localStorage.setItem('uadta', JSON.stringify(user))
					}
				}


			},
		},
	};
</script>

<style scoped lang="less">
	/* .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
  } */

	.frame {
		width: 836px;
		height: 531px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 1px solid #B0B1B4;
		display: flex;

		.frame-block {
			width: 50%;
		}

		.left-item {
			display: flex;
			width: 390px;
			align-items: center;
			border-radius: 8px;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			/deep/.el-input__inner {
				height: 36px;
				font-size: 15px;
				border-radius: 8px;
			}
		}

		.frame-text {
			font-weight: 400;
			font-size: 16px;
			color: #000000;
		}

		.frame-people {
			height: 420px;
			// background: #F8F9FA;
			// background-color: #000000;
			z-index: 101;
			/* Ensure it sits on top of other content */
			// -webkit-overflow-scrolling: touch;
			// overflow-scrolling: touch;
			// overflow-y: auto;
			// overflow-x: hidden;
		}
	}

	.el-dialog .el-dialog__footer {
		padding: 10px;
	}

	/deep/.el-dialog__body {
		padding: 0 1vw;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.memberInput .el-input__inner {
		width: 836px;
		height: 56px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 1px solid #B0B1B4;
	}

	.myDialogFooter .el-button {
		// border-color: rgb(42,155,152);
		// background-color: rgb(242,249,249);
		// color: rgb(42,155,152);
		width: 113px;
		height: 45px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #0052D9;
		font-size: 16px;
		color: #0052D9;
	}
</style>
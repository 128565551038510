<template>
	<div style="width: 100%;float: right;display: flex;justify-content: flex-end;">

		<!-- 编辑群信息 -->
		<div class="right-frame">
			<!-- 编辑群信息 -->
			<div class="right-frame-title flexal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="closeDialog" />
				<div class="right-tips-text" style="font-size: 16px;margin-left: 20px;flex: 1;">
					搜索会话内容-{{tabs[tabsIndex].name}}</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;" @click="closeDialog" />
			</div>
			<!-- 搜索框 -->
			<div class="left-item" style="margin-top: 15px;margin-left: 14px;">
				<el-input v-model="input" placeholder="搜索">
					<div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
						<div class="prefix-icon3"></div>
					</div>
				</el-input>
			</div>
			<!-- 导航栏 -->
			<div class="flexal">
				<div class="frame-text flexcen" :class="tabsIndex == index? 'frame-text-active':''"
					v-for="(item,index) in tabs" :key="index" @click="tabsChange(index)">
					{{item.name}}
					<div class="frame-slid-block" v-if="tabsIndex == index"></div>
				</div>
			</div>
			<!-- 筛选条件 -->
			<div class="flexal">
				<el-select v-model="memberId" filterable placeholder="来自用户" clearable
					style="width: 140px;height: 50px;margin-left: 16px;" v-if="tabsIndex != 3">
					<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>

				<el-date-picker v-if="tabsIndex == 0" v-model="dateTime" type="date" placeholder="时间" class="frame-date"
					style="width: 140px;height: 50px;margin-left: 16px;"></el-date-picker>
				<div style="flex: 1;"></div>
				<div class="frame-reset" v-if="memberId!=''&&dateTime !=null" @click="resetClick">重置</div>
			</div>
			<!-- 消息搜索结果 -->
			<div style="padding: 0px 17px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 0">
				<div class="flexal" style="margin-top: 17px;" v-for="(item,index) in msglist" :key="index">
					<img :src="getuserinfo(item.UserId,1)" style="width: 46px;height: 46px;border-radius: 50%;" />
					<div style="margin-left: 9px;flex: 1;">
						<div style="font-weight: 500;font-size: 14px;color: #333333;width: 273px;" class="ellipsis">
							{{getuserinfo(item.UserId,0)}}
						</div>
						<div style="font-weight: 400;font-size: 12px;color: #666666;width: 273px;margin-top: 5px;"
							class="ellipsis" v-html="item.MsgContent"></div>
					</div>
					<div style="font-weight: 400;font-size: 12px;color: #666666;">
						{{dateFtt(item.CreateTime,'MM:dd hh:mm')}}
					</div>
				</div>
			</div>
			<!-- 云文档搜索结果 -->
			<div style="padding: 0px 17px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 1">
				<div class="flexal" style="margin-top: 17px;" v-for="(item,index) in yunList" :key="index">
					<img :src="require('@/assets/img/messageRight/searchCloud.png')"
						style="width: 30px;height: 37px;" />
					<div style="margin-left: 10px;flex: 1;">
						<div style="font-weight: 500;font-size: 14px;color: #333333;width: 273px;" class="ellipsis">
							{{item.MsgName}}
						</div>
						<div style="font-weight: 400;font-size: 12px;color: #666666;width: 273px;margin-top: 5px;"
							class="ellipsis">{{getuserinfo(item.UserId,0)}}
							发送于{{dateFtt(item.CreateTime,'MM:dd hh:mm')}}</div>
					</div>
					<img :src="require('@/assets/img/skip.png')" style="width: 14px;height: 14px;" />
				</div>
			</div>
			<!-- 文件搜索结果 -->
			<div style="padding: 0px 17px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 2">
				<div class="flexal" style="margin-top: 17px;" v-for="(item,index) in doumentList" :key="index">
					<img :src="require('@/assets/img/messageRight/searchFile.png')" style="width: 30px;height: 37px;" />
					<div style="margin-left: 10px;flex: 1;">
						<div style="font-weight: 500;font-size: 14px;color: #333333;width: 273px;" class="ellipsis">
							{{item.MsgName}}
						</div>
						<div style="font-weight: 400;font-size: 12px;color: #666666;width: 273px;margin-top: 5px;"
							class="ellipsis">{{getuserinfo(item.UserId,0)}}
							发送于{{dateFtt(item.CreateTime,'MM:dd hh:mm')}}</div>
					</div>
					<img :src="require('@/assets/img/skip.png')" style="width: 14px;height: 14px;" />
				</div>
			</div>
			<!-- 图片/视频搜索结果 -->
			<div style="padding: 0px 16px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 3">
				<div style="margin-top: 29px;" v-for="(item,index) in photoList" :key="index">
					<div
						style="margin-left: 6px;font-weight: 400;font-size: 16px;color: #666666;width: 273px;margin-bottom: 10px;">
						{{item.year}}年{{item.month}}月
					</div>
					<div style="display: flex; flex-wrap: wrap;">
						<template v-for="(img,imgKey) in item.list">
							<el-image :src="img" ref="myImg"
								style="width: 98px;height: 98px;margin-left: 6px;margin-top: 6px;" :key="imgKey"
								:preview-src-list="item.list" v-if="isImgOfVideo(img) == 'image'"
								@click="handlePriveImg(img)" class="my-img" />
							<div style="width: 98px;height: 98px; margin-left: 6px;margin-top: 6px;" :key="imgKey"
								v-if="isImgOfVideo(img) == 'video'" @click="openVideo(img)">
								<div
									style="width: 98px;height: 98px;position: absolute;background-color: rgba(0, 0, 0, 0.4);display: flex; ">
									<img :src="require('@/assets/img/messageRight/video-white.png')"
										style="width: 20px;height: 15px;margin-top: 72px;margin-left: 9px;" />
									<div style="color: #FFFFFF;font-size: 12px;margin-top: 72px;margin-left: 9px;">00:20
									</div>
								</div>
								<video :src="img" style="width:100%; height:100%; object-fit: fill"></video>
							</div>
						</template>
					</div>
				</div>
			</div>

			<!-- 链接搜索结果 -->
			<div style="padding: 0px 17px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 4">
				<div class="flexal" style="margin-top: 17px;" v-for="(item,index) in linklist" :key="index">
					<img :src="require('@/assets/img/messageRight/searchLine.png')"
						style="width: 55px;height: 55px; border-radius: 50%;" />
					<div style="margin-left: 10px;flex: 1;">
						<div style="font-weight: 500;font-size: 14px;color: #333333;width: 273px;" class="ellipsis">
							{{item.MsgName}}
						</div>
						<div
							style="font-weight: 400;font-size: 12px;color: #666666;width: 245px;margin-top: 5px;overflow-wrap: break-word;">
							{{item.MsgContent}}
						</div>
						<div style="font-weight: 400;font-size: 12px;color: #666666;width: 273px;margin-top: 5px;"
							class="ellipsis">{{getuserinfo(item.UserId,0)}}
							发送于{{dateFtt(item.CreateTime,'MM:dd hh:mm')}}</div>
					</div>
					<img :src="require('@/assets/img/skip.png')" style="width: 14px;height: 14px;" />
				</div>
			</div>
		</div>
		<!-- 视频预览弹窗 -->
		<videoPlayerPop :dialogVisibleShow="dialogVisibleVideoShow" @updateDialog="updateDialogClick" :VideoUrl="video">
		</videoPlayerPop>
	</div>
</template>

<script>
	import videoPlayerPop from '../pop-up/videoPlayerPop.vue';
	export default {
		name: 'searchMessage',
		props: {
			// 弹窗标题
			index: {
				type: Number,
				default: 1
			},
			ulist: {
				type: Array,
				default: []
			},
			roomid: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				input: '',
				tabs: [{
					type: 0,
					name: '消息'
				}, {
					type: 6,
					name: '云文档'
				}, {
					type: 4,
					name: '文件'
				}, {
					type: 2,
					name: '图片/视频'
				}, {
					type: 3,
					name: '链接'
				}],
				tabsIndex: 0,
				video: 'https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/Video/2024/0730/202407301050064716.mp4',
				// 组织成员
				list: [],
				// 图片/视频数据集
				photoList: [],
				yunList: [], //云文档
				doumentList: [], //文件
				msglist: [], //文字消息
				linklist: [], //链接消息
				srcList: [], //预览图片集
				memberId: '', //选择成员
				dateTime: null, //选择日期
				query: {
					id: 0, //群组ID
					uid: 0, //用户id
					type: 0, //消息类型
					page: 1, //页码
					limit: 20, //条数
					isnext: true, //可否加载下一页
					isload: false, //加载中

				}
			}
		},
		watch: {
			// New: 新值 | Old: 老值
			memberId(New, Old) {
				// this.tabsIndex = New
				console.log(`新值:${New} ——— 老值:${Old}`)
			},
			dateTime(New, Old) {
				// this.tabsIndex = New
				console.log(`新值:${New} ——— 老值:${Old}`)
			},
			ulist(newval) {
				this.list = newval
			}

		},
		mounted() {
			this.tabsIndex = this.index
			this.query.page = 1
			this.getlists()
		},
		components: {
			videoPlayerPop
		},
		created() {
			document.addEventListener("click", this.myFunction)
		},
		methods: {
			// 关闭
			closeDialog() {
				console.log('我被取消了', this.dialogVisibleMember)
				this.$emit('updateDialog')
			},
			// 重置
			resetClick() {
				this.memberId = '' //选择成员
				this.dateTime = null //选择日期
			},
			//滑动到底部
			scrollLoad() {
				console.log('下拉到底部了')
			},

			// 点击查看大图
			handlePriveImg(row) {
				this.srcList.push(row); //将后端的这一行的数据push进数组中
				//   this.$refs.myImg.clickHandler();
			},
			// 点击查看视频
			openVideo(value) {
				console.log('我被点击了')
				this.dialogVisibleVideoShow = true
				this.video = value
			},
			// 弹窗关闭回调
			updateDialogClick() {
				// console.log('我被调用了')
				this.dialogVisibleVideoShow = false
			},
			tabsChange(index) {
				this.tabsIndex = index
				this.query.page = 1
				this.msglist = []
				this.yunList = []
				this.doumentList = []
				this.photoList = []
				this.linklist = []
				this.getlists()
			},
			async getlists() {
				var rlist = []
				if (!this.query.isload) {
					this.query.isload = true
					if (this.query.page == 1) {
						this.query.isnext = true
					}
					if (this.query.isnext) {

						this.query.id = this.roomid
						this.query.type = this.tabs[this.tabsIndex].type
						var row = {
							type: this.query.type
						}
						if (this.query.type == 2) {
							row.type2 = 5
						}
						rlist = await this.msggetlist(this.query.id, this.query.page, this.query.limit, row)
						this.query.page += 1
						if (rlist.length < this.query.limit) this.query.isnext = false
						console.log('rlist', rlist)
					}
					this.query.isload = false
				}

				if (this.tabsIndex == 0) {
					// rlist.sort((a, b) => a.MsgId - b.MsgId);
					this.msglist = this.msglist.concat(rlist)
				} else if (this.tabsIndex == 1) {
					this.yunList = this.yunList.concat(rlist)
				} else if (this.tabsIndex == 2) {
					this.doumentList = this.doumentList.concat(rlist)
				} else if (this.tabsIndex == 3) {
					rlist.sort((a,b)=>a.CreateTime-b.CreateTime)
					rlist.map(it => {
						var content=JSON.parse(it.MsgContent)
						var index = this.photoList.findIndex(a => a.year == it.YearNum && a.month == it.MonthNum)
						if (index >= 0) {
							this.photoList[index].list.push(content.url)
						} else {
							var data = {
								year: it.YearNum,
								month: it.MonthNum,
								list: []
							}
							data.list.push(content.url)
							this.photoList.push(data)
						}
					})
				} else if (this.tabsIndex == 4) {
					this.linklist = this.linklist.concat(rlist)
				}
			}
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.my-img:hover {
		//使鼠标悬浮在图片上时出现手的形状
		cursor: pointer;
	}

	.right-frame {
		margin-top: 0px;
		position: absolute;
		width: 457px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		z-index: 101;
		/* Ensure it sits on top of other content */
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;
		overflow-y: auto;
		overflow-x: hidden;

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.right-frame-title {
			padding: 15px 16px;
		}

		.right-line-bottom {
			margin-left: 16px;
			width: 424px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}

		.right-tips-text {
			font-weight: 400;
			color: #6B6B6B;
			font-size: 14px;
		}

		.el-button {
			padding: 0px 0px;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.3);
			font-size: 24px;
			color: rgba(255, 255, 255, 1);
			border: transparent;
			overflow: hidden;
		}

		.title-qrCode-frame {
			position: absolute;
			margin-left: -300px;
			z-index: 101;
			/* Ensure it sits on top of other content */
			width: 302px;
			height: 412px;
			background-image: url('../../assets/img/navigation/qrCode-frame.png');
			/* 替换为实际的图片路径 */
			background-size: cover;
			/* 确保背景图像覆盖整个容器 */
			background-position: center;
			/* 将背景图像居中对齐 */
			background-repeat: no-repeat;
			/* 避免背景图像重复 */
			box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.17);
			border-radius: 16px 16px 16px 16px;
		}

		.saveQrCode {
			width: 132px;
			height: 39px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 12px;
			color: #0052D9;
		}

		.shareQrCode {
			margin-left: 10px;
			width: 132px;
			height: 39px;
			background: #0052D9;
			border-radius: 8px 8px 8px 8px;
			color: #FFFFFF;
			font-size: 12px;
		}

		.frame-reset {
			margin-top: -10px;
			margin-right: 16px;
			font-weight: 400;
			font-size: 16px;
			color: #2953E7;
		}
	}

	.left-item {
		display: flex;
		width: 425px;
		align-items: center;
		border-radius: 8px;

		.prefix-icon3 {
			margin-left: 5px;
			width: 15px;
			height: 15px;
			background-image: url('../../assets/img/navigation/search.png');
			background-size: cover;
			/* 确保图像覆盖整个背景区域 */
			background-position: center;
			/* 图像居中对齐 */
			background-repeat: no-repeat;
			/* 防止图像重复 */
		}

		/deep/.el-input__inner {
			height: 36px;
			font-size: 15px;
			border-radius: 8px;
		}
	}

	::v-deep .enterpriseName .el-input__inner {
		width: 100px;
		height: 24px;
		background-color: #F8F9FA;
		text-align: center;
		border-color: #F8F9FA;
		color: #000;
		border: 0px solid #DCDFE6;
		padding: 0px 0px;
		text-align: left;
		font-size: 14px;
	}

	.frame-text {
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		width: 20%;
		padding-top: 24px;
		padding-bottom: 25px;
		flex-direction: column;

		.frame-slid-block {
			width: 28px;
			height: 3px;
			background: #0052D9;
			border-radius: 4px 4px 4px 4px;
		}
	}

	.frame-text-active {
		color: #0052D9;
	}

	::v-deep .frame-date .el-input__icon {
		height: 40px;
	}
</style>
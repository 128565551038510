<template>
	<div class="overlayPhone flexcen" @click="handleOverlayClick" id="overlay" ref="tag"
		:style="{'z-index':visible?'100':'-100'}">
		<div class="frame flexcumal" v-if="visible">
			<div class="flexcumal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="cancelClick" />
				<div class="right-tips-text" style="font-size: 16px;margin-left: 20px;flex: 1;">
					远程协助</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;" @click="cancelClick" />
			</div>

			<div class="flexcumal ">
				<img :src="require('@/assets/img/messageRight/c376a2944e3195a91c5e25336b6f540.png')"
					style="width: 204px;height: 138px;margin-top: 145px;" v-if="!isok" />
				<div style="font-weight: 400;font-size: 14px;color: #333333;margin-top: 54px;" v-if="!isok">
					{{teltype == 0?`${touser.UserName}正在请求远程控制您的电脑，等待您的回应`:`${touser.UserName}正在邀请您的远程电脑协助，等待您的回应`}}
				</div>
				<div :style="`margin-top: 54px;${isok?'width: 100%;height: 100%;':'width: 1px;height:  1px;'}`"
					v-if="isok">
					<video style="width: 100%;height: 100%;" :id="`remoteVideo`" muted autoplay></video>
				</div>
				<div class="flexcen">
					<div class="flexcumal" v-if="isok" @click="cancelClick()">
						<img :src="require('@/assets/img/popUp/tele-stop.png')"
							style="width: 61px;height: 61px;margin-top: 55px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;margin-top: 5px;">挂断</div>
					</div>
					<div class="flexcumal" v-if="!isok" @click="sumbitAnswerFun(false)">
						<img :src="require('@/assets/img/messageRight/e240f605b4b3c0c72335c020bffae3c.png')"
							style="width: 61px;height: 61px;margin-top: 55px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;margin-top: 5px;">拒绝</div>
					</div>
					<div class="flexcumal" style="margin-left: 116px;" v-if="!isok" @click="sumbitAnswerFun(true)">
						<img :src="require('@/assets/img/messageRight/e531caa835bcc2972dd243354dd0708.png')"
							style="width: 61px;height: 61px;margin-top: 55px;" />
						<div style="font-weight: 400;font-size: 13px;color: #000000;margin-top: 5px;">接受</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	//var robot = require('robotjs');

	export default {
		name: 'telephonePop',
		// 是否显示弹窗
		//  dialogVisibleShow: {
		//        type: Boolean,
		//        default: false
		//    },
		data() {
			return {
				visible: false,
				cancelTele: false, //挂断
				zoomValues: 0,
				teltype: 0, // 类型 0:控制自己电脑  1:控制对方电脑
				touser: {}, //请求用户
				myuser: {}, //当前用户
				isok: false, //是否同意
				localMediaStream: null, //本地音视频流
				remotetype: 0, //0-傀儡端 1-控制端
			};
		},
		watch: {
			visible: {
				handler(New, Old) {
					if (New) {
						console.log('进入远程控制页面')
						if (this.$eventBus) {
							this.$eventBus.$on("remote_rtcmessage", (wdata) => {
								console.log('remote_rtcmessage', wdata)
								if (wdata.code == 'Answer') {
									this.receiveAnswer(wdata.data)
								} else if (wdata.code == 'Offer') {
									this.receiveOffer(wdata.data)
								} else if (wdata.code == 'Candidate') {
									this.saveIceCandidate(wdata.data)
								} else if (wdata.code == 'Over') {
									this.handOverTel()
								}
							});
						}
					} else {
						console.log('远程控制页面关闭')

						//销毁事件
						if (this.$eventBus) {
							this.$eventBus.$off("remote_rtcmessage")
						}
						if (this.remotetype > 0) {
							// 移除全局事件监听
							document.removeEventListener('click', this.globalClickHandler);
							document.removeEventListener('keyup', this.globalKeyupHandler);
							document.removeEventListener('mousemove', this.handleMouseMove);
						}


						if (this.localMediaStream) {
							// 关闭摄像头和麦克风
							this.localMediaStream.getTracks().forEach(track => {
								track.stop();
							});
							this.localMediaStream = null
						}

						if (this.touser.rtcPeerConnection) {
							let pc = this.touser.rtcPeerConnection
							// 关闭所有已经打开的数据流追踪
							if (pc.getSenders) {
								pc.getSenders().forEach(function(sender) {
									sender.track ? sender.track.stop() : null;
								});
							}

							// 关闭所有已经创建的媒体通道
							pc.getTransceivers().forEach(function(transceiver) {
								if (transceiver.stop) {
									transceiver.stop();
								}
							});

							// 关闭信令机制，并且断开所有的网络连接
							pc.close();
						}
					}
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},

		},
		methods: {
			show(_type, _touser, _isok) {
				var udata = localStorage.getItem('udata')
				if (udata) {
					var user = JSON.parse(udata);
					this.myuser = user
				}
				this.localMediaStream = null
				this.teltype = _type
				this.isok = _isok
				this.touser = _touser
				this.touser.rtcPeerConnection = null
				this.touser.dataChannel = null

				this.visible = true;
				this.cancelTele = false
				this.remotetype = _type
				if (_isok && _type == 0) {
					this.okClick()
				}
				console.log('端类型-' + _isok, this.remotetype > 0 ? '控制端' : '傀儡端')



			},
			handleMouseMove(event) {
				// 处理鼠标移动事件
				// console.log(`鼠标移动Mouse position: X=${event.clientX}, Y=${event.clientY}`);
			},
			globalClickHandler(event) {
				console.log('鼠标单击Global click event:', event);
				if (this.remotetype > 0 && this.touser.dataChannel) {

					var data = {
						type: event.type,
						key: event.key,
						code: event.code
					}

					this.touser.dataChannel.send(JSON.stringify(data))
				}
				// 处理全局点击事件
			},
			globalKeyupHandler(event) {
				console.log('键盘Global keyup event:', event);
				if (this.remotetype > 0 && this.touser.dataChannel) {

					var data = {
						type: event.type,
						key: event.key,
						code: event.code
					}

					this.touser.dataChannel.send(JSON.stringify(data))
				}
				// 处理全局键盘释放事件
			},
			hide() {
				this.visible = false;
				console.log('关闭了', this.zoomValue)
			},
			handleOverlayClick() {
				this.$emit('click');
			},
			//取消
			cancelClick() {
				this.cancelTele = true
				this.sendOver()
				setTimeout(() => {
					this.visible = false
				}, 1000)
			},
			//收到挂断请求
			handOverTel() {
				setTimeout(() => {
					this.visible = false
				}, 1000)
			},

			//发送Over请求
			async sendOver() {
				//通知用户我已挂断
				var rtcdata = JSON.stringify({
					type: 6999,
					code: 'Over',
					userid: this.touser.ID,
					sendid: this.myuser.ID, //发送用户(我)Id
					data: '结束通话'
				});
				var data = {
					UserId: this.touser.ID,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
			},

			//发送offter请求
			async sendOffer(sdp) {
				var rtcdata = JSON.stringify({
					type: 6999,
					code: 'Offer',
					userid: this.touser.ID,
					sendid: this.myuser.ID, //发送用户(我)Id
					data: sdp
				});
				var data = {
					UserId: this.touser.ID,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
				if (rs.code == 200) {

				}

			},

			//发送Answer请求
			async sendAnswer(sdp) {
				var rtcdata = JSON.stringify({
					type: 6999,
					code: 'Answer',
					userid: this.touser.ID,
					sendid: this.myuser.ID, //发送用户(我)Id
					data: sdp
				});
				var data = {
					UserId: this.touser.ID,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
				if (rs.code == 200) {

				}
			},

			//发送candidate信息,用于交换
			async sendcandidate(candidate) {
				var rtcdata = JSON.stringify({
					type: 6999,
					code: 'Candidate',
					userid: this.touser.ID,
					sendid: this.myuser.ID, //发送用户(我)Id
					data: candidate
				});
				var data = {
					UserId: this.touser.ID,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
				if (rs.code == 200) {

				}
			},
			async sumbitAnswerFun(isok) {

				var robot = require("@jitsi/robotjs");
				// 模拟按下和松开键
				robot.keyTap('enter');

				//isok 同意/拒绝
				var rtcdata = JSON.stringify({
					type: 6999,
					code: 'AnswerRemote',
					userid: this.touser.ID,
					sendid: this.myuser.ID, //发送用户(我)Id
					data: isok
				});
				var data = {
					UserId: this.touser.ID,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
				if (rs.code == 200) {
					this.isok = isok
					if (this.isok) {
						if (this.remotetype == 0) {
							//傀儡端开屏幕分享
							this.okClick()
						} else {
							//控制端添加鼠标键盘监听事件
							// 添加全局鼠标事件监听
							document.addEventListener('click', this.globalClickHandler);
							// 添加全局鼠标移动监听
							document.addEventListener('mousemove', this.handleMouseMove);

							// 添加全局键盘事件监听
							document.addEventListener('keyup', this.globalKeyupHandler);

						}
					} else {
						this.cancelClick()
					}
				}
			},

			//接通
			okClick() {
				console.log('傀儡端执行okClick')
				var myuserid = this.myuser.ID

				//获取屏幕媒体流后
				this.openLocalMedia(() => {
					console.log('获取屏幕媒体流后执行')
					//创建rtc
					this.createPeerConnection()
					if (this.isok) {
						//创建offter后给用户发送offter请求
						this.createOffer((sdp) => {
							this.sendOffer(sdp)
						})
					}
				})

			},

			// 创建 PeerConnection 对象
			createPeerConnection() {

				//ice服务器信息, 用于创建 SDP 对象
				let iceServers = {
					"iceServers": [{
							"url": "stun:stun.l.google.com:19302"
						},
						//{ "urls": ["stun:159.75.239.36:3478"] },
						//{ "urls": ["turn:159.75.239.36:3478"], "username": "chr", "credential": "123456" },
					]
				};
				var rtcPeerConnection = new RTCPeerConnection(iceServers)
				console.log('rtcPeerConnection创建', rtcPeerConnection)


				//将 音视频流 添加到 端点 中
				if (this.localMediaStream) {
					for (const track of this.localMediaStream.getTracks()) {
						rtcPeerConnection.addTrack(track, this.localMediaStream);
					}
				}
				rtcPeerConnection.ontrack = (event) => {
					if (event) {
						console.log('流 的回调')
						var stream = event.streams[0]
						//绑定远程视频流
						var id = `remoteVideo`
						//显示 远程视频流
						let remoteVideo = document.getElementById(id);
						remoteVideo.srcObject = stream;
						// remoteVideo.play();

					}
				};
				// 监听DataChannel的open事件
				rtcPeerConnection.ondatachannel = (event) => {
					const dataChannel = event.channel;

					// 监听DataChannel的message事件
					dataChannel.onmessage = (event) => {
						const message = JSON.parse(event.data);
						console.log('接收到消息:', message);
						if (message.type == 'keyup') {
							var robot = require("@jitsi/robotjs");
							robot.keyTap(message.key)
						}
					};

					// 监听DataChannel的error事件
					dataChannel.onerror = (error) => {
						console.error('DataChannel错误:', error);
					};

					// 监听DataChannel的close事件
					dataChannel.onclose = () => {
						console.log('DataChannel已关闭');
					};
				};

				let dc = rtcPeerConnection.createDataChannel('myDataChannel');
				dc.onopen = function() {
					console.log('opened')
					// dc.send(JSON.stringify({
					// 	type,
					// 	data
					// }))
				}
				dc.onmessage = function(event) {
					console.log('message', event)
				}
				dc.onerror = (e) => {
					console.log(e)
				}
				this.touser.dataChannel = dc


				if (this.remotetype > 0) {
					//控制端添加鼠标键盘监听事件
					// 添加全局鼠标事件监听
					document.addEventListener('click', this.globalClickHandler);
					// 添加全局鼠标移动监听
					document.addEventListener('mousemove', this.handleMouseMove);

					// 添加全局键盘事件监听
					document.addEventListener('keyup', this.globalKeyupHandler);
				}


				this.touser.rtcPeerConnection = rtcPeerConnection

				console.log('创建rtc成功')

				//绑定 收集 candidate 的回调
				this.bindOnIceCandidate((candidate) => {
					//发送candidate信息,用于交换
					this.sendcandidate(candidate)

				})
				console.log('创建视频回调成功')




			},
			//获取本地屏幕媒体流
			openLocalMedia(callback) {
				if (!this.localMediaStream) {
					this.getDisplayMediaFun().then(stream => {
						this.localMediaStream = stream;
						callback();
					}).catch((e) => {
						console.log('获取本地屏幕媒体流错误', e)
					})

				}
			},
			//获取屏幕媒体流
			getDisplayMediaFun() {
				return new Promise((resolve, reject) => {
					if (window.electron) {
						window.electron.ipcRenderer.invoke('getSources').then(inputSources => {
							console.log('ipcRenderer视频流', inputSources);
							var soures = JSON.parse(inputSources)
							var sourceId = soures[0].id
							let constraints = {
								audio: {
									mandatory: {
										chromeMediaSource: 'desktop'
									}
								},
								video: {
									mandatory: {
										chromeMediaSource: 'desktop',
										chromeMediaSourceId: sourceId
									}
								}
							}
							navigator.mediaDevices.getUserMedia(constraints).then(stream => {
								resolve(stream)
							}).catch((e) => {
								console.log('electron-获取屏幕媒体流错误', e)
							})
						})
					} else {
						const mediaConstraints = {
							video: true,
							audio: false,
						};
						navigator.mediaDevices.getDisplayMedia(mediaConstraints).then(stream => {
							resolve(stream)
						}).catch((e) => {
							console.log('获取屏幕媒体流错误', e)
						})
					}
				});
			},

			//收到Answer请求
			async receiveAnswer(sdp) {
				this.saveSdp(sdp, () => {
					console.log('收到Answer请求保存后执行')

				})
			},

			//收到offter请求
			async receiveOffer(sdp) {
				if (this.teltype == 0) {
					//获取屏幕媒体流后
					this.openLocalMedia(() => {
						this.createPeerConnection()
						this.saveSdp(sdp, () => {
							this.createAnswer((answer) => {
								this.sendAnswer(answer)
							})
						})
					})
				} else {
					this.createPeerConnection()
					this.saveSdp(sdp, () => {
						this.createAnswer((answer) => {
							this.sendAnswer(answer)
						})
					})
				}
			},

			//创建用于 offer 的 SDP 对象
			createOffer(callback) {
				// 创建 offer 的信息
				const options = {
					iceRestart: true,
					offerToReceiveAudio: true,
				};
				// 调用PeerConnection的 CreateOffer 方法创建一个用于 offer的SDP对象，SDP对象中保存当前音视频的相关参数。
				this.touser.rtcPeerConnection.createOffer(options)
					.then(sdp => {
						// 保存自己的 SDP 对象
						this.touser.rtcPeerConnection.setLocalDescription(sdp)
							.then(() => callback(sdp));
					})
					.catch(() => console.log('createOffer 失败'));

			},
			//创建用于 answer 的 SDP 对象
			createAnswer(callback) {
				// 创建 answer 的信息
				const options = {
					iceRestart: true,
					offerToReceiveAudio: true,
				};
				// 调用PeerConnection的 CreateAnswer 方法创建一个 answer的SDP对象
				this.touser.rtcPeerConnection.createAnswer(options)
					.then(sdp => {
						// 保存自己的 SDP 对象
						this.touser.rtcPeerConnection.setLocalDescription(sdp)
							.then(() => callback(sdp));
					}).catch(() => console.log('createAnswer 失败'))

			},


			//保存远程的 SDP 对象
			saveSdp(answerSdp, callback) {
				this.touser.rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(answerSdp)).then(callback);
			},
			// 存 candidate 信息
			saveIceCandidate(candidate) {
				var rtc = this.touser.rtcPeerConnection
				if (rtc && rtc.remoteDescription) {
					let iceCandidate = new RTCIceCandidate(candidate);
					this.touser.rtcPeerConnection.addIceCandidate(iceCandidate)
						.then(() => {
							console.log('addIceCandidate0 成功')
							console.log(`connectionState0`, this.touser.rtcPeerConnection.iceConnectionState)
						}).catch((e) => {
							console.log('saveIceCandidate 失败', e)
						});
				}
			},
			// 收集 candidate 的回调
			bindOnIceCandidate(callback) {
				// 绑定 收集 candidate 的回调
				this.touser.rtcPeerConnection.onicecandidate = (event) => {
					if (event.candidate) {
						callback(event.candidate);
					}
				}
			},
		}
	}
</script>

<style scoped lang="less">
	.overlayPhone {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		// background-color: rgba(0, 0, 0, 0);
		/* Adjust transparency as needed */
		z-index: 100;
		/* Ensure it sits on top of other content */

		.frame {
			width: 1136px;
			height: 611px;
			background: #FFFFFF;
			border-radius: 24px 24px 24px 24px;
		}

		.cancel-frame {
			margin-top: 29px;
			margin-bottom: 31px;
			width: 214px;
			height: 52px;
			background: #FFFFFF;
			border-radius: 14px 14px 14px 14px;
			border: 2px solid #366EF4;
			font-size: 19px;
			font-weight: 400;
			color: #366EF4;
		}
	}
</style>
<template>
	<div style="width: 100%;float: center;display: flex;">
		<div class="frame">
			<div class="frame-title flexal">
				<div style="flex: 1;">
					<div style="font-weight: 500;font-size: 19px;color: #333333;">群公告</div>
					<div style="font-weight: 400;font-size: 14px;color: #858A99;margin-top: 5px;">
						{{dateFtt(messageDetail.UpdateTime,'yyyy/MM/dd hh:mm')}}
					</div>
				</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px; height: 18px;" @click="subimt" />
			</div>
			<div class="frame-line" style="margin-bottom: 32px;"></div>
			<div style="overflow:auto;height: 445px;padding: 0px 22px;font-weight: 400;font-size: 16px;color: #000000;"
				v-infinite-scroll="scrollLoad">
				{{messageDetail.intro}}
			</div>

			<!-- <div class="flexal" style="display: flex; justify-content: flex-end;padding: 20px 27px;">
            <div class="frame-button-cancel flexcen" @click="subimt">取消</div>
            <div class="frame-button-submit flexcen" @click="subimt">确认</div>
        </div> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'affiche',
		props: {
			messageDetail: {
				type: Object,
				default: {}
			}
		},

		watch: {
			messageDetail: {
				handler(newValue, oldValue) { //这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
					console.log('affiche message-数组变化', newValue, oldValue)
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
		},
		data() {
			return {

			}
		},
		methods: {
			//滑底触发
			scrollLoad() {
				console.log('滑底触发')
			},
			//   取消/确认/关闭，回调,0:关闭/取消  1:确认
			subimt() {
				this.$emit('afficheSubimt', 0)
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		margin-top: 0px;
		position: absolute;
		width: 726px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		// padding: 0px 22px;
		z-index: 100;

		.frame-title {
			padding: 22px;
		}

		.frame-line {
			width: 725px;
			height: 0px;
			border: 1px solid #C9C9C9;
		}

		.frame-button-cancel {
			width: 123px;
			height: 47px;
			background: #FFFFFF;
			border-radius: 16px 16px 16px 16px;
			border: 1px solid #0052D9;
			font-size: 16px;
			color: #0052D9;
		}

		.frame-button-submit {
			margin-left: 15px;
			width: 122px;
			height: 47px;
			background: #0052D9;
			border-radius: 16px 16px 16px 16px;
			font-size: 16px;
			color: #FFFFFF;
		}

		.imgIcon {
			width: 30px;
			height: 37px;
		}


	}
</style>
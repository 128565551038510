<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%" class="myDialogClass" @close="closeDialog"
			append-to-body>
			<template #title>
				<span class="myDialogFooter flexal" style="margin-left: 20px;">
					<img :src="require('@/assets/img/messageRight/warn.png')" style="width: 31px;height: 31px;" />
					<div style="font-weight: 400;font-size: 16px;color: #333333;margin-left: 10px;" v-if="type == 0">
						确定清空聊天记录？</div>
					<div style="font-weight: 400;font-size: 16px;color: #333333;margin-left: 10px;" v-if="type == 1">
						是否从列表中移除该文档？</div>
					<div style="font-weight: 400;font-size: 16px;color: #333333;margin-left: 10px;" v-if="type == 2">
						是否终止投票？</div>
				</span>
			</template>
			<div style="margin-left: 20px;" v-if="type == 0">
				聊天记录将在所有设备同步清空，该操作仅对自己生效。
			</div>
			<div style="margin-left: 20px;" v-if="type == 1">
				移除后，这些内容在列表中不可见
			</div>
			<div style="margin-left: 20px;" v-if="type == 2">
				投票终止后参会者将无法继续投票或修改投票。是否继续？
			</div>
			<!--弹窗底部-->
			<template #footer>
				<span class="myDialogFooter">
					<!-- 清空聊天记录 -->
					<el-button type="primary" @click="dialogVisible = false" v-if="type == 0">取消</el-button>
					<el-button type="primary" @click="sumbitFun()" style="background: #0052D9;color: #FFFFFF;"
						v-if="type == 0">确定</el-button>
					<!-- 移除文档 -->
					<el-button type="primary" @click="dialogVisible = false"
						style="color: #535D6D;border: 2px solid #858A99;" v-if="type == 1">取消</el-button>
					<el-button type="primary" @click="dialogVisible = false"
						style="background:#D54941;color: #FFFFFF;border: 0px solid #0052D9;"
						v-if="type == 1">确定</el-button>
					<!-- 终止投票 -->
					<el-button type="primary" @click="dialogVisible = false"
						style="color: #535D6D;border: 2px solid #858A99;" v-if="type == 2">取消</el-button>
					<el-button type="primary" @click="dialogVisible = false"
						style="background:#D54941;color: #FFFFFF;border: 0px solid #0052D9;"
						v-if="type == 2">确定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'deletePop',
		props: {
			// 是否显示弹窗
			dialogVisibleShow: {
				type: Boolean,
				default: false
			},
			roomid: {
				type: Number,
				default: 0
			},
			//  类型，0:清空聊天记录  1:移除文档  2：终止投票
			type: {
				type: Number,
				default: 0
			},
			// 弹窗标题
			title: {
				type: String,
				default: '删除'
			},
		},
		data() {
			return {
				dialogVisible: false
			};
		},

		watch: {
			// New: 新值 | Old: 老值
			dialogVisibleShow(New, Old) {
				this.dialogVisible = New
				//  console.log(`新值:${New} ——— 老值:${Old}`)
			},

		},
		mounted() {
			//  console.log('组件初始化',this.dialogVisible)
		},
		methods: {
			closeDialog() {
				//  console.log('我被取消了',this.dialogVisibleMember)
				this.$emit('updateDialog')
			},
			async sumbitFun() {
				//  类型，0:清空聊天记录  1:移除文档  2：终止投票
				if (this.type == 0) {
					var isok = await this.msgDelByroomId(this.roomid)
					if (isok) {
						this.$emit('updateDialog')
					}
				}
			}
		},
	};
</script>

<style scoped lang="less">
	.myDialogClass {
		border-radius: 16px 16px 16px 16px;
	}

	.el-dialog .el-dialog__footer {
		padding: 10px;
	}

	/deep/.el-dialog__body {
		//  padding: 0 1vw;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.el-textarea__inner {
		background: #F1F1F1;
		min-height: 214px;
	}

	.myDialogFooter .el-button {
		width: 113px;
		height: 45px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #0052D9;
		font-size: 16px;
		color: #0052D9;
	}
</style>
<template>
	<div class="hello">
		<div class="frame">
			<div class="frame-title">{{this.status>0?'已':'待' }}处理({{this.total}})</div>
			<div class="frame-header flexal">
				<div class="frame-header-text" style="width: 10%;">标题</div>
				<div class="frame-header-text" style="width: 25%;">发起人</div>
				<div class="frame-header-text" style="width: 40%;">摘要</div>
				<div class="frame-header-text" style="width: 10%;">时间</div>
				<div class="frame-header-text" style="width: 15%;">流程状态</div>
			</div>
			<div class="frame-content-block" v-infinite-scroll="scrollLoad" style="overflow:auto">
				<template v-for="(item,index) in list">
					<div :key="index">
						<div class="frame-content" style="display: flex;text-align: center;">
							<div style="width: 10%;;height: 100%;">
								<div class="frame-content-text">{{item.FormTypeCName}}</div>
							</div>
							<div style="width:25%;height: 100%;" class="flexal">
								<div class="frame-content-text">{{item.FormName}}</div>
							</div>
							<div style="width:40%;height: 100%;">
								<div class="frame-content-text">类型：{{item.FormCategory}}</div>
								<div class="frame-content-text" v-html="item.FormDesc"></div>
							</div>
							<div style="width: 10%;;height: 100%;">
								<div class="frame-content-text">{{item.CreatedDate}}</div>
							</div>
							<div style="width: 15%;height: 100%;" class="flexcen">

								<div class="frame-content-text" style="color: #26C215;"
									v-if="item.ReviewStatus == '通过'">已通过</div>
								<div class="frame-content-text" style="color: #D50022;"
									v-if="item.ReviewStatus == '撤回'">已撤回</div>
								<div class="flexcen frame-button" v-if="item.ReviewStatus == '待审批'"
									@click="handleAudit(item,'撤回')">撤回</div>
								<div class="flexcen frame-button" v-if="item.ReviewStatus == '待审批'"
									style="background: #0052D9;color: #FFFFFF;margin-left: 13px;"
									@click="handleAudit(item,'通过')">同意</div>
							</div>
						</div>
						<div class="frame-line"></div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'workList',
		props: {
			//0:待处理1：已通过2：已拒绝3:审核中
			status: {
				type: Number,
				default: 0
			}
		},
		watch: {
			status(newval) {
				this.query.page = 0
				this.list = []
				this.getdatas(true)
			},
		},
		data() {
			return {
				total: 0,
				query: {
					page: 0,
					limit: 20,
					state: 0,
				},
				avatar: 'https://cdn.uviewui.com/uview/album/1.jpg',
				list: []
			}
		},

		methods: {
			// 滑底触发
			scrollLoad() {
				console.log('下拉到底部了')
				if (this.query.page * this.query.limit <= this.total) {
					this.getdatas(true)
				}
			},
			async getdatas(isnext) {
				const loading = this.$loading({
					lock: true,
					text: 'Loading'
				});
				if (isnext) this.query.page += 1
				this.query.state = this.status
				var res = await this.$request.post('/notice/auditList', this.query)
				loading.close();
				if (res.code == 200) {
					this.total = res.data.total
					this.list = this.list.concat(res.data.items)
				}
			},
			async handleAudit(row, status = '通过') {
				this.$confirm(`确定${status}?`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					var data = row
					data.ReviewStatus = status
					const loading = this.$loading({
						lock: true,
						text: 'Loading'
					});
					var res = await this.$request.post('/notice/saveAudit', data)
					loading.close();
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
					}
					
				}).catch(err => {
					// console.error(err)
				})
				
				
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		margin-left: 12px;
		width: 1005px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;

		.frame-title {
			padding-top: 15px;
			margin-left: 16px;
			font-weight: 500;
			font-size: 19px;
			color: #333333;
		}

		.frame-header {
			margin-top: 21px;
			margin-left: 16px;
			width: 969px;
			height: 35px;
			background: #F1F2F3;
			border-radius: 0px 0px 0px 0px;
		}

		.frame-header-text {
			font-weight: 400;
			font-size: 15px;
			color: #535D6D;
			text-align: center;
		}

		.frame-content {
			margin-top: 25px;
			margin-left: 16px;
			width: 969px;
			// height: 35px;
			// background: #F1F2F3;
			border-radius: 0px 0px 0px 0px;
		}

		.frame-content-text {
			font-weight: 400;
			font-size: 13px;
			color: #272B34;
		}

		.frame-button {
			width: 52px;
			height: 25px;
			background: #FFFFFF;
			border-radius: 29px 29px 29px 29px;
			border: 1px solid #0052D9;
			font-size: 11px;
			color: #0052D9;
		}

		.frame-line {
			margin-top: 24px;
			margin-left: 16px;
			width: 969px;
			height: 0px;
			border: 1px solid #C9C9C9;
		}

		.frame-content-block {
			width: 100%;
			height: 640px;
			// background: #000;
		}
	}

	.imgIcon-avatar {
		width: 26px;
		height: 27px;
		border-radius: 50%;
	}
</style>
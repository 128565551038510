<template>
    <div class="hello">
        <div class="frame">
             <!-- 标题 -->
            <div class="flexal" style="padding-top: 15px;margin-left: 14px;margin-bottom: 10px;">
                <div class="people-title">文档</div>
            </div>
            
            <div class="flexal">
                <div class="frame-block flexcumal" style="justify-content: center;">
                    <img :src="require('@/assets/img/messageRight/word.png')"  style="width: 27px;height: 28px;margin-bottom: 2px;"/>
                   <div>新建文档</div>
                </div>
                <div class="frame-block flexcumal" style="justify-content: center;">
                    <img :src="require('@/assets/img/messageRight/ppt.png')"  style="width: 27px;height: 28px;margin-bottom: 2px;"/>
                   <div>新建PPT</div>
                </div>
                <div class="frame-block flexcumal" style="justify-content: center;">
                    <img :src="require('@/assets/img/messageRight/xlx.png')"  style="width: 27px;height: 28px;margin-bottom: 2px;"/>
                   <div>新建表格</div>
                </div>
            </div>
            <div class="frame-line"></div>
            <div class="frame-content">
                <div class="frame-item flexal ellipsis">
                    <img :src="require('@/assets/img/messageRight/word.png')"  style="width: 35px;height: 35px;margin-left: 19px;"/>
                    <div style="margin-left: 15px;">
                        <div style="font-weight: 400;font-size: 15px;color: #000000;">2024年工作文档.docx</div>
                        <div style="font-weight: 400;font-size: 11px;color: #666666;">创建人：张三     2024/08/05 10:00  </div>
                    </div>
                </div>
                <div class="frame-item flexal ellipsis">
                    <img :src="require('@/assets/img/messageRight/ppt.png')"  style="width: 35px;height: 35px;margin-left: 19px;"/>
                    <div style="margin-left: 15px;">
                        <div style="font-weight: 400;font-size: 15px;color: #000000;">2024年7月22日工作汇报文档.pptx</div>
                        <div style="font-weight: 400;font-size: 11px;color: #666666;">创建人：张三     2024/08/05 10:00  </div>
                    </div>
                </div>
                <div class="frame-item flexal ellipsis">
                    <img :src="require('@/assets/img/messageRight/xlx.png')"  style="width: 35px;height: 35px;margin-left: 19px;"/>
                    <div style="margin-left: 15px;" >
                        <div  style="font-weight: 400;font-size: 15px;color: #000000;">2024年工作文档.xls</div>
                        <div style="font-weight: 400;font-size: 11px;color: #666666;">创建人：张三     2024/08/05 10:00  </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    name: 'fileConferencePop',
    props: {
      msg: {
        type:String,
        default:''
      }
    },
    data(){
        return{
            input:'',
        }
    },
    components:{
    },
    methods:{
        submit(index){
            this.tabIndex = index
            this.$emit('workNoticeSubmit',index)
        },
        // 添加弹窗回调
        updateDialogClick(){
            this.dialogVisibleMember = false
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
    .frame{
        // margin-left: -5px;
        width: 436px;
        height: 610px;
        background: #FFFFFF;
        border-radius: 0px 16px 16px 0px;
        box-shadow: 0px 3px 26px 1px rgba(0,0,0,0.16);
        .people-title{
        // margin-left: 12px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        }
        .frame-block{
            margin-left: 36px;
            width: 91px;
            height: 74px;
            background: #FFFFFF;
            border-radius: 16px 16px 16px 16px;
            border: 2px solid #858A99;
            font-weight: 400;
            font-size: 15px;
            color: #15181D;
        }
        .frame-line{
            margin-top: 30px;
            width: 436px;
            height: 0px;
            border: 1px solid #707070;
            opacity: 0.43;
        }
        .frame-content{
            height: 440px;
            padding: 0px 16px;
            // background: #000;
            overflow: auto;
        }
        .frame-item{
            margin-top: 20px;
            width: 405px;
            height: 72px;
            background: #F9F9F9;
            border-radius: 16px 16px 16px 16px;
        }
    }
    .imgIcon{
      width: 19px;
      height: 19px;
    }

   
  </style>
  
<template>
	<div class="overlayPhone flexcen" id="overlay" ref="tag" :style="{'z-index':visible?'100':'-100' }">
		<!-- @click.stop="joinSubimtClick"> -->
		<div class="frame" v-if="visible" :class="rightShow? 'frame-active':''"
			:style="{'height':visibleVoteInConference? (610+237)+'px': (610+0) + 'px'}" @click.stop="">
			<div class="frame-title flexal">
				<div style="flex: 1;">开始会议 <img :src="require('@/assets/img/conference/recordIcon.png')"
						style="width: 17px;height: 17px;margin-left: 16px;" v-if="switchList.recordClose" /></div>

				<img :src="require('@/assets/img/cancel.png')" style="width: 15px;height: 15px;margin-right: 32px;"
					@click="joinSubimtClick" />
				<!-- <img :src="require('@/assets/img/conference/d20c22d6afd79678dec34e0196b9460.png')"  style="width: 14px;height: 2px;margin-right: 22px;"  @click="joinSubimtClick" />
          <img :src="require('@/assets/img/conference/cb6cdd4c99ac91bb30fb649e4fe2089.png')"  style="width: 15px;height: 15px;margin-right: 21px;"  @click="joinSubimtClick" />
          <img :src="require('@/assets/img/conference/575d63bbff7b7e64db25062a2d18892.png')"  style="width: 14px;height: 14px;margin-right: 26px;"  @click="joinSubimtClick" /> -->
			</div>
			<el-input v-model="name" class="memberInput" style="margin-left: 297px;width: 301px;" disabled
				placeholder="请输入会议名称"></el-input>
			<div style="width: 100%;margin-top: 10px;justify-content: center;" class="flexal"
				v-if="detail&&detail.cdata">
				<div style="font-weight: 400;font-size: 18px;color: #535353;">
					{{dateFtt(detail.cdata.MeetDate,'MM-dd hh:mm')}}
				</div>
			</div>

			<!-- 成员集 -->
			<div style="'width: 100%;height: 400px;':'width: 1px;height: 1px;'}margin-top: 20px;flex-wrap: wrap;justify-content: center;overflow: auto;"
				class="flexal"
				:style="switchList.openScreen?'width:  4px;height: 4px;': {'height':visibleVoteInConference? (400+237)+'px': (400+0) + 'px'}">
				<div class="flexcumal" style="margin-right: 65px;margin-left: 65px;margin-bottom: 16px;"
					v-for="(item,index) in joinusers" :key="index">
					<div style="display: flex;">
						<img :src="require('@/assets/img/conference/hand.png')" v-if="item.IsRaise>0"
							style="width: 21px;height: 30px;z-index: 10;" />
						<img :src="item.avatar" v-if="item.IsCamera>0"
							style="width: 92px;height: 92px;border-radius: 50%;margin-left: -20px;" />
						<video :id="`remoteVideo_${item.UserId}`" :muted="item.UserId==myuser.UserId"
							:style="`${item.IsCamera>0?'width: 1px;height: 1px;':'width: 92px;height: 92px;border-radius: 50%;margin-left: -20px;'} `"
							autoplay></video>
					</div>
					<div class="flexal" style="height: 17px;width: 92px;justify-content: center;">
						<div class="frame-text-name ellipsis" style="margin-left: 10px;">{{item.UserName}}</div>
						<img :src="require('@/assets/img/conference/audioClose.png')" v-if="item.IsMicrophone>0"
							style="width: 15px;height: 16px;margin-top: 6px;margin-left: 1px;" />
						<img :src="require('@/assets/img/conference/audio.png')" v-else
							style="width: 15px;height: 16px;margin-top: 6px;margin-left: 1px;" />
					</div>
				</div>
			</div>
			<div style="flex-wrap: wrap;justify-content: center;" class="flexal"
				:style="switchList.openScreen?'padding: 10px 28px;': 'width:  4px;height: 4px;'">
				<div style="flex-wrap: wrap;justify-content: center;background: #F3F3F4;" class="flexal"
					:style="switchList.openScreen?'height: 400px;width: 812px;': 'width:  4px;height: 4px;'">
					<video :id="`captureVideo`" muted autoplay
						:style="switchList.openScreen?'width: 800px;height: 400px;': 'width:  4px;height: 4px;'"></video>
				</div>
			</div>

			<div style="width: 100%;height: 60px;margin-top: 5px;" class="flexal">
				<!-- 打开聊天框 -->
				<div class="flexal">
					<div class="tooltip">
						<div class="tooltiptext" v-if="tooltipShow">
							<!--          消息整体-->
							<div style="height: 185px;width: 100%;overflow: auto;">
								<div :class="message.UserId==myuser.UserId?'chat-message-me':'chat-message-other'"
									:style="{'padding-bottom':messages.length-1===index?'2rem':'none'}"
									v-for="(message, index) in messages" :key="index">

									<!--            消息头像-->
									<div
										:class="message.UserId==myuser.UserId?'message-me-asWhole-headPortrait':'message-other-asWhole-headPortrait'">
										<img :src="getutxt(message.UserId,joinusers,'avatar')"
											class="examineeFace_logo_style">
									</div>
									<!--          消息-->
									<div
										:class="message.UserId==myuser.UserId?'message-me-asWhole-right':'message-other-asWhole-right'">
										<!--            消息上面-->
										<div
											:class="message.UserId==myuser.UserId?'message-me-asWhole-top':'message-other-asWhole-top'">
											<div>{{getutxt(message.UserId,joinusers,'UserName')}}
												{{getutxt(message.UserId,joinusers,'IsLeader')>0?'(主持人)':''}}
												{{dateFtt(message.CreateTime,'yyyy/MM/dd hh:mm')}}
											</div>
										</div>
										<!--          消息内容-->
										<div :class="message.UserId==myuser.UserId?'message-me':'message-other'">
											{{ message.MsgContent }}
										</div>
									</div>
								</div>
							</div>
							<div v-if="messages.length>0&&messages[messages.length-1].MsgType==41" class="exitFrame">
								{{messages[messages.length-1].MsgContent}}
							</div>
							<!-- 输入框 -->
							<el-input v-model="content" @change="sendmsg" class="inputFrame"
								style="margin-left: 11px;margin-top: 5px;" placeholder="发送消息给会议">
							</el-input>
						</div>
						<div class="frame-chat flexal" @click.stop="tooltipShow = !tooltipShow">
							<img :src="require('@/assets/img/conference/face.png')"
								style="width: 19px;height: 19px;margin-left: 11px;" />
							<div class="frame-chat-line"></div>
							<div class="frame-chart-text" style="margin-left: 10px;">说点什么</div>
						</div>
					</div>
				</div>
				<!-- 举手 -->
				<div class="frame-hand flexcen" style="margin-left: 10px;" @click=" changeFun(2)">
					<img :src="myuser.IsRaise>0? require('@/assets/img/conference/hand.png'): require('@/assets/img/conference/cameraClose.png')"
						style="width: 16px;height: 22px;" />
				</div>
				<!-- 声音 -->
				<div class="frame-hand flexcen" style="margin-left: 86px;" @click="changeFun(0)">
					<img :src="require('@/assets/img/conference/audioClose.png')" v-if="switchList.audioClose"
						style="width: 22px;height: 22px;" />
					<img :src="require('@/assets/img/conference/audio.png')" v-else style="width: 22px;height: 22px;" />
				</div>
				<!-- 摄像头 -->
				<div class="frame-hand flexcen" style="margin-left: 12px;" @click="changeFun(1)">
					<img :src="require('@/assets/img/conference/cameraClose.png')" v-if="switchList.cameraClose"
						style="width: 22px;height: 22px;" />
					<img :src="require('@/assets/img/conference/camera.png')" v-else
						style="width: 22px;height: 22px;" />
				</div>
				<!-- 录制 -->
				<div class="frame-chat flexal" style="width: 83px;margin-left: 12px;" @click="recordAction()">
					<img :src="require('@/assets/img/conference/recordIcon.png')" v-if="switchList.recordClose"
						style="width: 17px;height: 17px;margin-left: 13px;" />
					<img :src="require('@/assets/img/conference/record.png')" v-else
						style="width: 17px;height: 17px;margin-left: 13px;" />
					<!-- <div class="frame-chat-line"></div> -->
					<div class="frame-chart-text" style="margin-left: 11px;">录制</div>
				</div>
				<!-- 共享屏幕 -->
				<div class="frame-chat flexal" style="width: 102px;margin-left: 12px;" @click="openCaptureFun()">
					<img :src="require('@/assets/img/conference/ScreenIcon.png')" v-if="switchList.openScreen"
						style="width: 18px;height: 16px;margin-left: 13px;" />

					<img :src="require('@/assets/img/conference/Screen.png')" v-else
						style="width: 18px;height: 16px;margin-left: 13px;" />
					<!-- <div class="frame-chat-line"></div> -->
					<div class="frame-chart-text" style="margin-left: 11px;">共享屏幕</div>
				</div>
				<!-- 参会人 -->
				<div class="frame-chat flexal" style="width: 94px;margin-left: 12px;" @click="openRight(1)">
					<img :src="require('@/assets/img/conference/people.png')"
						style="width: 23px;height: 23px;margin-left: 13px;" />
					<!-- <div class="frame-chat-line"></div> -->
					<div class="frame-chart-text" style="margin-left: 7px;">参会人</div>
				</div>
				<!-- 菜单 -->
				<div class="tooltip" @click="menuShow = !menuShow">
					<div class="tooltiptext1" v-if="menuShow">
						<div class="flexal" style="margin-top: 13px;margin-left: 13px;" @click="openRight(3)">
							<img :src="require('@/assets/img/conference/vote.png')" style="width: 16px;height: 14px;" />
							<div style="font-weight: 400;font-size: 14px;color: #333333;margin-left: 12px">投票</div>
						</div>
						<div class="flexal" style="margin-top: 13px;margin-left: 13px;" @click="openRight(2)">
							<img :src="require('@/assets/img/conference/signIn.png')"
								style="width: 16px;height: 14px;" />
							<div style="font-weight: 400;font-size: 14px;color: #333333;margin-left: 12px">签到</div>
						</div>
						<div class="flexal" style="margin-top: 13px;margin-left: 13px;" @click="openRight(4)">
							<img :src="require('@/assets/img/conference/document.png')"
								style="width: 16px;height: 14px;" />
							<div style="font-weight: 400;font-size: 14px;color: #333333;margin-left: 12px">文档</div>
						</div>
					</div>
					<div class="frame-hand flexcen" style="margin-left: 12px;">
						<img :src="require('@/assets/img/conference/menu.png')" style="width: 22px;height: 22px;" />
					</div>
				</div>
				<!-- 退出会议 -->
				<img :src="require('@/assets/img/conference/stop.png')"
					@click="dialogVisibleDelete = !dialogVisibleDelete"
					style="width: 45px;height: 32px;margin-left: 12px;" />
			</div>
		</div>
		<div class="frame-line" v-if="rightShow"
			:style="{'height':visibleVoteInConference? (610+237)+'px': (610+0) + 'px'}"></div>
		<!-- 参会人右侧列表 -->
		<attendConferencePop v-if="attendPeopleShow" :joinlists="joinusers" @inviteUpdate="inviteUpdateFun"
			:roomid="roomId"></attendConferencePop>
		<!-- 退出会议提示弹窗 -->
		<quitConferencePop :dialogVisibleShow="dialogVisibleDelete" @updateDialog="updateDialogFun"></quitConferencePop>
		<!-- 右侧签到 -->
		<signInConferencePop v-if="visibleSignInConference" :roomId="roomId"></signInConferencePop>
		<!-- 右侧投票 -->
		<voteConferencePop v-if="visibleVoteInConference" :roomId="roomId"></voteConferencePop>
		<!-- 右侧文档 -->
		<fileConferencePop v-if="visiblefileConference"></fileConferencePop>
	</div>
</template>

<script>
	import attendConferencePop from '../pop-up/attendConferencePop.vue';
	import quitConferencePop from '../pop-up/quitConferencePop.vue';
	import signInConferencePop from '../pop-up/signInConferencePop.vue';
	import voteConferencePop from '../pop-up/voteConferencePop.vue';
	import fileConferencePop from '../pop-up/fileConferencePop.vue';
	export default {
		name: 'startConferencePop',
		props: {
			roomId: {
				type: Number,
				default: 0
			},
		},
		watch: {
			roomId(newval) {
				console.log('roomId-newval', newval)
				this.getdata()
			}
		},
		data() {
			return {
				visible: true,
				avatar: 'https://cdn.uviewui.com/uview/album/8.jpg',
				cancelTele: false,
				content: '', //信息
				name: '会议名称',
				switchList: {
					audioClose: false, //是否静音
					cameraClose: true, //摄像头关闭
					recordClose: false, //是否录制
					openScreen: false, //是否开启共享屏幕
				},
				rightShow: false, //右侧是否显示
				attendPeopleShow: false, //参会人列表是否显示
				joinusers: [], //参会人员
				messages: [],
				tooltipShow: false, //消息弹窗
				menuShow: false, //菜单弹窗
				detail: {
					cdata: {}
				},
				myuser: {},
				query: {
					id: 0,
					page: 1, //页码
					limit: 20, //条数
					isnext: true, //可否加载下一页
					isload: false, //加载中
				},
				rtclist: [], //通话信息
				localMediaStream: null, //我的音视频流数据
				captureStream: null, //屏幕共享流
				isopencapture: false, //是否已开启屏幕分享

				chunks: [], // 用来存储媒体数据
				currentWebmData: null, //录制数据
				_recorder: null, //录制对象
				_stream: null, //录制流
			};
		},
		components: {
			attendConferencePop,
			quitConferencePop,
			signInConferencePop,
			voteConferencePop,
			fileConferencePop
		},
		mounted() {
			this.getdata(true)
		},
		created() {
			if (this.$eventBus) {
				this.$eventBus.$on("meeting_message", (WebSocketdata) => {
					console.log('会议页-message', WebSocketdata)
					this.handelDetailMsg(WebSocketdata)
				});

				this.$eventBus.$on("meeting_rtcmessage", (wdata) => {
					console.log('会议-rtc', wdata)
					if (wdata.code == 'Answer') {
						this.receiveAnswer(wdata.sendid, wdata.data)
					} else if (wdata.code == 'Offer') {
						this.receiveOffer(wdata.sendid, wdata.data)
					} else if (wdata.code == 'Candidate') {
						this.saveIceCandidate(wdata.sendid, wdata.data)
					} else if (wdata.code == 'Over') {
						this.handOvermeeting(wdata.sendid)
					} else if (wdata.code == 'Answer_Capture') {
						this.receiveAnswer_Capture(wdata.sendid, wdata.data)
					} else if (wdata.code == 'Offer_Capture') {
						this.receiveOffer_Capture(wdata.sendid, wdata.data)
					} else if (wdata.code == 'Candidate_Capture') {
						this.saveIceCandidate_Capture(wdata.sendid, wdata.data)
					} else if (wdata.code == 'Over_Capture') {
						this.isopencapture = false
						this.switchList.openScreen = false
					}
				});
			}
		},
		beforeDestroy() {
			console.log('进入了beforeDestroy')

			//销毁事件
			if (this.$eventBus) {
				this.$eventBus.$off("meeting_message")
				this.$eventBus.$off("meeting_rtcmessage")
			}

			if (this.localMediaStream) {
				// 关闭摄像头和麦克风
				this.localMediaStream.getTracks().forEach(track => {
					track.stop();
				});
			}
			if (this.captureStream) {
				// 关闭屏幕共享
				this.captureStream.getTracks().forEach(track => {
					track.stop();
				});
			}

			if (this.rtclist) {
				this.rtclist.map((it, index) => {
					if (it.rtcPeerConnection) {
						let pc = it.rtcPeerConnection
						// 关闭所有已经打开的数据流追踪
						if (pc.getSenders) {
							pc.getSenders().forEach(function(sender) {
								sender.track ? sender.track.stop() : null;
							});
						}

						// 关闭所有已经创建的媒体通道
						pc.getTransceivers().forEach(function(transceiver) {
							if (transceiver.stop) {
								transceiver.stop();
							}
						});

						// 关闭信令机制，并且断开所有的网络连接
						pc.close();
					}
				})
			}
		},
		methods: {
			// show() {
			//   this.visible = true;
			//   this.cancelTele = false
			//   console.log('触发了')
			// },
			// hide() {
			//   this.visible = false;
			//   console.log('关闭了',this.zoomValue)
			// },
			joinSubimtClick() {
				this.$emit('closeSubimt');
			},
			cancelClick() {
				this.cancelTele = true
				setTimeout(() => {
					this.visible = false
				}, 1000)
			},
			// 打开右侧内容  1:参会人,2:签到,3:投票
			openRight(type) {
				if (type == 1) {
					this.rightShow = !this.rightShow //右侧是否显示
					if (this.visibleSignInConference || this.visibleVoteInConference || this.visiblefileConference) {
						this.visibleSignInConference = false
						this.visibleVoteInConference = false
						this.visiblefileConference = false
						this.rightShow = true
					}
					this.attendPeopleShow = !this.attendPeopleShow //参会人列表是否显示
				} else if (type == 2) {
					this.visibleSignInConference = !this.visibleSignInConference
					this.rightShow = !this.rightShow
					if (this.attendPeopleShow || this.visibleVoteInConference || this.visiblefileConference) {
						this.attendPeopleShow = false
						this.visibleVoteInConference = false
						this.visiblefileConference = false
						this.rightShow = true
					}
				} else if (type == 3) {
					this.visibleVoteInConference = !this.visibleVoteInConference
					this.rightShow = !this.rightShow
					if (this.attendPeopleShow || this.visibleSignInConference || this.visiblefileConference) {
						this.visibleSignInConference = false
						this.attendPeopleShow = false
						this.visiblefileConference = false
						this.rightShow = true
					}
				} else if (type == 4) {
					this.visiblefileConference = !this.visiblefileConference
					this.rightShow = !this.rightShow
					if (this.attendPeopleShow || this.visibleSignInConference || this.visibleVoteInConference) {
						this.visibleSignInConference = false
						this.attendPeopleShow = false
						this.visibleVoteInConference = false
						this.rightShow = true
					}
				}

				// console.log('rightShow',this.rightShow,'attendPeopleShow',this.attendPeopleShow )
			},
			//确认退出
			async updateDialogFun(isok) {
				console.log('退出会议', isok)
				if (isok) {
					//退出会议
					var data = {
						RoomId: this.roomId,
					}
					var res = await this.$request.post('/chat/exitRoom', data)
					if (res.code == 200) {
						this.sendOver()
						this.handOvermeeting(this.myuser.UserId)

						this.joinSubimtClick()
					}
				}
				this.dialogVisibleDelete = false
			},
			//获取会会议详情
			async getdata(isrtc = false) {
				var data = {
					RoomId: this.roomId
				}
				var res = await this.$request.post('/meeting/meetingDetail', data)
				if (res.code == 200) {
					this.detail = res.data
					this.name = this.detail.cdata.Name
					this.joinusers = this.detail.userlist
					this.myuser = res.data.myuser
					this.switchList.audioClose = this.myuser.IsMicrophone > 0
					this.switchList.cameraClose = this.myuser.IsCamera > 0
					this.show = true
				}
				this.query.page = 1
				this.query.isload = false
				this.query.id = this.roomId
				this.handlegetlist()
				if (isrtc) {
					this.$nextTick().then(() => {
						this.initRtc()
					});

				}
			},
			inviteUpdateFun(type) {
				console.log('inviteUpdateFun', type)
				if (type == 1) {
					//邀请成功
					this.getdata()
				}
			},
			//录制初始化
			_initRecord() {
				// 获取录制的媒体流
				this.getDisplayMediaFun().then(stream => {
					this._stream = stream;
					console.log('获取录制媒体流成功')
					// 创建媒体录制器，并关联媒体流
					this._recorder = new MediaRecorder(this._stream, {
						mimeType: "video/webm;codecs=h264"
					})
					// 当有数据可用时的处理函数
					this._recorder.ondataavailable = this.handleDataAvailable;
					this.$nextTick(() => {
						this._recorder.start()
					})
				}).catch((e) => {
					console.log('打开获取录制的媒体流错误', e)
				})


			},
			//录制数据回调事件
			handleDataAvailable(event) {
				console.log('handleDataAvailable', event);
				//判断是否有数据
				if (event.data && event.data.size > 0) {
					//将数据记录起来
					this.currentWebmData = event.data
					this.chunks.push(event.data) // 将数据添加到数据片段数组
				}
			},
			//录制保存mp4
			async saveAction2() {
				// 创建一个包含录制数据的 Blob 对象, 设置视频格式mp4
				const blob = new Blob(this.chunks, {
					type: 'video/mp4'
				});

				// 获取Blob对象的大小
				const size = blob.size;

				console.log('Blob对象的大小（以字节为单位）', size); // 输出Blob对象的大小（以字节为单位）
				//保存的视频大于100kb
				if (size / 1024 > 100) {
					var timespan = new Date().getTime()
					var filename = `${this.name}_${timespan}.mp4`


					// // 创建可访问该 Blob 的 URL
					// const url = URL.createObjectURL(blob);
					// // 创建一个a标签链接元素
					// const a = document.createElement('a');
					// // 设置链接的地址为 Blob 的 URL
					// a.href = url;
					// // 设置下载的文件名
					// a.download = filename;
					// // 模拟点击进行下载
					// a.click();
					// // 使用完删除
					// a.remove();


					// 创建一个FormData实例
					const formData = new FormData();

					// 将Blob添加到FormData中，并为其指定一个名称
					// formData.append('file', blob, filename);
					formData.append('uploadfile', blob, filename)


					var roomId = this.roomId
					if (roomId && roomId > 0) formData.append('roomId', roomId)
					var fileno = new Date().getTime();
					if (fileno && fileno != '') formData.append('uploadfileNo', fileno)
					var groupno = 'api_meeting_record_groupno:000000'
					if (groupno && groupno != '') formData.append('groupNo', groupno)

					var res = await this.$request.post('/upload/UploadFile', formData)
					console.log(res)
					if (res.code == 200) {
						this.clearRecord()
					}


				}

			},
			// 开始录制
			recordAction() {
				this.switchList.recordClose = !this.switchList.recordClose
				if (this.switchList.recordClose) {
					if (!this._stream) {
						this._initRecord()
					}

				} else {
					this.stopAction()
				}

			},
			// 暂停录制
			pauseAction() {
				this._recorder.pause()
			},
			// 恢复录制
			resumeAction() {
				this._recorder.resume()
			},
			//停止录制
			stopAction() {
				this._recorder.stop()
				//等等先执行handleDataAvailable录制数据回调事件之后处理保存
				setTimeout(() => {
					this.saveAction2()
				}, 1000)

			},
			clearRecord() {
				// 清空数据片段数组
				this.chunks = [];
				// 停止媒体流中各个轨道的播放
				this._stream.getTracks().forEach(track => track.stop());
				// 重置
				this._recorder = null;
				this._stream = null;
			},
			//获取消息列表
			async handlegetlist() {

				var rlist = []
				if (!this.query.isload) {
					this.query.isload = true
					if (this.query.page == 1) {
						this.messages = []
						this.query.isnext = true
					}
					if (this.query.isnext) {
						rlist = await this.msggetlist(this.query.id, this.query.page, this.query.limit)
						if (rlist == undefined || rlist == null) rlist = []
						this.query.page += 1
						if (rlist.length < this.query.limit) this.query.isnext = false
						rlist.map(it => {
							if (it.MsgType == 10) {
								it = this.getmsg10(it)
							} else if (it.MsgType > 0 && it.MsgType < 7 && it.MsgType != 3) {
								if (typeof it.MsgContent === 'string') {
									it.MsgContent = JSON.parse(it.MsgContent)
								}
							} else if (it.MsgType == 9) {
								if (typeof it.MsgContent === 'string') {
									it.MsgContent = JSON.parse(it.MsgContent)
								}
							}
						})
						// console.log('rlist', rlist)
					}
					this.query.isload = false
				}
				rlist.sort((a, b) => a.MsgId - b.MsgId);
				this.messages = this.messages.concat(rlist)

			},

			//处理消息
			handelDetailMsg(msg) {
				this.messages.push(msg)
				//会议用户变更信息，更新
				if (msg.MsgType >= 41) {
					this.getdata()
				}
			},
			//发送文本消息
			async sendmsg() {
				if (!this.content || this.content == '') {
					this.$message('发送消息不能为空')
					return false;
				}

				var msgType = (this.content.indexOf('http') >= 0 ? 3 : 0)
				var data = {
					MsgType: msgType,
					RoomId: this.roomId,
					MsgContent: this.content
				}
				var rs = await this.$request.post('/chat/sendMsg', data)
				if (rs.code == 200) {
					this.content = ''
				}
			},

			//权限 0-麦克风 1-摄像头 2-举手
			async changeFun(type) {
				if (type == 0) {
					if (this.myuser.SpeenState < 1) {
						var stt = (this.myuser.IsMicrophone > 0 ? 0 : 1)
						var data = {
							Id: this.myuser.Id,
							editType: 8,
							IsMicrophone: stt
						}
						var rs = await this.$request.post('/chat/editRoomRole', data)
						if (rs.code == 200) {
							this.myuser.IsMicrophone = stt
							this.switchList.audioClose = this.myuser.IsMicrophone > 0
						}
					}
				} else if (type == 1) {
					var stt = (this.myuser.IsCamera > 0 ? 0 : 1)
					var data = {
						Id: this.myuser.Id,
						editType: 9,
						IsCamera: stt
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.myuser.IsCamera = stt
						this.switchList.cameraClose = this.myuser.IsCamera > 0
					}
				} else if (type == 2) {
					var stt = (this.myuser.IsRaise > 0 ? 0 : 1)
					var data = {
						Id: this.myuser.Id,
						editType: 5,
						IsRaise: stt
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.myuser.IsRaise = stt
					}
				}
			},

			//开启起屏幕共享
			async openCaptureFun() {
				this.switchList.openScreen = !this.switchList.openScreen
				this.$nextTick(() => {
					if (this.isopencapture) {
						//已开启，关闭
						if (this.captureStream) {
							// 关闭屏幕共享流
							this.captureStream.getTracks().forEach(track => {
								track.stop();
							});
							this.captureStream = null
							this.isopencapture = false
							this.switchList.openScreen = false
							this.sendOver('Over_Capture');
						}
					} else {
						//开启
						//监测是否有其它用户已分享屏幕

						//开启屏幕分享
						var myuserid = this.myuser.UserId
						this.openCaptureMedia(() => {
							this.rtclist.map((it, index) => {
								if (it.uid != myuserid) {
									//创建rtc
									this.createRtc_Capture(it.uid)

									//创建offter后给用户发送offter请求
									this.createOffer_Capture(it
										.uid, (userid,
											sdp) => {
											this.sendOffer(
												userid,
												sdp,
												'Offer_Capture'
											)
										})
								}
							});
						})
						this.isopencapture = true
					}

				})
			},
			//收到离开请求
			handOvermeeting(uid) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					this.rtclist.splice(index, 1)
				}
				var uindex = this.joinusers.findIndex(a => a.ID == uid)
				if (index >= 0) {
					//清除下线用户
					this.joinusers.splice(index, 1)
				}
				//参与用户全部挂断，结束
				if (this.joinusers.length <= 0) {
					setTimeout(() => {
						this.joinSubimtClick()
					}, 1000)
				}
			},




			//发送Over结束请求
			sendOver(code = 'Over') {
				//通知其它用户我已挂断
				this.rtclist.map(async it => {
					if (this.myuser.UserId != it.uid) {
						var rtcdata = JSON.stringify({
							type: 8999,
							code: code,
							userid: it.uid,
							sendid: this.myuser.UserId, //发送用户(我)Id
							data: '结束'
						});
						var data = {
							UserId: it.uid,
							MsgContent: rtcdata
						}
						var rs = await this.$request.post('/chat/sendrtcMsg',
							data)
					}
				})
			},

			//发送offter请求
			async sendOffer(uid, sdp, code = 'Offer') {
				var rtcdata = JSON.stringify({
					type: 8999,
					code: code,
					userid: uid,
					sendid: this.myuser.UserId, //发送用户(我)Id
					data: sdp
				});
				var data = {
					UserId: uid,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
				if (rs.code == 200) {

				}

			},
			//发送Answer请求
			async sendAnswer(uid, sdp, code = 'Answer') {
				var rtcdata = JSON.stringify({
					type: 8999,
					code: code,
					userid: uid,
					sendid: this.myuser.UserId, //发送用户(我)Id
					data: sdp
				});
				var data = {
					UserId: uid,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
				if (rs.code == 200) {

				}
			},

			//发送candidate信息,用于交换
			async sendcandidate(uid, candidate, code = 'Candidate') {
				var rtcdata = JSON.stringify({
					type: 8999,
					code: code,
					userid: uid,
					sendid: this.myuser.UserId, //发送用户(我)Id
					data: candidate
				});
				var data = {
					UserId: uid,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
				if (rs.code == 200) {

				}
			},

			//初始化rtc
			initRtc() {
				var myuserid = this.myuser.UserId
				var userlist = this.joinusers
				this.rtclist = []
				userlist.map(it => {
					this.rtclist.push({
						uid: it.UserId,

						captureRtc: null, // 会议rtc初始状态
						captureRtcState: 'new', // 会议rtc初始状态
						captureRtcIcelist: [], //会议ice缓存

						rtcPeerConnection: null, // 会议rtc初始状态
						connectionState: 'new', // 会议rtc初始状态
						icelist: [], //会议ice缓存
						state: 0, //状态 0-拨打中 1-已接通 2-未接通 3-已挂断 
						telmin: 0, //通话时长(秒)

					})
				})
				//打开本地音视频后
				this.openLocalMedia(() => {
					console.log('打开本地音视频后执行')
					this.rtclist.map((it, index) => {
						if (it.uid != myuserid) {
							//创建rtc
							this.createPeerConnection(it.uid)

							//创建offter后给用户发送offter请求
							this.createOffer(it.uid, (userid,
								sdp) => {
								this.sendOffer(userid,
									sdp)
							})
						}
					})
				})
			},
			//打开本地音视频流
			openLocalMedia(callback) {
				if (!this.localMediaStream) {
					console.log('打开本地视频流');
					// 本地音视频信息, 用于 打开本地音视频流
					const mediaConstraints = {
						video: false, // (this.myuser.IsCamera == 0),
						audio: true, //由于没有麦克风，所有如果请求音频，会报错，不过不会影响视频流播放
					};
					//getUserMedia
					if (navigator.mediaDevices && navigator.mediaDevices
						.getUserMedia) {
						navigator.mediaDevices.getUserMedia(mediaConstraints)
							.then(stream => {
								this.localMediaStream = stream;
								//显示本地视频流
								var id =
									`remoteVideo_${this.myuser.UserId}`
								//显示 远程视频流
								let localVideo = document.getElementById(
									id);
								localVideo.srcObject = stream;
								localVideo.play();

								callback();
							}).catch((e) => {
								console.log('打开本地音视频流错误', e)
							})
					}


				}
			},

			// 创建 PeerConnection 对象
			createPeerConnection(uid) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					//ice服务器信息, 用于创建 SDP 对象
					let iceServers = {
						"iceServers": [{
								"url": "stun:stun.l.google.com:19302"
							},
							//{ "urls": ["stun:159.75.239.36:3478"] },
							//{ "urls": ["turn:159.75.239.36:3478"], "username": "chr", "credential": "123456" },
						]
					};
					var rtcPeerConnection = new RTCPeerConnection(iceServers)
					console.log('rtcPeerConnection创建', rtcPeerConnection)


					//将 音视频流 添加到 端点 中
					for (const track of this.localMediaStream.getTracks()) {
						rtcPeerConnection.addTrack(track, this
							.localMediaStream);
					}
					rtcPeerConnection.ontrack = (event) => {
						if (event) {
							console.log('流 的回调')
							var stream = event.streams[0]
							//绑定远程视频流
							var id = `remoteVideo_${uid}`
							//显示 远程视频流
							let remoteVideo = document.getElementById(id);
							remoteVideo.srcObject = stream;
							remoteVideo.play();

						}
					};

					this.rtclist[index].rtcPeerConnection = rtcPeerConnection

					console.log('创建rtc成功')

					//绑定 收集 candidate 的回调
					this.bindOnIceCandidate(uid, (uid, candidate) => {
						//发送candidate信息,用于交换
						this.sendcandidate(uid, candidate)

					})



					console.log('创建视频回调成功')
				}
			},
			//收到offter请求
			async receiveOffer(uid, sdp) {
				console.log('收到offter请求-开始重新创建rtc')
				this.createPeerConnection(uid)
				console.log('收到offter请求')
				this.saveSdp(uid, sdp, () => {
					this.saveIcelistfun(uid)
					this.createAnswer(uid, (userid, answer) => {
						this.sendAnswer(userid, answer)

					})
				})
			},
			//收到Answer请求
			async receiveAnswer(uid, sdp) {
				this.saveSdp(uid, sdp, () => {
					console.log('收到Answer请求保存后执行')
					this.saveIcelistfun(uid)
				})
			},
			//创建用于 offer 的 SDP 对象
			createOffer(uid, callback) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {

					// 创建 offer 的信息
					const offerOptions = {
						iceRestart: true,
						offerToReceiveAudio: true, //由于没有麦克风，所有如果请求音频，会报错，不过不会影响视频流播放
					};
					// 调用PeerConnection的 CreateOffer 方法创建一个用于 offer的SDP对象，SDP对象中保存当前音视频的相关参数。
					this.rtclist[index].rtcPeerConnection.createOffer(
							offerOptions)
						.then(sdp => {
							// 保存自己的 SDP 对象
							this.rtclist[index].rtcPeerConnection
								.setLocalDescription(sdp)
								.then(() => callback(uid, sdp));
						})
						.catch(() => console.log('createOffer 失败'));
				}
			},
			//创建用于 answer 的 SDP 对象
			createAnswer(uid, callback) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					// 创建 offer 的信息
					const offerOptions = {
						iceRestart: true,
						offerToReceiveAudio: true, //由于没有麦克风，所有如果请求音频，会报错，不过不会影响视频流播放
					};
					// 调用PeerConnection的 CreateAnswer 方法创建一个 answer的SDP对象
					this.rtclist[index].rtcPeerConnection.createAnswer(
							offerOptions)
						.then(sdp => {
							// 保存自己的 SDP 对象
							this.rtclist[index].rtcPeerConnection
								.setLocalDescription(sdp)
								.then(() => callback(uid, sdp));
						}).catch(() => console.log('createAnswer 失败'))
				}
			},


			//保存远程的 SDP 对象
			saveSdp(uid, answerSdp, callback) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					this.rtclist[index].rtcPeerConnection
						.setRemoteDescription(
							new RTCSessionDescription(answerSdp))
						.then(callback);
				}
			},
			// 、保存 candidate 信息
			saveIceCandidate(uid, candidate) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {

					var rtc = this.rtclist[index].rtcPeerConnection
					console.log('rtc.remoteDescription', rtc)
					if (rtc && rtc.remoteDescription) {
						let iceCandidate = new RTCIceCandidate(
							candidate);
						this.rtclist[index].rtcPeerConnection
							.addIceCandidate(iceCandidate)
							.then(() => {

								console.log('addIceCandidate 成功')
								console.log(
									`connectionState${index}`,
									this.rtclist[index]
									.rtcPeerConnection
									.iceConnectionState)
							}).catch((e) => {
								console.log('saveIceCandidate 失败',
									e)
							});
					} else {
						this.rtclist[index].icelist.push(candidate)
					}
				}
			},
			saveIcelistfun(uid) {
				var uindex = this.rtclist.findIndex(a => a.uid == uid)
				if (uindex >= 0) {
					if (this.rtclist[uindex].icelist && this.rtclist[
							uindex].icelist.length > 0) {
						this.rtclist[uindex].icelist.map((candidate,
							index) => {

							let iceCandidate =
								new RTCIceCandidate(candidate);
							this.rtclist[uindex]
								.rtcPeerConnection
								.addIceCandidate(iceCandidate)
								.then(() => {
									console.log(
										'addIceCandidate 成功'
									)
									console.log(
										`connectionState${uindex}`,
										this.rtclist[
											uindex]
										.rtcPeerConnection
										.iceConnectionState
									)
									//删除
									this.rtclist[uindex]
										.icelist.splice(
											index, 1)
								})
								.catch((e) => {
									console.log(
										'saveIcelistfun 失败',
										e)
								});

						})

					}
				}
			},
			// 收集 candidate 的回调
			bindOnIceCandidate(uid, callback) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					// 绑定 收集 candidate 的回调
					this.rtclist[index].rtcPeerConnection
						.onicecandidate = (event) => {
							if (event.candidate) {
								callback(uid, event.candidate);
							}
						};
				}
			},

			//打开屏幕共享流
			openCaptureMedia(callback) {
				if (!this.captureStream) {

					var myuserid = this.myuser.UserId

					console.log('打开屏幕共享流', myuserid);
					// 本地音视频信息, 用于 打开本地音视频流
					const mediaConstraints = {
						video: true,
						audio: false,
					};
					this.getDisplayMediaFun().then(stream => {
						this.captureStream = stream;
						//显示本地共享流
						var id = `captureVideo`
						let localVideo = document.getElementById(id);
						if (localVideo) {
							localVideo.srcObject = stream;
							// localVideo.play();
						}
						callback();
					}).catch((e) => {
						console.log('打开屏幕共享流错误', e)
					})


				}
			},


			// 创建 PeerConnection 对象
			createRtc_Capture(uid) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					//ice服务器信息, 用于创建 SDP 对象
					let iceServers = {
						"iceServers": [{
								"url": "stun:stun.l.google.com:19302"
							},
							//{ "urls": ["stun:159.75.239.36:3478"] },
							//{ "urls": ["turn:159.75.239.36:3478"], "username": "chr", "credential": "123456" },
						]
					};
					var rtcPeerConnection = new RTCPeerConnection(
						iceServers)
					console.log('CaptureRtc创建', rtcPeerConnection)


					//将 音视频流 添加到 端点 中
					if (this.captureStream) {
						for (const track of this.captureStream
								.getTracks()) {
							rtcPeerConnection.addTrack(track, this
								.captureStream);
						}
					}
					rtcPeerConnection.ontrack = (event) => {
						if (event) {
							console.log('流 的回调')
							var stream = event.streams[0]
							//绑定远程视频流
							var id = `captureVideo`
							//显示 远程视频流
							let remoteVideo = document.getElementById(id);
							if (remoteVideo) {
								remoteVideo.srcObject = stream;
								// remoteVideo.play();
							}
						}
					};

					this.rtclist[index].captureRtc = rtcPeerConnection

					console.log('创建rtc成功')

					//绑定 收集 candidate 的回调
					this.bindOnIceCandidate_Capture(uid, (uid,
						candidate) => {
						//发送candidate信息,用于交换
						this.sendcandidate(uid, candidate,
							'Candidate_Capture')

					})



					console.log('创建视频回调成功')
				}
			},
			// 收集 candidate 的回调
			bindOnIceCandidate_Capture(uid, callback) {
				var index = this.rtclist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					// 绑定 收集 candidate 的回调
					this.rtclist[index].captureRtc.onicecandidate = (
						event) => {
						if (event.candidate) {
							callback(uid, event.candidate);
						}
					};
				}
			},

			//收到offter请求
			async receiveOffer_Capture(uid, sdp) {
				console.log('收到offter请求-开始重新创建rtc')
				this.createRtc_Capture(uid)

				this.saveSdp_Capture(uid, sdp, () => {
					this.saveIcelistfun_Capture(uid)
					this.createAnswer_Capture(uid, (userid,
						answer) => {
						this.sendAnswer(userid,
							answer,
							'Answer_Capture')
					})
				})
				this.switchList.openScreen = true
				this.isopencapture = true
			},
			//收到Answer请求
			async receiveAnswer_Capture(uid, sdp) {
				this.saveSdp_Capture(uid, sdp, () => {
					console.log('收到Answer请求保存后执行')
					this.saveIcelistfun_Capture(uid)
				})
			},
			//创建用于 offer 的 SDP 对象
			createOffer_Capture(uid, callback) {
				var index = this.rtclist.findIndex(a => a
					.uid == uid)
				if (index >= 0) {

					// 创建 offer 的信息
					const offerOptions = {
						iceRestart: true,
						offerToReceiveAudio: true, //由于没有麦克风，所有如果请求音频，会报错，不过不会影响视频流播放
					};
					// 调用PeerConnection的 CreateOffer 方法创建一个用于 offer的SDP对象，SDP对象中保存当前音视频的相关参数。
					this.rtclist[index].captureRtc.createOffer(
							offerOptions)
						.then(sdp => {
							// 保存自己的 SDP 对象
							this.rtclist[index].captureRtc
								.setLocalDescription(sdp)
								.then(() => callback(uid,
									sdp));
						})
						.catch(() => console.log(
							'createOffer 失败'));
				}
			},
			//创建用于 answer 的 SDP 对象
			createAnswer_Capture(uid, callback) {
				var index = this.rtclist.findIndex(a => a
					.uid == uid)
				if (index >= 0) {
					// 创建 offer 的信息
					const offerOptions = {
						iceRestart: true,
						offerToReceiveAudio: true, //由于没有麦克风，所有如果请求音频，会报错，不过不会影响视频流播放
					};
					// 调用PeerConnection的 CreateAnswer 方法创建一个 answer的SDP对象
					this.rtclist[index].captureRtc
						.createAnswer(offerOptions)
						.then(sdp => {
							// 保存自己的 SDP 对象
							this.rtclist[index].captureRtc
								.setLocalDescription(sdp)
								.then(() => callback(uid,
									sdp));
						}).catch(() => console.log(
							'createAnswer 失败'))
				}
			},


			//保存远程的 SDP 对象
			saveSdp_Capture(uid, answerSdp, callback) {
				var index = this.rtclist.findIndex(a => a
					.uid == uid)
				if (index >= 0) {
					this.rtclist[index].captureRtc
						.setRemoteDescription(
							new RTCSessionDescription(
								answerSdp))
						.then(callback);
				}
			},
			// 、保存 candidate 信息
			saveIceCandidate_Capture(uid, candidate) {
				var index = this.rtclist.findIndex(a => a
					.uid == uid)
				if (index >= 0) {

					var rtc = this.rtclist[index].captureRtc
					console.log('rtc.remoteDescription', rtc)
					if (rtc && rtc.remoteDescription) {
						let iceCandidate = new RTCIceCandidate(
							candidate);
						this.rtclist[index].captureRtc
							.addIceCandidate(iceCandidate)
							.then(() => {

								console.log(
									'addIceCandidate 成功'
								)
								console.log(
									`connectionState${index}`,
									this.rtclist[index]
									.captureRtc
									.iceConnectionState
								)
							}).catch((e) => {
								console.log(
									'saveIceCandidate 失败',
									e)
							});
					} else {
						this.rtclist[index].captureRtcIcelist
							.push(candidate)
					}
				}
			},
			saveIcelistfun_Capture(uid) {
				var uindex = this.rtclist.findIndex(a => a
					.uid == uid)
				if (uindex >= 0) {
					if (this.rtclist[uindex]
						.captureRtcIcelist && this.rtclist[
							uindex].captureRtcIcelist.length >
						0) {
						this.rtclist[uindex].captureRtcIcelist
							.map((candidate, index) => {

								let iceCandidate =
									new RTCIceCandidate(
										candidate);
								this.rtclist[uindex]
									.captureRtc
									.addIceCandidate(
										iceCandidate)
									.then(() => {
										console.log(
											'addIceCandidate 成功'
										)
										console.log(
											`connectionState${uindex}`,
											this
											.rtclist[
												uindex
											]
											.captureRtc
											.iceConnectionState
										)
										//删除
										this.rtclist[
												uindex]
											.captureRtcIcelist
											.splice(
												index,
												1)
									})
									.catch((e) => {
										console.log(
											'saveIcelistfun 失败',
											e)
									});

							})

					}
				}
			},




		},
	};
</script>

<style scoped lang="less">
	.overlayPhone {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0);
		/* Adjust transparency as needed */
		z-index: 100;

		/* Ensure it sits on top of other content */
		.frame-active {
			border-radius: 16px 0px 0px 16px !important;
		}

		.frame {
			width: 877px;
			height: 610px;
			background: #FFFFFF;
			box-shadow: 0px 3px 26px 1px rgba(0, 0, 0, 0.16);
			border-radius: 16px 16px 16px 16px;

			.frame-title {
				margin-top: 18px;
				margin-left: 26px;
				font-weight: 400;
				font-size: 20px;
				color: #333333;
			}

			.frame-title-two {
				font-weight: 400;
				font-size: 16px;
				color: #333333;
			}

			.frame-item {
				cursor: pointer;
				/* 鼠标悬停时光标变为手指 */
				margin-left: 14px;
				width: 121px;
				height: 83px;
				background: #F2F3F5;
				border-radius: 16px 16px 16px 16px;
				justify-content: center;
			}

			.rame-item-text {
				font-weight: 400;
				font-size: 14px;
				color: #6E6E6E;
			}

			.frame-button {
				cursor: pointer;
				/* 鼠标悬停时光标变为手指 */
				margin-right: 32px;
				width: 122px;
				height: 47px;
				background: #0052D9;
				border-radius: 16px 16px 16px 16px;
				font-size: 16px;
				color: #FFFFFF;
				z-index: 200;
			}

			.frame-text-name {
				margin-top: 10px;
				font-weight: 400;
				font-size: 14px;
				color: #000000;
			}

			.frame-chat {
				cursor: pointer;
				/* 鼠标悬停时光标变为手指 */
				margin-left: 20px;
				width: 116px;
				height: 32px;
				background: #F1F3F4;
				border-radius: 10px 10px 10px 10px;
			}

			.frame-chat-line {
				margin-left: 10px;
				width: 0px;
				height: 19px;
				border: 1px solid #707070;
				opacity: 0.63;
			}

			.frame-chart-text {
				font-weight: 400;
				font-size: 13px;
				color: #6E6E6E;
			}

			.frame-hand {

				// width: 41px;
				// height: 32px;
				padding: 5px 12px;
				background: #F1F3F4;
				border-radius: 10px 10px 10px 10px;
			}
		}

		.cancel-frame {
			margin-top: 29px;
			margin-bottom: 31px;
			width: 214px;
			height: 52px;
			background: #FFFFFF;
			border-radius: 14px 14px 14px 14px;
			border: 2px solid #366EF4;
			font-size: 19px;
			font-weight: 400;
			color: #366EF4;
		}
	}

	.frame-line {
		width: 0px;
		height: 610px;
		border: 1px solid #707070;
		opacity: 0.43;
	}

	/* 基本样式 */
	.tooltip {
		position: relative;
		/* 使得气泡弹窗能够相对于其容器定位 */
		display: inline-block;
		/* 使元素在行内显示 */
		cursor: pointer;
		/* 鼠标悬停时显示为手形光标 */
	}

	.tooltip .tooltiptext {
		// visibility: hidden; /* 默认隐藏弹窗 */
		width: 317px;
		/* 弹窗的宽度 */
		height: 244px;
		background-color: #fff;
		/* 背景色 */
		color: #000;
		/* 字体颜色 */
		// text-align: center; /* 文字居中 */
		border-radius: 6px;
		/* 圆角 */
		padding: 5px 0;
		/* 内边距 */
		position: absolute;
		/* 绝对定位 */
		z-index: 1;
		/* 确保弹窗显示在其他元素之上 */
		bottom: 125%;
		/* 距离容器顶部的距离 */
		left: 0%;
		/* 使弹窗居中对齐 */
		margin-left: -80px;
		/* 使弹窗水平居中 */
		opacity: 1;
		/* 默认隐藏弹窗 */
		transition: opacity 0.3s;
		/* 渐变效果 */
		box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.16);
	}

	.tooltip .tooltiptext1 {
		// visibility: hidden; /* 默认隐藏弹窗 */
		width: 101px;
		/* 弹窗的宽度 */
		height: 116px;
		background-color: #fff;
		/* 背景色 */
		color: #000;
		/* 字体颜色 */
		// text-align: center; /* 文字居中 */
		border-radius: 6px;
		/* 圆角 */
		// padding: 5px 0; /* 内边距 */
		position: absolute;
		/* 绝对定位 */
		z-index: 1;
		/* 确保弹窗显示在其他元素之上 */
		bottom: 125%;
		/* 距离容器顶部的距离 */
		left: 0%;
		/* 使弹窗居中对齐 */
		margin-left: -15px;
		/* 使弹窗水平居中 */
		opacity: 1;
		/* 默认隐藏弹窗 */
		transition: opacity 0.3s;
		/* 渐变效果 */
		box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.16);
	}

	/* 小三角形 */
	.tooltip .tooltiptext1::after {
		content: "";
		/* 生成内容 */
		position: absolute;
		/* 绝对定位 */
		top: 100%;
		/* 三角形在弹窗底部 */
		left: 50%;
		/* 三角形水平居中 */
		margin-left: -5px;
		/* 使三角形水平居中 */
		border-width: 5px;
		/* 三角形的大小 */
		border-style: solid;
		/* 边框样式 */
		border-color: #fff transparent transparent transparent;
		/* 三角形颜色 */
		// box-shadow: 0px 3px 15px 1px rgba(0,0,0,0.16);
	}

	/* 小三角形 */
	.tooltip .tooltiptext::after {
		content: "";
		/* 生成内容 */
		position: absolute;
		/* 绝对定位 */
		top: 100%;
		/* 三角形在弹窗底部 */
		left: 50%;
		/* 三角形水平居中 */
		margin-left: -5px;
		/* 使三角形水平居中 */
		border-width: 5px;
		/* 三角形的大小 */
		border-style: solid;
		/* 边框样式 */
		border-color: #fff transparent transparent transparent;
		/* 三角形颜色 */
		// box-shadow: 0px 3px 15px 1px rgba(0,0,0,0.16);
	}

	// /* 显示弹窗 */
	// .tooltip:hover .tooltiptext {
	//     visibility: visible; /* 显示弹窗 */
	//     opacity: 1; /* 不透明 */
	// }
	.chat-message-other {
		/*background-color: red;*/
		display: flex;
		padding-left: 1rem;
		padding-top: 1rem;
	}

	.chat-message-me {
		/*background-color: red;*/
		display: flex;
		padding-right: 1rem;
		padding-top: 1rem;
		flex-direction: row-reverse;
		/* 将子div的顺序反转 */

	}

	.message-me-asWhole-headPortrait {
		padding: 3px;
	}

	.message-other-asWhole-headPortrait {
		padding: 3px;
	}

	.message-me-asWhole-right {
		display: flex;
		flex-direction: column;
		/* 设置子元素垂直排列 */
		margin-left: 0.1rem;
	}

	.message-other-asWhole-right {
		display: flex;
		flex-direction: column;
		/* 设置子元素垂直排列 */
		margin-left: 0.1rem;
	}

	.message-me-asWhole-top {
		padding: 3px;
		/* font-size: 12px; */
		// font-family: 微软雅黑;
		padding: 3px;
		// color: rgba(134, 144, 156, 1);
		// direction: rtl; /* 右到左书写 */
		text-align: right;
		font-weight: 500;
		font-size: 10px;
		color: #333333;
		// display: flex;
		// justify-content: flex-end;
	}

	.message-other-asWhole-top {
		padding: 3px;
		/* font-size: 12px; */
		// font-family: 微软雅黑;
		font-weight: 500;
		font-size: 10px;
		color: #333333;
		padding: 3px;
		// color: rgba(134, 144, 156, 1);
	}

	.message-me {
		background-color: rgba(242, 243, 245, 1);
		max-width: 100px;
		word-wrap: break-word;
		/* 处理英文单词换行 */
		word-break: break-all;
		/* 处理中文换行 */
		display: inline-block;
		/*将div元素转换为行内块元素*/
		width: auto;
		/* 宽度根据文本宽度自动调正*/
		padding: 6px 12px;
		border-radius: 4px;
		font-size: 12px;
		color: #535D6D;
	}

	.message-other {
		background-color: rgba(242, 243, 245, 1);
		max-width: 100px;
		word-wrap: break-word;
		/* 处理英文单词换行 */
		word-break: break-all;
		/* 处理中文换行 */
		display: inline-block;
		/*将div元素转换为行内块元素*/
		width: auto;
		/* 宽度根据文本宽度自动调正*/
		padding: 6px 12px;
		border-radius: 4px;
		font-size: 12px;
		color: #535D6D;
	}

	.exitFrame {
		padding: 0px 11px;
		font-size: 12px;
		color: #000000;
	}


	.examineeFace_logo_style {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	/deep/ .inputFrame .el-input__inner {

		width: 295px;
		height: 33px;
		background: #F6F6F6;
		border-radius: 8px;
		border: 1px solid #B7B7B7;
	}

	/deep/.memberInput .el-input__inner {
		width: 301px;
		// height: 56px;
		background: #FFFFFF;
		// border-radius: 16px 16px 16px 16px;
		border: 0px solid #B0B1B4;
		border-bottom: 1px solid #B7B7B7;
		text-align: center;
	}

	/deep/.el-input.is-disabled .el-input__inner {
		background: #FFFFFF;
		color: #000000;
		font-size: 23px;
	}
</style>
<template>
	<div class="hello">
		<div class="frame">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-left: 14px;margin-bottom: 10px;">
				<img :src="require('@/assets/img/workbench/28b4c33b74ba8c53275cb0d29bf8cfb.png')" class="imgIcon" />
				<div class="people-title">审批</div>
			</div>
			<!-- 搜索框 -->
			<!-- <div class="left-item" style="margin-top: 10px;margin-bottom: 15px;">
                <el-input v-model="input"  placeholder="搜索">
                <div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
                    <div  class="prefix-icon3"></div>
                </div>
                </el-input>
            </div> -->

			<template v-for="(item,index) in tab">
				<div class="frame-item flexal" :class="index == tabIndex? 'frame-item-active': ''" :key="index"
					@click="submit(index)">
					<img :src="index == tabIndex ? item.chooseIcon:item.icon"
						style="width: 16px;height: 16px;margin-left: 12px;margin-right: 6px;" />
					<div class="frame-item-text">{{item.text}}</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'workNotcie',
		props: {
			msg: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				input: '',
				tab: [{
						icon: require('@/assets/img/workbench/stay.png'),
						chooseIcon: require('@/assets/img/workbench/stayIcon.png'),
						text: '待处理'
					},
					{
						icon: require('@/assets/img/workbench/end.png'),
						chooseIcon: require('@/assets/img/workbench/endIcon.png'),
						text: '已处理'
					},
					// {
					//     icon:require('@/assets/img/workbench/start.png'),
					//     chooseIcon:require('@/assets/img/workbench/startIcon.png'),
					//     text:'已发起'
					// },
				],
				tabIndex: 0
			}
		},
		methods: {
			submit(index) {
				this.tabIndex = index
				this.$emit('workNoticeSubmit', index)
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		width: 209px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;

		.people-title {
			margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
		}

		.left-item {
			margin-left: 12px;
			margin-top: 5px;
			display: flex;
			width: 185px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

			.item-bubble {
				width: 15px;
				height: 15px;
				background: #F65160;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				color: #FFFFFF;
				font-weight: 400;
			}
		}

		.frame-item {
			margin-top: 5px;
			margin-left: 12px;
			width: 185px;
			height: 36px;
			background: #FFFFFF;
			color: #858A99;
			border-radius: 8px 8px 8px 8px;
		}

		.frame-item-active {
			background: #EFF2FF;
			color: #0052D9;
		}

		.frame-item-text {
			font-size: 15px;

			font-weight: 400;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}
</style>